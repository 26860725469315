type ViolateMetricsObject = {
    validation_metrics_old: any;
    tile_id_old: string;
    tileset_mode_old: string;
    tileset_name_old: string;
    change_difference_metrics: any;
    validation_metrics_new: any;
    threshold_violation_metrics: any;
    change_rate_metrics: any;
    missing_layer_metrics: any;
};

type ChangeMetricsObject = {
    violation_metric: string;
    x_y_z: string;
    old: number;
    new: number;
    change_rate: number;
    change_difference: number;
};

/**
 * Helper method to reformat JSON inputs for aggregate metrics function
 * {new:{}, old:{}} → {attribute1_new: {} attribute2_old: {}}
 * @param aggregateMetricsJSON
 */
export const reformatAggregateMetricsJSON = (aggregateMetricsJSON: any) => {
    const reformattedData = [];
    for (const key in aggregateMetricsJSON.new) {
        const newData = aggregateMetricsJSON.new[key];
        const oldData = aggregateMetricsJSON.old[key];

        const tileMetric = {
            POI: key,
            area_new: newData.area,
            area_old: oldData.area,
            count_new: newData.count,
            count_old: oldData.count,
            count_complex_new: newData.count_complex,
            count_complex_old: oldData.count_complex,
            length_new: newData.length,
            length_old: oldData.length,
        }
        reformattedData.push(tileMetric);
    }
    console.log(reformattedData);
    return reformattedData;
}

/**
 * Helper method to reformat JSON inputs for violate metrics function
 * {count|transportation~transportation_others=1.0, count_complex|transportation=224.0}
 * -> {"count|transportation~transportation_others":1.0, "count_complex|transportation":224.0}
 * @param violateMetricsJSON
 */
export const reformatViolateMetricsJSON = (violateMetricsJSON: any) => {
    // const reformattedData = violateMetricsJSON.map((obj : ViolateMetricsObject) => {
    //     let {validation_metrics_old, tile_id_old, tileset_mode_old, tileset_name_old, change_difference_metrics,
    //         validation_metrics_new, threshold_violation_metrics, change_rate_metrics, missing_layer_metrics} = obj;
    //     validation_metrics_old = convertToJSONFormat(validation_metrics_old);
    //     validation_metrics_new = convertToJSONFormat(validation_metrics_new)
    //     return {tile_id: tile_id_old, tileset_name: tileset_name_old, tileset_mode: tileset_mode_old,
    //         validation_metrics: {validation_metrics_old, validation_metrics_new}, change_rate_metrics: convertToJSONFormat(change_rate_metrics),
    //         change_difference_metrics: convertToJSONFormat(change_difference_metrics),
    //         threshold_violation_metrics : convertToJSONFormat(threshold_violation_metrics),
    //         missing_layer_metrics: convertToJSONFormat(missing_layer_metrics)};
    // });
    // return reformattedData;

    const reformattedData = violateMetricsJSON.map((obj : ViolateMetricsObject) => {
        let {validation_metrics_old, tile_id_old, tileset_mode_old, tileset_name_old, change_difference_metrics,
            validation_metrics_new, threshold_violation_metrics, change_rate_metrics, missing_layer_metrics} = obj;
        validation_metrics_old = convertToJSONFormat(validation_metrics_old);
        validation_metrics_new = convertToJSONFormat(validation_metrics_new)
        const result: any = {
            tile_id: tile_id_old,
            tileset_name: tileset_name_old,
            tileset_mode: tileset_mode_old,
            validation_metrics: { validation_metrics_old, validation_metrics_new },
            change_rate_metrics: convertToJSONFormat(change_rate_metrics),
            change_difference_metrics: convertToJSONFormat(change_difference_metrics),
            threshold_violation_metrics: convertToJSONFormat(threshold_violation_metrics)
        };

        // Add missing_layer_metrics only if it's not empty
        if (missing_layer_metrics && Object.keys(missing_layer_metrics).length > 0) {
            result.missing_layer_metrics = convertToJSONFormat(missing_layer_metrics);
        }

        return result;
    });

    return reformattedData;
}

/**
 * Convert nested strings into JSON format
 * @param inputString - String to be converted to JSON format
 */
const convertToJSONFormat = (inputString: string) => {
    let reformattedString = inputString.replace(/=/g, ": ").replace(/NULL/g, "null");
    const stringWithoutBraces = reformattedString.slice(1, -1);
    const elements = stringWithoutBraces.split(",");
    const modifiedElements: string[] = [];
    for (const element of elements) {
        const [key, value] = element.split(":");
        const modifiedElement = `"${key}": ${value}`;
        modifiedElements.push(modifiedElement);
    }
    reformattedString = `{${modifiedElements.join(", ")}}`;
    return reformattedString;
}

/**
 * Helper method to reformat JSON inputs for change metrics function
 * Parsing the label|type attributess to seperate into label & type attributes
 * @param changeMetricsJSON
 */
export const reformatChangeMetricsJSON = (changeMetricsJSON : any) => {
    const reformattedData = changeMetricsJSON.map((obj : ChangeMetricsObject) => {
        //"violation_metric", "x_y_z", "old", "new", "change_rate", "change_difference"
        let {violation_metric, x_y_z, old, change_rate, change_difference} = obj;
        // new is keyword
        let new_value = obj["new"];
        let label = violation_metric.split("|")[0];
        let type = violation_metric.split("|")[1];
        return {label: label, type: type, violation_metric: violation_metric, tileset_id:  x_y_z,
            old_value: old, new_value: new_value, change_rate: change_rate, change_difference: change_difference};
    });
    return reformattedData;
}
