import { ContentLayout, Header } from "@amzn/awsui-components-react";
import { FC } from "react";
import Layout from "../../../layout/Layout";

const ManageStylesheets: FC<any> = () => {
    return (
        <Layout>
            <ContentLayout header={<Header variant="h1">Stylesheets</Header>}>
                <>Stylesheets</>
            </ContentLayout>
        </Layout>
    )
}
export default ManageStylesheets;