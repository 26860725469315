import React, { useState, useEffect } from 'react';
import { CodeEditor } from '@amzn/awsui-components-react/polaris';
import { useGeoJsonStore } from '../../../../store/GeojsonStore';



const CodeEditorView = () => {
    const [preferences, setPreferences] = React.useState(
        undefined
    );
    const [loading, setLoading] = React.useState(false);

    const [ace, setAce] = useState();
    const settings = useGeoJsonStore((state) => state.settings);
    const setCodeViewData = useGeoJsonStore((state) => state.setCodeViewData);

    useEffect(() => {
        setLoading(true);

        import('ace-builds')
            .then((instance: any) =>
                import('ace-builds/webpack-resolver')
                    .then(() => {
                        instance.config.set('useStrictCSP', true);
                        instance.config.set('loadWorkerFromBlob', false);
                        setLoading(false);
                        setAce(instance);
                    })
                    .catch(() => setLoading(false)),
            )
            .catch(() => setLoading(false));
    }, []);


    const onChange = (data: any) => {
        setCodeViewData(data);
    };

    return (
        <CodeEditor
            ace={ace}
            language='json'
            value={settings.codeViewData}
            // Without `onChange` the `onDelayedChange` handler was not colled
            onChange={() => { }}
            onDelayedChange={(event) => onChange(event.detail.value)}

            preferences={preferences}
            onPreferencesChange={(e: any) => setPreferences(e.detail)}
            loading={loading}
            i18nStrings={{
                loadingState: 'Loading code editor',
                errorState:
                    'There was an error loading the code editor.',
                errorStateRecovery: 'Retry',
                editorGroupAriaLabel: 'Code editor',
                statusBarGroupAriaLabel: 'Status bar',
                cursorPosition: (row, column) =>
                    `Ln ${row}, Col ${column}`,
                errorsTab: 'Errors',
                warningsTab: 'Warnings',
                preferencesButtonAriaLabel: 'Preferences',
                paneCloseButtonAriaLabel: 'Close',
                preferencesModalHeader: 'Preferences',
                preferencesModalCancel: 'Cancel',
                preferencesModalConfirm: 'Confirm',
                preferencesModalWrapLines: 'Wrap lines',
                preferencesModalTheme: 'Theme',
                preferencesModalLightThemes: 'Light themes',
                preferencesModalDarkThemes: 'Dark themes'
            }}
        />
    )
}

export default CodeEditorView;