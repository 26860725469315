import React, { FC, useEffect, useState } from 'react';
import { SpritesCard } from './SpritesCard';

const Sprites: FC<{url: string}> = ({url}) => {
    const [list, setList] = useState([]);
    const [sprites, setSprites] = useState([]);


    useEffect(() => {
        // declare the data fetching function
        const fetchData = async (url: string) => {
            if (url) {
                const response = await fetch(`${url}@2x.json`);
                const json = await response.json();
                setSprites(await json)
            }
        }

        
            fetchData(url)
                .catch(console.error);
        
    }, [url]);

    useEffect(() => {
        const entries: any = Object.entries(sprites)
            .map((entry: any) => ({ ...entry[1], id: entry[0] }))
        setList(entries)
    }, [sprites]);



    return (
        <>
            <SpritesCard sprites={list} spriteImageURL={url} />
        </>
    );
};

export default Sprites;