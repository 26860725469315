import { SpaceBetween } from "@amzn/awsui-components-react";

import Layout from "../layout/Layout";
import RealmStatus from "./RealmStatus";
import RealmData from "./RealmData";

/**
 * Top level component for the contents of the Offline Maps Dashboard.
 *
 * @return <OfflineMapsDashboard/>
 */
const OfflineMapsDashboard = () => {
  return (
    <Layout>
      <SpaceBetween direction="vertical" size="xs">
        <RealmStatus />
        <RealmData />
      </SpaceBetween>
    </Layout>
  );
};

export default OfflineMapsDashboard;
