import { ContentLayout, Header } from "@amzn/awsui-components-react";
import { FC } from "react";
import Layout from "../../layout/Layout";

const RouteSimulator: FC<any> = () => {
    return (
        <Layout>
            <ContentLayout header={<Header variant="h1">Route Simulator</Header>}>
                <>Route Simulator</>
            </ContentLayout>
        </Layout>
    )
}
export default RouteSimulator;