import React, { useEffect } from 'react';
import Cards from '@amzn/awsui-components-react/polaris/cards';
import { Pagination, TextFilter } from '@amzn/awsui-components-react';

export function SpritesCard({ sprites, spriteImageURL }: any) {
    const [filteringText, setFilteringText] = React.useState('');
    const [filteredList, setFilteredList] = React.useState(sprites);
    const [currentPageIndex, setCurrentPageIndex] = React.useState(1);
    const [pageCount, setPageCount] = React.useState(1);

    

    useEffect(() => {

        const onPageChange = () => {
            const pageSize = 24;
            const index = (currentPageIndex - 1) * pageSize;
            const paginated = sprites.slice(index, index + pageSize);
            setFilteredList(paginated);
        };

        setFilteredList(sprites);
        sprites.length && setPageCount(Math.ceil(sprites.length / 24))
        onPageChange();
    }, [sprites, currentPageIndex]);

    const filterSplices = (text: any) => {
        const filteredSplices = sprites.filter((splice: any) => splice.id.startsWith(text));
        setFilteredList(filteredSplices);
    }

    

    return (
        <Cards
            cardDefinition={{
                header: (e: any) => e.id,
                sections: [
                    {
                        id: 'id',
                        header: '',
                        content: e => <Sprite sprite_style={e} spriteImageURL={spriteImageURL} />
                    },
                ]
            }}
            cardsPerRow={[
                { cards: 2 },
                { minWidth: 500, cards: 3 }
            ]}
            items={filteredList}
            loadingText='Loading resources'
            trackBy='id'
            visibleSections={['id']}
            filter={
                <TextFilter filteringPlaceholder='Find resources'
                    filteringText={filteringText}
                    onChange={({ detail }) => {
                        setFilteringText(detail.filteringText);
                        filterSplices(detail.filteringText);
                    }
                    } />
            }
            pagination={
                <Pagination currentPageIndex={currentPageIndex}
                    ariaLabels={{
                        nextPageLabel: 'Next page',
                        previousPageLabel: 'Previous page',
                        pageLabel: pageNumber =>
                            `Page ${pageNumber} of all pages`
                    }}
                    onChange={({ detail }) => setCurrentPageIndex(detail.currentPageIndex)
                    }
                    pagesCount={pageCount} />
            }
        />
    );
}



const Sprite = (props: any) => {
    const style = props.sprite_style;
    const spriteImageURL = props.spriteImageURL;
    return <>
        <p>width: {style.width}, height: {style.height}</p>

        <div // width='1' height='1'
            style={{
                borderStyle: 'none',
                width: `${style.width}px`,
                height: `${style.height}px`,
                background: `url(${spriteImageURL}@2x.png)
                         -${style.x}px -${style.y}px`
            }
            } />
    </>
}