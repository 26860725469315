import { FilteringProperty } from "@amzn/awsui-components-react/polaris/property-filter/interfaces";
import {
    columnDefinitionToOption,
    configPropsToColumnDefinitions,
    configPropsToFilterProps, NUMBER_FIELD_OPERATORS,
    TableContentConfigProps,
    TEXT_FIELD_OPERATORS
} from "../tableConfig";
import {AggregatedTileMetricsData} from "./tileValidationConfig";
import CopyTextField from "../../components/offline-maps/CopyTextField";

const AGGREGATEDTILEMETRICS_TABLE_CONTENT_CONFIG: TableContentConfigProps<AggregatedTileMetricsData>[] =
    [
        {
            id: "POI",
            description: "POI",
            tableCell: (AggregatedTileMetrics: AggregatedTileMetricsData) => (
                <CopyTextField content={AggregatedTileMetrics.POI} />
            ),
            sortingField: "POI",
            filterGroup: "aggregatedTileTableProp",
            filterOperators: TEXT_FIELD_OPERATORS,
        },
        {
            id: "area_new",
            description: "Area (New)",
            tableCell: (AggregatedTileMetrics: AggregatedTileMetricsData) => (
                <CopyTextField content={AggregatedTileMetrics.area_new} />
            ),
            sortingField: "area_new",
            filterGroup: "aggregatedTileTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "area_old",
            description: "Area (Old)",
            tableCell: (AggregatedTileMetrics: AggregatedTileMetricsData) => (
                <CopyTextField content={AggregatedTileMetrics.area_old} />
            ),
            sortingField: "area_old",
            filterGroup: "aggregatedTileTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "count_new",
            description: "Count (New)",
            tableCell: (AggregatedTileMetrics: AggregatedTileMetricsData) => (
                <CopyTextField content={AggregatedTileMetrics.count_new} />
            ),
            sortingField: "count_new",
            filterGroup: "aggregatedTileTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "count_old",
            description: "Count (Old)",
            tableCell: (AggregatedTileMetrics: AggregatedTileMetricsData) => (
                <CopyTextField content={AggregatedTileMetrics.count_old} />
            ),
            sortingField: "count_old",
            filterGroup: "aggregatedTileTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "count_complex_new",
            description: "Count Complex (New)",
            tableCell: (AggregatedTileMetrics: AggregatedTileMetricsData) => (
                <CopyTextField content={AggregatedTileMetrics.count_complex_new} />
            ),
            sortingField: "count_complex_old",
            filterGroup: "aggregatedTileTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "count_complex_old",
            description: "Count Complex (Old)",
            tableCell: (AggregatedTileMetrics: AggregatedTileMetricsData) => (
                <CopyTextField content={AggregatedTileMetrics.count_complex_old} />
            ),
            sortingField: "count_complex_old",
            filterGroup: "aggregatedTileTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "length_new",
            description: "Length (New)",
            tableCell: (AggregatedTileMetrics: AggregatedTileMetricsData) => (
                <CopyTextField content={AggregatedTileMetrics.length_new} />
            ),
            sortingField: "length_old",
            filterGroup: "aggregatedTileTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "length_old",
            description: "Length (Old)",
            tableCell: (AggregatedTileMetrics: AggregatedTileMetricsData) => (
                <CopyTextField content={AggregatedTileMetrics.length_old} />
            ),
            sortingField: "length_old",
            filterGroup: "aggregatedTileTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
    ];

export const AGGREGATEDTILEMETRICS_TABLE_COLUMN_DEFINITIONS =
    configPropsToColumnDefinitions(AGGREGATEDTILEMETRICS_TABLE_CONTENT_CONFIG);

export const AGGREGATEDTILEMETRICS_TABLE_VISIBLE_CONTENT_OPTIONS: any = [
    {
        label: "Aggregated Tile Metrics Info",
        options: columnDefinitionToOption(AGGREGATEDTILEMETRICS_TABLE_COLUMN_DEFINITIONS),
    },
];

export const AGGREGATEDTILEMETRICS_TABLE_FILTER_PROPERTIES: FilteringProperty[] =
    configPropsToFilterProps(AGGREGATEDTILEMETRICS_TABLE_CONTENT_CONFIG).sort((a, b) =>
        a.propertyLabel.localeCompare(b.propertyLabel)
    );