import { Density, Mode } from '@amzn/awsui-global-styles';
import { create } from 'zustand';
import {persist, createJSONStorage} from 'zustand/middleware';
import { DevelopmentSettings, GeneralSettings, SettingsPersist, SettingsStoreState } from './models/SettingsStore';

const VERSION = 1;

export const useSettingsStore = create<SettingsStoreState>(
    (persist as SettingsPersist)(
        (set, get) => ({
            mapSettings: { 
                updateBasemapCenter: false, 
                inspectType: 'none', 
                showTileBoundaries: false, 
                showCollisionBoxes: false, 
                showOverdrawInspector: false,
                highlightDifferences: false
            },
            setMapSettings: (settings: any) => { set({ mapSettings: { ...get().mapSettings, 
                showTileBoundaries: settings.showTileBoundaries, 
                showCollisionBoxes: settings.showCollisionBoxes,
                showOverdrawInspector: settings.showOverdrawInspector,
                updateBasemapCenter: settings.updateBasemapCenter, 
                inspectType: settings.inspectType,
                highlightDifferences: settings.highlightDifferences 
            } }) },
        
            generalSettings: { 
                mode: Mode.Light,
                density: Density.Comfortable,
                motion: 'False' },
            setGeneralSettings: (settings: GeneralSettings) => { set({ generalSettings: { ...get().generalSettings, 
                mode: settings.mode, 
                density: settings.density,
                motion: settings.motion
            } }) },
            developmentSettings: { 
                enableLocalDevelopment: false,
                localApiUrl: (window as any).Configs['LOCAL_SERVER_URL']
            },
            setDevelopmentSettings: (developmentSettings: DevelopmentSettings) => { set({ developmentSettings: { ...get().developmentSettings, 
                enableLocalDevelopment: developmentSettings.enableLocalDevelopment, 
                localApiUrl: developmentSettings.localApiUrl, 
            } }) }
        }), 
        {
            name: 'settings',
            storage: createJSONStorage(() => sessionStorage),
            version: VERSION
        }
    )
);
