import React from 'react';
import {
    Table, Box, TextContent, ExpandableSection
} from '@amzn/awsui-components-react/polaris';

const FeatureDisplay = ({content}: any) => {
    const groupByLayer = (featureArray: any) => {
            let layers: any = {};
            featureArray.forEach((feature: any) => {
                let layerId = feature['layer']['id'];
                if(layers[layerId]) {
                    layers[layerId].push(feature);
                } else {
                    layers[layerId] = [feature];
                }
            })
            return layers;
    }

    const featureArray = content === null ? {} : content;
    const featuresByLayer: any = groupByLayer(featureArray);
    return (
             <TextContent>
                {Object.keys(featuresByLayer).map((layer) =>
                   <ExpandableSection defaultExpanded key={layer} header={layer}>
                       <FeatureTable features={featuresByLayer[layer]} />
                   </ExpandableSection>
                )}
             </TextContent>
    );
}


const FeatureTable = ({features}: any) => {
    let items = features.map((f: any) => f.properties);
    const columns = Object.keys(items[0]);
    const colDefinitions = columns.map((col) => ({id: col, header: col, cell: (item: any) => item[col], sortingField: col}));
    return (
        <Table
              columnDefinitions={colDefinitions}
              items={items}
              loadingText='Loading resources'
              sortingDisabled
              empty={
                <Box textAlign='center' color='inherit'>
                  <b>No features to display.</b>
                </Box>
              }
            />
          );
}

export default FeatureDisplay;