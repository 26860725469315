import { Header, Tabs } from "@amzn/awsui-components-react";

import TableRealmControl from "./TableRealmControl";
import ComplexFilterTable from "./ComplexFilterTable";
import WorkflowCards from "./WorkflowCards";
import { useOfflineMapsDashboardStore } from "../../store/OfflineMapsDashboardStore";
import { PAGE_SIZE_OPTIONS } from "../../utils/offlineMapsDashboard/offlineDashboardConfig";
import {
  REGION_TABLE_COLUMN_DEFINITIONS,
  REGION_TABLE_VISIBLE_CONTENT_OPTIONS,
  REGION_TABLE_FILTER_PROPERTIES,
} from "../../utils/offlineMapsDashboard/regionTableConfig";
import {
  STYLESHEET_TABLE_COLUMN_DEFINITIONS,
  STYLESHEET_TABLE_VISIBLE_CONTENT_OPTIONS,
  STYLESHEET_TABLE_FILTER_PROPERTIES,
} from "../../utils/offlineMapsDashboard/stylesheetTableConfig";
import {
  BUILDVERSION_TABLE_COLUMN_DEFINITIONS,
  BUILDVERSION_TABLE_VISIBLE_CONTENT_OPTIONS,
  BUILDVERSION_TABLE_FILTER_PROPERTIES,
} from "../../utils/offlineMapsDashboard/buildversionTableConfig";
import { getRealmDropdownOptions } from "../../utils/offlineMapsDashboard/offlineDashboardUtil";

/**
 * Top level component for the Realm Tables section of the Offline Maps Dashboard.
 *
 * @return <RealmData/>
 */
const RealmData = () => {
  const activeRealmData = useOfflineMapsDashboardStore((state) =>
    state.getActiveRealmData()
  );
  const activeRealm = useOfflineMapsDashboardStore(
    (state) => state.offlineMapsActiveRealm
  );
  const activeRealmrealmFetchStatus = useOfflineMapsDashboardStore(
    (state: any) => state.isFetchingOfflineRealmData[activeRealm]
  );
  const regionPreferences = useOfflineMapsDashboardStore(
    (state) => state.offlineRegionTablePreferences
  );
  const setRegionPreferences = useOfflineMapsDashboardStore(
    (state) => state.setOfflineRegionTablePreferences
  );
  const stylesheetPreferences = useOfflineMapsDashboardStore(
    (state) => state.offlineStylesheetTablePreferences
  );
  const setStylesheetPreferences = useOfflineMapsDashboardStore(
    (state) => state.setOfflineStylesheetTablePreferences
  );
  const buildversionPreferences = useOfflineMapsDashboardStore(
      (state) => state.offlineBuildVersionTablePreferences
  );
  const setBuildVersionPreferences = useOfflineMapsDashboardStore(
      (state) => state.setOfflineBuildVersionTablePreferences
  );


    return (
    <div>
      <Header
        variant="h1"
        actions={
          <TableRealmControl dropdownOptions={getRealmDropdownOptions()} />
        }
      >
        {activeRealm} Region Info
      </Header>
      <Tabs
        tabs={[
          {
            label: "Regions",
            id: "first",
            content: (
              <ComplexFilterTable
                itemList={activeRealmData.regions}
                columnDefinitions={REGION_TABLE_COLUMN_DEFINITIONS}
                visibleContentOptions={REGION_TABLE_VISIBLE_CONTENT_OPTIONS}
                fetchingData={activeRealmrealmFetchStatus}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                filterProperties={REGION_TABLE_FILTER_PROPERTIES}
                preferences={regionPreferences}
                setPreferences={setRegionPreferences}
                tableName={"Regions"}
              />
            ),
          },
          {
            label: "Stylesheets",
            id: "second",
            content: (
              <ComplexFilterTable
                itemList={activeRealmData.stylesheets}
                columnDefinitions={STYLESHEET_TABLE_COLUMN_DEFINITIONS}
                visibleContentOptions={STYLESHEET_TABLE_VISIBLE_CONTENT_OPTIONS}
                fetchingData={activeRealmrealmFetchStatus}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                filterProperties={STYLESHEET_TABLE_FILTER_PROPERTIES}
                preferences={stylesheetPreferences}
                setPreferences={setStylesheetPreferences}
                tableName={"Regions"}
              />
            ),
          },
          {
            label: "Workflows",
            id: "third",
            content: (
              <WorkflowCards
                workflows={activeRealmData.workflows}
                isFetchingData={activeRealmrealmFetchStatus}
              />
            ),
          },
          {
            label: "BuildVersions",
            id: "fourth",
            content: (
                <ComplexFilterTable
                    itemList={activeRealmData.buildversions}
                    columnDefinitions={BUILDVERSION_TABLE_COLUMN_DEFINITIONS}
                    visibleContentOptions={BUILDVERSION_TABLE_VISIBLE_CONTENT_OPTIONS}
                    fetchingData={activeRealmrealmFetchStatus}
                    pageSizeOptions={PAGE_SIZE_OPTIONS}
                    filterProperties={BUILDVERSION_TABLE_FILTER_PROPERTIES}
                    preferences={buildversionPreferences}
                    setPreferences={setBuildVersionPreferences}
                    tableName={"Regions"}
                />
            ),
          },
        ]}
      />
    </div>
  );
};

export default RealmData;
