import { User } from 'oidc-client-ts'

const configData = (window as any).Configs;

export function getUser() {
    const oidcStorage = sessionStorage.getItem(`oidc.user:${configData.COGNITO_AUTHORITY}:${configData.COGNITO_CLIENT_ID}`)
    if (!oidcStorage) {
        return null;
    }

    return User.fromStorageString(oidcStorage);
}