import {Map as MapboxGLMap} from "mapbox-gl";

export class ExternalMapsPlugin {
    private map: MapboxGLMap | null = null;
    private container: HTMLDivElement | null = null;
    private button: HTMLElement | null = null;
    private title = 'External Maps';
    private cssClass = 'btn-external-map';
    private _btnClickFn = this._onClick.bind(this);

    onAdd(map: any) {
        this.map = map;
        this.container = document.createElement('div');
        this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
        this.button = document.createElement('BUTTON');
        this.button.className = `mapboxgl-ctrl-icon ${this.cssClass}`;
        this.button.setAttribute('title', this.title);
        this.container.appendChild(this.button);

        // events
        this.button.addEventListener('click', this._btnClickFn);

        return this.container;
    }

    _onClick(evt: any) {
        if (!this.map) {
            return; 
        }
        // get map center and zoom
        const { lng, lat } = this.map.getCenter();
        const zoom = Math.round(this.map.getZoom());
        const externalMapUrls = [
            `https://www.google.com/maps/@${lat},${lng},${zoom}z`,
            `https://www.openstreetmap.org/#map=${zoom}/${lat}/${lng}`
        ]
        for (const url of externalMapUrls) {
            window.open(url, '_blank');
        }
    }

    _onError(evt: any) {
        console.error(evt);
        // do the needful
    }

    onRemove() {
        // remove events
        this.button?.removeEventListener('click', this._btnClickFn);

        this.container?.parentNode?.removeChild(this.container);
        this.map = null;
    }
}
