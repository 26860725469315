import { Alert } from '@amzn/awsui-components-react';
import { FC } from 'react';
import { useNotificationsStore } from '../../../store/NotificationsStore';

interface ErrorMessageProps {
    style: React.CSSProperties
}

const MapErrorMessage: FC<ErrorMessageProps> = ({ style }) => {
    const notificationSettings = useNotificationsStore((state) => state.notificationSettings);
    const getLastFilteredNotification = useNotificationsStore((state) => state.getLastFilteredNotification);
    const readFilteredNotifications = useNotificationsStore((state) => state.readFilteredNotifications);

    const dismissAlert = () => {
        readFilteredNotifications();
    }

    return (
        <>
            {
                notificationSettings.hasFilteredSeverity &&
                <div style={style}>
                    <Alert
                        onDismiss={dismissAlert}
                        dismissAriaLabel='Close alert'
                        dismissible
                        type='error'
                        header={getLastFilteredNotification()?.title}
                    >
                        {getLastFilteredNotification()?.message}
                    </Alert>
                </div>
            }
        </>

    )
};

export default MapErrorMessage;