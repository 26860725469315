import React from 'react';
import {
    Box,
    Checkbox,
    SegmentedControl,
    SpaceBetween,
} from '@amzn/awsui-components-react/polaris';
import { useSettingsStore } from '../../../store/SettingsStore';

export function MapSettings() {
    const mapSettings = useSettingsStore((state) => state.mapSettings);
    const setMapSettings = useSettingsStore((state) => state.setMapSettings);

    const handleUpdateCenter = (checked: any) => {
        const updatedSettings = { ...mapSettings, updateBasemapCenter: checked }
        setMapSettings(updatedSettings);
    };
    const handleInspectType = (inspectMode: any) => {
        const updatedSettings = { ...mapSettings, inspectType: inspectMode };
        setMapSettings(updatedSettings);
    };

    return (<Box padding="s">
        <SpaceBetween size='xs'>
            <Checkbox
                onChange={({ detail }) =>
                    handleUpdateCenter(detail.checked)
                }
                checked={mapSettings.updateBasemapCenter}
            >
                Update Map Center while switching basemap
            </Checkbox>

            <Box variant='p'>
                Inspect Features:
            </Box>
            <SegmentedControl
                selectedId={mapSettings.inspectType}
                onChange={({ detail }) =>
                    handleInspectType(detail.selectedId)
                }
                label='Inspect Features'
                options={[
                    { text: 'None', id: 'none' },
                    { text: 'Popup', id: 'popup' },
                    { text: 'Panel', id: 'panel' }
                ]}
            />

            <Checkbox
                onChange={({ detail }) => {
                    const updatedSettings = { ...mapSettings, showTileBoundaries: detail.checked };
                    setMapSettings(updatedSettings);
                }}
                checked={mapSettings.showTileBoundaries}
            >
                Show TileBoundaries
            </Checkbox>
            <Checkbox
                onChange={({ detail }) => {
                    const updatedSettings = { ...mapSettings, showCollisionBoxes: detail.checked };
                    setMapSettings(updatedSettings);
                }}
                checked={mapSettings.showCollisionBoxes}
            >
                Show CollisionBoxes
            </Checkbox>
            <Checkbox
                onChange={({ detail }) => {
                    const updatedSettings = { ...mapSettings, showOverdrawInspector: detail.checked };
                    setMapSettings(updatedSettings);
                }}
                checked={mapSettings.showOverdrawInspector}
            >
                Show OverdrawInspector
            </Checkbox>
            <Checkbox
                onChange={({ detail }) => {
                    const updatedSettings = { ...mapSettings, highlightDifferences: detail.checked };
                    setMapSettings(updatedSettings);
                }}
                checked={mapSettings.highlightDifferences}
            >
                Highlight Differences
            </Checkbox>

        </SpaceBetween>
    </Box>);
}