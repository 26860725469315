import { ButtonDropdown, ButtonDropdownProps } from "@amzn/awsui-components-react";
import { FC, useEffect, useState } from "react";
import { useMapStore } from "../../../store/MapStore";
import { SplitPanelContentTypes } from "../../../store/models/SplitPanelStore";
import { useSplitPanelStore } from "../../../store/SplitPanelStore";
import ModalController from "../../common/popup/ModalController";

export interface MapActionsProps {
    hasRightMap: boolean;
}
const MapActions: FC<MapActionsProps> = ({hasRightMap}) => {
    const modes = useMapStore((state) => state.modes);
    const mapConfig = useMapStore((state) => state.mapConfig);
    const mapConfigRight = useMapStore((state) => state.mapConfigRight);
    const setLayers = useMapStore((state) => state.setLayers);


    const setModelControllerProps = useMapStore((state) => state.setModelControllerProps);

    const splitPanelSettings = useSplitPanelStore((state) => state.splitPanelSettings);
    const setSplitPanelContent = useSplitPanelStore((state) => state.setSplitPanelContent);
    const clearSplitPanel = useSplitPanelStore((state) => state.clearSplitPanel);

    const [
        dropdownOptions,
        setDropdownOptions
    ] = useState<ButtonDropdownProps.ItemOrGroup[]>([]);

    useEffect(() => {
        const insertIf = (condition: any, ...elements: any[]) => { // (A)
            return condition ? elements : [];
        }

        const options: ButtonDropdownProps.ItemOrGroup[] = [
            {
                text: "Map",
                items: [
                    { text: "Map Configuration", id: "map-configuration" },
                    { text: "Layers", id: "map-layers", iconName: modes.layers === "map-layers" ? 'check' : undefined  },
                    { text: "Stylesheet - Sprites", id: "sprites", iconName: splitPanelSettings.contentType === 'sprites' ? 'check' : undefined },
                    
                ]
            },
            ...insertIf(hasRightMap === true, {
                text: "Map (Right)",
                items: [
                    { text: "Map Configuration", id: "map-configuration-right" },
                    { text: "Layers", id: "map-layers-right", iconName: modes.layers === "map-layers-right" ? 'check' : undefined },
                    { text: "Stylesheet - Sprites", id: "sprites-right", iconName: splitPanelSettings.contentType === 'sprites-right' ? 'check' : undefined },
                ]
            }),
            {
                text: "Navigate To",
                items: [
                    { text: "Zoom to Tile", id: "zoom-to-tile", iconName: splitPanelSettings.contentType === 'zoom-to-tile' ? 'check' : undefined },
                    { text: "Zoom to XY", id: "zoom-to-xy", iconName: splitPanelSettings.contentType === 'zoom-to-xy' ? 'check' : undefined }
                ]
            },
            ...insertIf(hasRightMap !== true,{
                text: "Map Actions",
                items: [
                    { text: "GeoJSON", id: "map-actions-geojson", iconName: splitPanelSettings.contentType === 'geojson' ? 'check' : undefined },
                    { text: "BBox", id: "map-actions-bbox", iconName: splitPanelSettings.contentType === 'bbox' ? 'check' : undefined }
                ]
            })
        ];
        setDropdownOptions(options);
    }, [splitPanelSettings, hasRightMap, modes]);


    const displaySplitPanel = (title: string, contentType: SplitPanelContentTypes, content: any = null) => {
        if (splitPanelSettings.contentType === contentType) {
            clearSplitPanel();
        } else {
            setSplitPanelContent(content, title, contentType, null);
        }
    }

    const onNavClick = (id: string) => {
        if (id === 'sprites' || id === 'sprites-right') {
            let stylesheetJson: any = null;
            if (id === 'sprites' && mapConfig) {
                stylesheetJson = mapConfig.stylesheetJson;
            } else if (id === 'sprites-right' && mapConfigRight) {
                stylesheetJson = mapConfigRight.stylesheetJson;
            }
            if (stylesheetJson) {
                const url = stylesheetJson.sprite;
                displaySplitPanel('Sprites', id, url);
            }
            
        }
        else if (id === 'map-actions-geojson') {
            displaySplitPanel('GeoJSON', 'geojson');
        }
        else if (id === 'map-actions-bbox') {
            displaySplitPanel('BBox', 'bbox');
        }
        else if (id === 'map-configuration' || id === 'map-configuration-right') {
            setModelControllerProps({ size: 'large', title: 'Map Configuration', type: id, visibility: true });
        }
        else if (id === 'zoom-to-tile') {
            displaySplitPanel('Zoom To Tile', 'zoom-to-tile');
        }
        else if (id === 'zoom-to-xy') {
            displaySplitPanel('Zoom To XY', 'zoom-to-xy');
        }
        else if (id === 'map-layers' || id === 'map-layers-right') {
            if (modes.layers === id) {
                setLayers(null);
            } else {
                setLayers(id);
            }
            
        }
    }

    return (
        <div >
            <ButtonDropdown
                items={dropdownOptions}
                ariaLabel="Map Actions"
                onItemClick={(evt: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
                    onNavClick(evt.detail.id);
                }}
                variant="primary"
            >Options</ButtonDropdown>
            <ModalController />
        </div>
    )
}

export default MapActions;

/*
style={{background: '#ec7211',
        paddingRight: '5px',
        paddingBottom: '5px',
        borderRadius: '0px 0px 3px 0px'}}
 */