import React from "react";
import mapboxgl, { Map, PointLike } from 'mapbox-gl';
import { addMapTools, loadMapConfig, renderPopup, RESIZE_TIMEOUT, updateMapSettings } from "../../common/MapUtils";
import { MapConfigResult, MapLocation, MapStoreState, NavigateByLocationProps } from "../../../store/models/MapStore";
import { canvasWithStore, CanvasWithStoreProps } from "./CanvasStore";
import { useSettingsStore } from "../../../store/SettingsStore";
import { MapSettings, SettingsStoreState } from "../../../store/models/SettingsStore";
import { useMapStore } from "../../../store/MapStore";
import { LOG_SEVERITY } from "../../../store/models/NotificationsStore";
var sliderMap = require('mapbox-gl-compare');

export interface MapViewerSliderCanvasProps {
    style: React.CSSProperties
}

interface State {
    lastZoomToLocation: NavigateByLocationProps | null;
    mapConfigResult: MapConfigResult | null;
    mapConfigRightResult: MapConfigResult | null;
    highlightDifferences: boolean;
    mapSettings: MapSettings;
    highlightDifferencesValue: string;
}


class MapViewerSliderCanvas extends React.PureComponent<MapViewerSliderCanvasProps & CanvasWithStoreProps, State> {
    private leftMapContainer: HTMLElement | null | undefined = undefined;
    private leftMap: Map | undefined;

    private rightMapContainer: HTMLElement | null | undefined = undefined;
    private rightMap: Map | undefined;

    private sliderContainer: HTMLElement | null | undefined = undefined;
    private sliderContainerObserver: ResizeObserver | undefined;
    private resizeTimeout: NodeJS.Timeout | undefined;

    private mapStore = this.props.mapStore;
    private settingsStore = this.props.settingsStore;
    private notificationStore = this.props.notificationStore;
    private splitPanelStore = this.props.splitPanelStore;
    private subscriptions: Array<(() => void)> = [];
    private leftMapSubscriptions: Array<any[]> = [];
    private rightMapSubscriptions: Array<any[]> = [];

    private mapStyle: React.CSSProperties = {
        position: 'absolute',
        top: '15px',
        bottom: '15px',
        width: 'calc(100% - 50px)'
    };

    private diffPanelStyle: React.CSSProperties = {
        width: 'max-content',
        height: '40px',
        textAlign: 'center',
        alignSelf: 'first baseline',
        justifySelf: 'center',
        zIndex: 300,
        backgroundColor: 'rgba(35, 55, 75, 0.9)',
        color: '#fff',
        padding: '8px 14px',
        wordSpacing: '5px',
        fontFamily: '"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif',
        borderRadius: '0px 0px 10px 10px'
    }

    constructor(props: MapViewerSliderCanvasProps & CanvasWithStoreProps) {
        super(props);
        this.state = {
            highlightDifferences: false,
            highlightDifferencesValue: '100',
            lastZoomToLocation: null,
            mapConfigResult: null,
            mapConfigRightResult: null,
            mapSettings: this.settingsStore.mapSettings
        };
    }

    resizeMap(maps: (Map | undefined)[]) {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() => {
            for (const map of maps) {
                map?.resize();
            }
        }, RESIZE_TIMEOUT);
    }

    onError(e: any) {
        console.error(e.error);
        this.notificationStore.addNewNotificationMessage(
            {
                title: 'Mapbox Encountered an Error',
                message: `${e.error.message}. Additional errors may be present in the console.`,
                severity: LOG_SEVERITY.WARN
            })
    };

    onLeftFeatureInspect(e: any) {
        if (!this.leftMap) {
            return;
        }
        if (this.state.mapSettings.inspectType === null) {
            return
        }

        // set a bbox around the pointer
        var selectThreshold = 3;
        var queryBox: [PointLike, PointLike] = [
            [
                e.point.x - selectThreshold,
                e.point.y + selectThreshold
            ], // bottom left (SW)
            [
                e.point.x + selectThreshold,
                e.point.y - selectThreshold
            ] // top right (NE)
        ];

        var features = this.leftMap.queryRenderedFeatures(queryBox);

        if (this.state.mapSettings.inspectType === 'panel') {
            const displayProperties = [
                'type',
                'properties',
                'id',
                'layer',
                'source',
                'sourceLayer',
                'state'
            ];

            const displayFeatures = features.map((feat: any) => {
                const displayFeat: any = {};
                displayProperties.forEach((prop) => {
                    displayFeat[prop] = feat[prop];
                });
                return displayFeat;
            });

            this.splitPanelStore.setSplitPanelContent(displayFeatures, 'Feature Details', 'feature-array', null);
        }
        else if (this.state.mapSettings.inspectType === 'popup') {
            if (features.length) {
                const popupContent = renderPopup(features);
                new mapboxgl.Popup({
                    maxWidth: '350px'
                })
                    .setLngLat(e.lngLat)
                    .setHTML(popupContent)
                    .addTo(this.leftMap);
            }
        }
    };
    onRightFeatureInspect(e: any) {
        if (!this.rightMap) {
            return;
        }
        if (this.state.mapSettings.inspectType === null) {
            return
        }

        // set a bbox around the pointer
        var selectThreshold = 3;
        var queryBox: [PointLike, PointLike] = [
            [
                e.point.x - selectThreshold,
                e.point.y + selectThreshold
            ], // bottom left (SW)
            [
                e.point.x + selectThreshold,
                e.point.y - selectThreshold
            ] // top right (NE)
        ];

        var features = this.rightMap.queryRenderedFeatures(queryBox);

        if (this.state.mapSettings.inspectType === 'panel') {
            const displayProperties = [
                'type',
                'properties',
                'id',
                'layer',
                'source',
                'sourceLayer',
                'state'
            ];

            const displayFeatures = features.map((feat: any) => {
                const displayFeat: any = {};
                displayProperties.forEach((prop) => {
                    displayFeat[prop] = feat[prop];
                });
                return displayFeat;
            });

            this.splitPanelStore.setSplitPanelContent(displayFeatures, 'Feature Details', 'feature-array', null);
        }
        else if (this.state.mapSettings.inspectType === 'popup') {
            if (features.length) {
                const popupContent = renderPopup(features);
                new mapboxgl.Popup({
                    maxWidth: '350px'
                })
                    .setLngLat(e.lngLat)
                    .setHTML(popupContent)
                    .addTo(this.rightMap);
            }
        }
    };

    onMoveEnd() {
        if (!this.leftMap) return;
        const mapLocation: MapLocation = {
            lon: this.leftMap.getCenter().lng,
            lat: this.leftMap.getCenter().lat,
            zoom: this.leftMap.getZoom(),
            pitch: this.leftMap.getPitch(),
            bearing: this.leftMap.getBearing(),
        };
        this.mapStore.setMapLocation(mapLocation);
    }

    useSettingsStoreCallback(state: SettingsStoreState, prevState: SettingsStoreState) {
        if (this.leftMap) {
            updateMapSettings(this.leftMap, state.mapSettings);
        }
        if (this.rightMap) {
            updateMapSettings(this.rightMap, state.mapSettings);
        }
        this.setState({ ...this.state, ...{ highlightDifferences: state.mapSettings.highlightDifferences, mapSettings: state.mapSettings } });
    }

    useMapStoreCallback(state: MapStoreState, prevState: MapStoreState) {
        if (this.state.lastZoomToLocation !== state.lastNavigateByLocationProps) {
            this.setState({ ...this.state, ...{ lastZoomToLocation: state.lastNavigateByLocationProps } }, () => {
                if (this.leftMap && state.lastNavigateByLocationProps) {
                    this.leftMap.flyTo({
                        zoom: state.lastNavigateByLocationProps.zoom,
                        center: [state.lastNavigateByLocationProps.longitude, state.lastNavigateByLocationProps.latitude],
                        animate: false
                    });
                }
            });

        }

        if (this.state.mapConfigResult !== state.mapConfig) {

            if (this.leftMap && state.mapConfig) {
                loadMapConfig(this.leftMap, state.mapConfig, this.state.mapConfigResult);

            }
            this.setState({ ...this.state, ...{ mapConfigResult: state.mapConfig } }, () => {
                this.leftMap?.fire('moveend');
            });

        }

        if (this.state.mapConfigRightResult !== state.mapConfigRight) {

            if (this.rightMap && state.mapConfigRight) {
                loadMapConfig(this.rightMap, state.mapConfigRight, this.state.mapConfigRightResult);
            }
            this.setState({ ...this.state, ...{ mapConfigRightResult: state.mapConfigRight } }, () => {
                this.rightMap?.fire('moveend');
            });

        }
    }

    componentDidMount() {
        if (!this.sliderContainer || !this.leftMapContainer || !this.rightMapContainer) return;
        const { lon, lat, zoom, bearing, pitch } = this.mapStore.mapLocation;
        this.leftMap = new mapboxgl.Map({
            container: this.leftMapContainer,
            style: this.mapStore.mapConfig?.stylesheetJson,
            center: [lon, lat],
            zoom: zoom,
            bearing: bearing,
            pitch: pitch
        });
        this.rightMap = new mapboxgl.Map({
            container: this.rightMapContainer,
            style: this.mapStore.mapConfigRight?.stylesheetJson,
            center: [lon, lat],
            zoom: zoom,
            bearing: bearing,
            pitch: pitch
        });
        const onLeftErrorRef = this.onError.bind(this);
        this.leftMapSubscriptions.push(['error', onLeftErrorRef]);
        this.leftMap.on('error', onLeftErrorRef);

        const onRightErrorRef = this.onError.bind(this);
        this.rightMapSubscriptions.push(['error', onRightErrorRef]);
        this.rightMap.on('error', onRightErrorRef);

        const onMoveEndRef = this.onMoveEnd.bind(this);
        this.leftMapSubscriptions.push(['moveend', onMoveEndRef]);
        this.leftMap.on('moveend', onMoveEndRef);

        updateMapSettings(this.leftMap, this.settingsStore.mapSettings);
        updateMapSettings(this.rightMap, this.settingsStore.mapSettings);
        this.setState({ ...this.state, ...{ highlightDifferences: this.settingsStore.mapSettings.highlightDifferences } });


        addMapTools(this.leftMap);
        addMapTools(this.rightMap);

        // feature inspect logic
        const onLeftFeatureInspectRef = this.onLeftFeatureInspect.bind(this);
        this.leftMapSubscriptions.push(['click', onLeftFeatureInspectRef]);
        this.leftMap.on('click', onLeftFeatureInspectRef);

        const onRightFeatureInspectRef = this.onRightFeatureInspect.bind(this);
        this.rightMapSubscriptions.push(['click', onRightFeatureInspectRef]);
        this.rightMap.on('click', onRightFeatureInspectRef);

        new sliderMap(this.leftMap, this.rightMap, this.sliderContainer, {
            mousemove: false, // Optional. Set to true to enable swiping during cursor movement.
            orientation: 'vertical' // Optional. Sets the orientation of swiper to horizontal or vertical, defaults to vertical
        });

        this.sliderContainerObserver = new ResizeObserver(() => { this.resizeMap([this.leftMap, this.rightMap]) });
        this.sliderContainerObserver.observe(this.sliderContainer);

        this.subscriptions.push(useSettingsStore.subscribe(this.useSettingsStoreCallback.bind(this)));
        this.subscriptions.push(useMapStore.subscribe(this.useMapStoreCallback.bind(this)));
    }


    componentWillUnmount() {
        if (this.leftMap) {
            for (const [event, func] of this.leftMapSubscriptions) {
                this.leftMap.off(event, func);
            }
        }
        if (this.rightMap) {
            for (const [event, func] of this.rightMapSubscriptions) {
                this.rightMap.off(event, func);
            }
        }
        this.leftMap?.remove();
        this.rightMap?.remove();
        this.sliderContainerObserver?.disconnect();
        clearTimeout(this.resizeTimeout);

        for (const subscription of this.subscriptions) {
            if (subscription !== null) {
                subscription();
            }
        }
    }

    render() {
        const { highlightDifferences } = this.state;
        return (
            <div style={{ display: 'grid', ...this.props.style }} ref={(el): void => {
                this.sliderContainer = el;
            }}>
                {highlightDifferences && <div style={this.diffPanelStyle}>
                    <div style={{
                        display: 'grid',
                        alignItems: 'center',
                        justifyItems: 'center',
                        placeItems: 'center',
                        gridAutoFlow: 'column',
                        height: '100%',
                        gap: '12px'
                    }}>
                        <label htmlFor="showDiff">Difference</label>
                        <input type="range" id="showDiff" name="showDiff"
                            min={0} 
                            max={1} 
                            step={0.1}
                            value={this.state.highlightDifferencesValue}
                            onChange={(event) => {
                                this.setState({highlightDifferencesValue: event.target.value});
                            }}/>
                    </div>
                </div>}
                <div style={this.mapStyle} ref={(el): void => {
                    this.leftMapContainer = el;
                }} className={highlightDifferences ? 'left-map' : undefined} />
                <div style={{...this.mapStyle, ...{filter: (highlightDifferences && Number(this.state.highlightDifferencesValue) > 0) ? `invert(${this.state.highlightDifferencesValue}) opacity(0.5)`: 'unset'}}} ref={(el): void => {
                    this.rightMapContainer = el;
                }}/>
            </div>
        );
    }
}

export default canvasWithStore(MapViewerSliderCanvas);