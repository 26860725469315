import React from 'react';
import { MapSettings } from './MapSettings';
import { OfflineMapSettings } from './OfflineMapSettings';
import GeneralSettings from './GeneralSettings';
import { Tabs } from '@amzn/awsui-components-react';
import DevelopmentSettings from './DevelopmentSettings';


export function Settings() {
    const [activeTabId, setActiveTabId] = React.useState(
        "general"
    );
    return (
        <Tabs onChange={({ detail }) =>
            setActiveTabId(detail.activeTabId)
        }
            activeTabId={activeTabId}
            tabs={[
                {
                    label: 'General',
                    id: 'general',
                    content: <GeneralSettings />
                },
                {
                    label: 'Map',
                    id: 'map',
                    content: <MapSettings />

                },
                {
                    label: 'Offline',
                    id: 'offline',
                    content: <OfflineMapSettings />,
                    disabled: true
                },
                {
                    label: 'Development',
                    id: 'development',
                    content: <DevelopmentSettings />
                }
            ]}
        />
    );
}
