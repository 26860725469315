import { getUser } from "../apiConfig";
import {
    reformatAggregateMetricsJSON,
    reformatChangeMetricsJSON,
    reformatViolateMetricsJSON
} from "./tileValidationDataTransformation";
const axios = require("axios");

const apiRoot = (window as any).Configs.OFFLINE_MAPS.API_ROOT;
const apiSubdomain = (window as any).Configs.OFFLINE_MAPS.API_SUBDOMAIN;

const TILE_VALIDATION_DASHBOARD_ENDPOINT = "handleRequest";

/**
 * Fetches metrics data
 * @returns JSON output
 */
export const getMetrics = async (tileType: string, tileVersion: number, requestType: string) => {
    try {
        const user = getUser();
        const token = user?.access_token;
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        let realm = "";
        // https://api.offlinemaps.na.beta.foundationstools.maps.amazon.dev/dashboardDataRequest
        console.log(apiRoot);

        // Tile validation lambda is only in one realm for each stage
        // beta -> fe
        // prod -> na
        if (apiRoot === "beta.foundationstools.maps.amazon.dev/") {
            realm = "fe";
        } else if (apiRoot === "foundationstools.maps.amazon.dev/") {
            realm = "na";
        }
        console.log(realm);
        const url =
            apiSubdomain + `.${realm.toLowerCase()}.` + apiRoot + TILE_VALIDATION_DASHBOARD_ENDPOINT
            + `?type=${tileType}&version=${tileVersion}&requestType=${requestType}`;
        const res = await axios.get(url, config);
        console.log(res.data);
        if (res.data) {
            let data  = '';
            let response = await fetch(res.data, {mode: 'cors'})
            if (response.status === 404) {
                console.log("Incorrect tileset type/version: 404 not found error");
                return null;
            }
            data = await response.text();
            // Change to switch case when other APIs are implemented
            if (requestType === "aggregate") {
                let reformatData = reformatAggregateMetricsJSON(JSON.parse(data));
                console.log(reformatData);
                return reformatData;
            } else if (requestType === "violate") {
                let reformatData = reformatViolateMetricsJSON(JSON.parse(data));
                console.log(reformatData);
                return reformatData;
            } else if (requestType === "change") {
                let reformatData = reformatChangeMetricsJSON(JSON.parse(data));
                console.log(reformatData);
                return reformatData;
            } else if (requestType === "size") {
                let reformatData = JSON.parse(data);
                console.log(reformatData);
                return reformatData;
            }
        }
        else return null;
    } catch (error) {
        console.error(error);
        return {
            message: error,
        };
    }
};