export enum LOG_SEVERITY {
    TRACE = 1,
    DEBUG = 2,
    INFO = 3,
    WARN = 4,
    ERROR = 5,
    FATAL = 6
};

export interface NotificationMessage {
    createdOn?: Date;
    title: string;
    message: string;
    severity: LOG_SEVERITY;
}

export interface NotificationsSettings {
    messages: NotificationMessage[];
    hasNew: boolean;
    hasFilteredSeverity: boolean;
}

export interface NotificationsStoreState {
    notificationSettings: NotificationsSettings,
    addNewNotificationMessage: (message: NotificationMessage) => void;
    readAllNotifications: () => void;
    readFilteredNotifications: () => void;
    getLastFilteredNotification: () => NotificationMessage | null;
};