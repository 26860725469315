import { Box, TextContent } from '@amzn/awsui-components-react';
import React, { FC } from 'react';


const JsonContent: FC<{ content: any }> = ({ content }) => {
    return (
        <div style={{ backgroundColor: '#F2F2F2' }}>
            <Box padding={'m'} margin={'m'}>
                <TextContent>
                    <code><pre>{content}</pre></code>
                </TextContent>
            </Box>
        </div>
    );
};

export default JsonContent;