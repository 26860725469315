import {Map as MapboxGLMap} from "mapbox-gl";

export class MapBusyPlugin {
  private map: MapboxGLMap | null = null;
  private container: HTMLDivElement | null = null;
  private button: HTMLElement | null = null;
  private title = 'Loading';
  private cssClass = 'btnbusy';
  private isBusy = false;
  private isCustomBusy = false;

    onAdd(map: any) {
        this.map = map;
        this.container = document.createElement('div');
        this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
        this.button = document.createElement('BUTTON');
        this.button.className = `mapboxgl-ctrl-icon ${this.cssClass}`;
        this.button.setAttribute('title', this.title);
        this.container.appendChild(this.button);

        this.map?.on('render', (evt: any) => {
            this.stopSpinner(evt);
        });

        this.map?.on('dataloading', (evt: any) => {
            this.triggerLoading(evt);
        });

        this.map?.on('data', (evt: any) => {
            this.dataLoading(evt);
        });


        return this.container;
    }

    onRemove() {
        // remove events
        this.container?.parentNode?.removeChild(this.container);
        this.map = null;
    }

    stopSpinner(e: any) {
        if (e.target && e.target.loaded()) {
          this.isBusy = false;
          this.updateUI();
        }
      }
      updateUI() {
        if (this.container) {
          if (this.isBusy || this.isCustomBusy) {
            this.container.style.display = 'block';
          } else {
            this.container.style.display = 'none';
          }
        }
      }
    
      triggerLoading(evt: any) {
        if (evt.sourceId === 'mapbox-gl-draw-hot' || evt.sourceId === 'mapbox-gl-draw-cold') {
          return;
        }
    
        if (evt.sourceId && evt.sourceId.startsWith('graphics_layer_')) {
          return;
        }
        this.isBusy = true;
        this.updateUI();
      }
    
      dataLoading(evt: any) {
        if (evt.sourceId === 'mapbox-gl-draw-hot' || evt.sourceId === 'mapbox-gl-draw-cold') {
          return;
        }
    
        if (evt.sourceId && evt.sourceId.startsWith('graphics_layer_')) {
          return;
        }
      }
}
