import { FilteringProperty } from "@amzn/awsui-components-react/polaris/property-filter/interfaces";
import {
    columnDefinitionToOption,
    configPropsToColumnDefinitions,
    configPropsToFilterProps, NUMBER_FIELD_OPERATORS,
    TableContentConfigProps,
    TEXT_FIELD_OPERATORS
} from "../tableConfig";
import {ViolatedTileMetricsData} from "./tileValidationConfig";
import CopyTextField from "../../components/offline-maps/CopyTextField";
import DetailModal from "../../components/tileValidation/DetailModal";
import MapViewerURLGenerator from "./mapViewerURLGenerator";

let LEFTSTYLESHEETTYPE = "amazon-delivery-us";
let RIGHTSTYLESHEETTYPE = "amazon-delivery-us";
let LEFTSTYLESHEETVERSION = "3.1";
let RIGHTSTYLESHEETVERSION = "3.1";
let LEFTURL = "";
let RIGHTURL = "";

const setViolatedTileMetricsURLConfig = (styleSheetTypeLeft: string, styleSheetVersionLeft: string, URLLeft: string,
                                         styleSheetTypeRight: string, styleSheetVersionRight: string, URLRight: string) => {
    console.log(styleSheetTypeLeft, styleSheetVersionLeft, URLLeft, styleSheetTypeRight, styleSheetVersionRight, URLRight);
    if (styleSheetTypeLeft !== "") {
        LEFTSTYLESHEETTYPE = styleSheetTypeLeft;
    }
    if (styleSheetVersionLeft !== "") {
        LEFTSTYLESHEETVERSION = styleSheetVersionLeft;
    }
    if (URLLeft !== "") {
        LEFTURL = URLLeft;
    }
    if (styleSheetTypeRight !== "") {
        RIGHTSTYLESHEETTYPE = styleSheetTypeRight;
    }
    if (styleSheetVersionRight !== "") {
        RIGHTSTYLESHEETVERSION = styleSheetVersionRight;
    }
    if (URLRight !== "") {
        RIGHTURL = URLRight;
    }
}

const VIOLATEDTILEMETRICS_TABLE_CONTENT_CONFIG: TableContentConfigProps<ViolatedTileMetricsData>[] =
    [
        {
            id: "tile_id",
            description: "Tile ID (x,y,z)",
            tableCell: (ViolatedTileDetails: ViolatedTileMetricsData) => (
                <a href={MapViewerURLGenerator((ViolatedTileDetails.tile_id).split("_")[0],
                    (ViolatedTileDetails.tile_id).split("_")[1], (ViolatedTileDetails.tile_id).split("_")[2],
                LEFTSTYLESHEETTYPE, RIGHTSTYLESHEETTYPE, LEFTSTYLESHEETVERSION, RIGHTSTYLESHEETVERSION,
                LEFTURL, RIGHTURL)} target="_blank" rel="noopener noreferrer">
                    <CopyTextField content={ViolatedTileDetails.tile_id}/>
                </a>
            ),
            sortingField: "tile_id",
            filterGroup: "violatedTileTableProp",
            filterOperators: TEXT_FIELD_OPERATORS,
        },
        {
            id: "tileset_name",
            description: "Tileset Name",
            tableCell: (ViolatedTileDetails: ViolatedTileMetricsData) => (
                <CopyTextField content={ViolatedTileDetails.tileset_name} />
            ),
            sortingField: "tileset_name",
            filterGroup: "violatedTileTableProp",
            filterOperators: TEXT_FIELD_OPERATORS,
        },
        {
            id: "tileset_mode",
            description: "Tileset Mode",
            tableCell: (ViolatedTileDetails: ViolatedTileMetricsData) => (
                <CopyTextField content={ViolatedTileDetails.tileset_mode} />
            ),
            sortingField: "tileset_mode",
            filterGroup: "violatedTileTableProp",
            filterOperators: TEXT_FIELD_OPERATORS,
        },
        {
            id: "validation_metrics",
            description: "Validation Metrics",
            tableCell: (ViolatedTileDetails: ViolatedTileMetricsData) => (
                <DetailModal metric={"Validation Metrics"} violatedTileDetails={ViolatedTileDetails}/>
            ),
            sortingField: "validation_metrics",
            filterGroup: "violatedTileTableProp",
            filterOperators: TEXT_FIELD_OPERATORS,
        },
        {
            id: "change_rate_metrics",
            description: "Change Rate Metrics",
            tableCell: (ViolatedTileDetails: ViolatedTileMetricsData) => (
                <DetailModal metric={"Change Rate Metrics"} violatedTileDetails={ViolatedTileDetails}/>
            ),
            sortingField: "change_rate_metrics",
            filterGroup: "violatedTileTableProp",
            filterOperators: TEXT_FIELD_OPERATORS,
        },
        {
            id: "change_difference_metrics",
            description: "Change Difference Metrics",
            tableCell: (ViolatedTileDetails: ViolatedTileMetricsData) => (
                <DetailModal metric={"Change Difference Metrics"} violatedTileDetails={ViolatedTileDetails}/>
            ),
            sortingField: "change_difference_metrics",
            filterGroup: "violatedTileTableProp",
            filterOperators: TEXT_FIELD_OPERATORS,
        },
        {
            id: "threshold_violation_metrics",
            description: "Threshold Violation Metrics",
            tableCell: (ViolatedTileDetails: ViolatedTileMetricsData) => (
                <DetailModal metric={"Threshold Violation Metrics"} violatedTileDetails={ViolatedTileDetails}/>
            ),
            sortingField: "threshold_violation_metrics",
            filterGroup: "violatedTileTableProp",
            filterOperators: TEXT_FIELD_OPERATORS,
        },
        {
            id: "missing_layer_metrics",
            description: "Missing Layer Metrics",
            tableCell: (ViolatedTileDetails: ViolatedTileMetricsData) => (
                <DetailModal metric={"Missing Layer Metrics"} violatedTileDetails={ViolatedTileDetails}/>
            ),
            sortingField: "missing_layer_metrics",
            filterGroup: "violatedTileTableProp",
            filterOperators: TEXT_FIELD_OPERATORS,
        }
    ];

export const VIOLATEDTILEMETRICS_TABLE_COLUMN_DEFINITIONS =
    configPropsToColumnDefinitions(VIOLATEDTILEMETRICS_TABLE_CONTENT_CONFIG);

export const VIOLATEDTILEMETRICS_TABLE_VISIBLE_CONTENT_OPTIONS: any = [
    {
        label: "Violated Tile Metrics Info",
        options: columnDefinitionToOption(VIOLATEDTILEMETRICS_TABLE_COLUMN_DEFINITIONS),
    },
];

export const VIOLATEDTILEMETRICS_TABLE_FILTER_PROPERTIES: FilteringProperty[] =
    configPropsToFilterProps(VIOLATEDTILEMETRICS_TABLE_CONTENT_CONFIG).sort((a, b) =>
        a.propertyLabel.localeCompare(b.propertyLabel)
    );

export default setViolatedTileMetricsURLConfig;