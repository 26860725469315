
import { create } from 'zustand';
import { SplitPanelContentTypes, SplitPanelStoreState } from './models/SplitPanelStore';


export const useSplitPanelStore = create<SplitPanelStoreState>((set, get) => ({
    splitPanelSettings: {
        preference: { position: 'side' },
        contentId: null,
        content: null,
        title: null,
        contentType: null,
    },

    setSplitPanelPreference: (updatedPreference: any) => {
        set({ splitPanelSettings: { ...get().splitPanelSettings, preference: updatedPreference } });
    },
    setSplitPanelContent: (content: any | null, title: string | null, type: SplitPanelContentTypes | null, contentId: any | null) => {
        set({
            splitPanelSettings:
            {
                ...get().splitPanelSettings,
                content: content,
                title: title,
                contentType: type,
                contentId: contentId
            }
        });
    },
    clearSplitPanel: () => {
        set({
            splitPanelSettings: {
                ...get().splitPanelSettings,
                contentType: null,
                content: null,
                title: null,
                contentId: null
            }
        });
    }
}));