import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl, { VectorSource } from 'mapbox-gl';
import { Map } from 'mapbox-gl';
import { MapConfigResult } from '../../store/models/MapStore';
import { MapSettings } from '../../store/models/SettingsStore';
import { fetchResourceFromUrl } from '../../utils/apiCall';
import { ExternalMapsPlugin } from './map-controls/ExternalMapsPlugin';
import { MapBusyPlugin } from './map-controls/MapBusyPlugin';

export const RESIZE_TIMEOUT = 500;
export const MAP_PAGE_STYLE = { height: 'calc(100vh - 95px)' };

export const addMapTools = (map: Map) => {

    // add geocoder
    map.addControl(
        new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            collapsed: true,
            limit: 10,
        })
    );

    // Add zoom and rotation controls to the map.
    map.addControl(new mapboxgl.NavigationControl());

    map.addControl(new mapboxgl.FullscreenControl());

    map.addControl(new ExternalMapsPlugin());

    map.addControl(new MapBusyPlugin());
}

export const updateMapSettings = (map: Map, mapSettings: MapSettings) => {
    if (map.showTileBoundaries !== mapSettings.showTileBoundaries) {
        map.showTileBoundaries = mapSettings.showTileBoundaries;
    }

    if (map.showCollisionBoxes !== mapSettings.showCollisionBoxes) {
        map.showCollisionBoxes = mapSettings.showCollisionBoxes;
    }

    if ((map as any).showOverdrawInspector !== mapSettings.showOverdrawInspector) {
        (map as any).showOverdrawInspector = mapSettings.showOverdrawInspector;
    }
}

const getColor = (index: number) => {
    const colors = ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c', '#fdbf6f', '#ff7f00', '#cab2d6', '#6a3d9a', '#ffff99', '#b15928'];
        if (index < colors.length) {
            return colors[index];
        }

        // return random color if layers list is too long
        return `#${(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0')}`;
}

const getLayerStyle = (color: string, layer_name: string, geometry_type: string) => {
    if (geometry_type === 'line_string') {
        return {
            'id': `${layer_name}_${geometry_type}`,
            'type': 'line',
            'source': 'xray',
            'source-layer': layer_name,
            'filter': ["==", "$type", "LineString"],
            'layout': {
                'line-join': 'round',
                'line-cap': 'round'
            },
            'paint': {
                'line-color': color,
                'line-width': 1,
                'line-opacity': 0.75
            }
        };
    }
    else if (geometry_type === 'polygon') {
        return {
            'id': `${layer_name}_${geometry_type}`,
            'type': 'line',
            'source': 'xray',
            'source-layer': layer_name,
            'filter': ["==", "$type", "Polygon"],
            'layout': {
                'line-join': 'round',
                'line-cap': 'round'
            },
            'paint': {
                'line-color': color,
                'line-width': 1,
                'line-opacity': 0.75
            }
        };
    }
    else if (geometry_type === 'point') {
        return {
            'id': `${layer_name}_${geometry_type}`,
            'type': 'circle',
            'source': 'xray',
            'source-layer': layer_name,
            'filter': ["==", "$type", "Point"],
            'paint': {
                'circle-color': color,
                'circle-radius': 2.5,
                'circle-opacity': 0.75
            }
        };
    }
    else {
        console.error('unhandled geometry type');
    }
    return null;
}

export const getXrayJson = (styleJson: any) => {
    const sources = styleJson.sources;
    if (sources['AmznVector']) {
        const layersIds = Array.from(new Set<any>(styleJson.layers.filter((item: any) => item && item['source-layer']).map((item: any) => item['source-layer'])));
        const geomTypes = ['point', 'line_string', 'polygon'];
            const styleLayers = [];
            for (const geom of geomTypes) {
                let i = 0
                for (const layerId of layersIds) {
                    // skip system layers
                    if (layerId.startsWith('gl-draw-') || layerId.startsWith('tilegentools_')) {
                        continue;
                    }
                    const color = getColor(i);
                    styleLayers.push(getLayerStyle(color, layerId, geom));
                    i += 1;
                }
            }

            const sourceClone = JSON.parse(JSON.stringify(sources['AmznVector']));

            const styleXrayJson: any = {
                version: 8,
                sources: { xray: sourceClone },
                layers: styleLayers,
            };
            return styleXrayJson;
    }
    return styleJson;
}

function displayValue(value: any, propName: any) {
    if (propName === '@timestamp') {
      return value.toString() + '<br>[ ' + (new Date(value * 1000)).toISOString() + ' ]';
    }
    if (typeof value === 'undefined' || value === null) return value;
    if (typeof value === 'object' ||
      typeof value === 'number' ||
      typeof value === 'string') return value.toString();
    return value;
  }

  function renderProperty(propertyName: any, property: any) {
    return '<div class="mbview_property">' +
      '<div class="mbview_property-name">' + propertyName + '</div>' +
      '<div class="mbview_property-value">' + displayValue(property, propertyName) + '</div>' +
      '</div>';
  }

  function renderLayer(layerId: any) {
    return '<div class="mbview_layer">' + layerId + '</div>';
  }

  function renderProperties(feature: any) {
    var sourceProperty = renderLayer(feature.layer['source-layer'] || feature.layer.source);
    var idProperty = renderProperty('$id', feature.id);
    var typeProperty = renderProperty('$type', feature.geometry.type);
    var properties = Object.keys(feature.properties).map(function (propertyName) {
      return renderProperty(propertyName, feature.properties[propertyName]);
    });
    properties.push(renderProperty('Source Layer', feature.layer['source-layer'] || feature.layer.source));
    properties.push(renderProperty('Layer Id', feature.layer['id'] || feature.layer.id));
    return (feature.id ? [sourceProperty, idProperty, typeProperty]
      : [sourceProperty, typeProperty]).concat(properties).join('');
  }

  function renderFeatures(features: any) {
    return features.map(function (ft: any) {
      return '<div class="mbview_feature">' + renderProperties(ft) + '</div>';
    }).join('');
  }


  export const renderPopup = (features: any) => {
    return '<div class="mbview_popup">' + renderFeatures(features) + '</div>';
  };

export const loadMapConfig = (map: Map, mapConfig: MapConfigResult, prevMapConfig: MapConfigResult | null) => {
    if (mapConfig.enableXrayMode === false && mapConfig.stylesheetJson) {
        if (mapConfig.stylesheetJson !== prevMapConfig?.stylesheetJson) {
            map.setStyle(mapConfig.stylesheetJson);
        }
    } else if (mapConfig.enableXrayMode === true && mapConfig.stylesheetXrayJson) {
        if (mapConfig.stylesheetXrayJson !== prevMapConfig?.stylesheetXrayJson) {
            map.setStyle(mapConfig.stylesheetXrayJson);
        }
    }

    if (mapConfig.hiddenLayers !== prevMapConfig?.hiddenLayers) {
        if (map && mapConfig && map.isStyleLoaded()) {
            const layers = map.getStyle().layers;
            for (const layer of layers) {
                const mapLayer = map.getLayer(layer.id);
                if (mapLayer) {
                    if (mapConfig.hiddenLayers.has(layer.id)) {
                        (mapLayer as any).setLayoutProperty('visibility', 'none');
                    } else {
                        (mapLayer as any).setLayoutProperty('visibility', 'visible');
                    }
                }
            }
            
        }
    }
}