import { Box, Checkbox, FormField, Input, SpaceBetween } from "@amzn/awsui-components-react";
import { FC } from "react";
import { useSettingsStore } from "../../../store/SettingsStore";


const DevelopmentSettings: FC = () => {
    const developmentSettings = useSettingsStore((state) => state.developmentSettings);
    const setDevelopmentSettings = useSettingsStore((state) => state.setDevelopmentSettings);


    return (<Box padding="s">
        <SpaceBetween size='xs'>
            <FormField
                label="Enable Local Development"
            >
                <Checkbox
                    onChange={({ detail }) =>
                        setDevelopmentSettings({ ...developmentSettings, enableLocalDevelopment: detail.checked })
                    }
                    checked={developmentSettings.enableLocalDevelopment}
                >
                    Enable Local Development
                </Checkbox>
            </FormField>

            <FormField
                label="Local Api Url"
            >
                <Input
                    onChange={({ detail }) =>
                        setDevelopmentSettings({ ...developmentSettings, localApiUrl: detail.value })
                    }
                    disabled={!developmentSettings.enableLocalDevelopment}
                    value={developmentSettings.localApiUrl}
                />
            </FormField>
        </SpaceBetween>
    </Box>)
}

export default DevelopmentSettings;