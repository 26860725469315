import mapboxgl, { GeoJSONSource } from 'mapbox-gl';
import React, { useEffect, useRef } from 'react';
import { useGeoJsonStore } from '../../../../store/GeojsonStore';


export const GeoJsonLayer = ({currentMap}: any) => {
    const settings = useGeoJsonStore((state) => state.settings);
    const map = useRef<mapboxgl.Map | null>(null);
    const source_name = 'tilegentools_geojson';

    // using the updated map object
    useEffect(() => {
        map.current = currentMap;
    }, [currentMap]);

    useEffect(() => {
        if (!map.current || !map.current.isStyleLoaded() || !settings.mapViewData) return () => { };

        const sourceObj = map.current.getSource(source_name) as GeoJSONSource;
        if (!sourceObj) {
            map.current.addSource(source_name, {
                type: 'geojson',
                data: settings.mapViewData,
                promoteId: 'featureId'
            });
        } else {
            sourceObj.setData(settings.mapViewData);
        }

        const layerColor = '#16191f';

        if (!map.current.getLayer(`${source_name}-polygons`)) {
            map.current.addLayer({
                'id': `${source_name}-lines`,
                'type': 'line',
                'source': source_name,
                'filter': ['==', '$type', 'LineString'],
                'layout': {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                'paint': {
                    'line-color': layerColor,
                    'line-width': 3,
                    'line-opacity': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        1,
                        0.5
                    ],
                }
            });



            map.current.addLayer({
                'id': `${source_name}-pts`,
                'type': 'circle',
                'source': source_name,
                'filter': ['==', '$type', 'Point'],
                'paint': {
                    'circle-color': layerColor,
                    'circle-radius': 5,
                    'circle-opacity': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        1,
                        0.5
                    ],
                }
            });

            map.current.addLayer({
                'id': `${source_name}-polygons`,
                'type': 'fill',
                'source': source_name,
                'filter': ['==', '$type', 'Polygon'],
                'layout': {},
                'paint': {
                    'fill-opacity': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        1,
                        0.5
                    ],
                    'fill-color': layerColor
                }
            });

            map.current.addLayer({
                'id': `${source_name}-polygons-outline`,
                'type': 'line',
                'source': source_name,
                'filter': ['==', '$type', 'Polygon'],
                'layout': {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                'paint': {
                    'line-color': layerColor,
                    'line-width': 3,
                    'line-opacity': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        1,
                        0.5
                    ],
                }
            });
        }

    }, [map, settings.mapViewData]);


    useEffect(() => {
        if (!map.current || !map.current.isStyleLoaded() || !map.current.getSource(source_name)) return () => { };

        map.current.removeFeatureState({
            source: source_name
        });

        if (settings.selectedFeature) {
            map.current.setFeatureState(
                {
                    source: source_name,
                    id: settings.selectedFeature
                },
                {
                    hover: true
                }
            );
        }


    }, [map, settings.selectedFeature]);

    return (
        <></>
    );
};