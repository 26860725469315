import { Button, Container, Header, SpaceBetween } from "@amzn/awsui-components-react";
import { CSSProperties, FC, useEffect, useState } from "react";
import { useMapStore } from "../../../store/MapStore";
import { useSplitPanelStore } from "../../../store/SplitPanelStore";

export interface MapLayersProps {
    isRightMap: boolean;
    style: CSSProperties;
}

const ShowHideIcon = ({ visible }: any) => {
    if (visible === false) {
        return (
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-eye-slash' viewBox='0 0 16 16'>
                <path d='M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z' />
                <path d='M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z' />
                <path d='M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z' />
            </svg>
        )
    } else {
        return (
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-eye' viewBox='0 0 16 16'>
                <path d='M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z' />
                <path d='M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z' />
            </svg>
        )
    }
}

const MapLayers: FC<MapLayersProps> = ({ style, isRightMap }) => {
    const setHiddenLayers = useMapStore((state) => state.setHiddenLayers);
    const mapConfig = useMapStore((state) => state.mapConfig);
    const mapLocation = useMapStore((state) => state.mapLocation);

    const mapConfigRight = useMapStore((state) => state.mapConfigRight);
    const splitPanelSettings = useSplitPanelStore((state) => state.splitPanelSettings);
    const setSplitPanelContent = useSplitPanelStore((state) => state.setSplitPanelContent);
    const clearSplitPanel = useSplitPanelStore((state) => state.clearSplitPanel);

    const [selectedLayerId, setSelectedLayerId] = useState(null);

    const [layers, setLayers] = useState([]);
    const [allLayers, setAllLayers] = useState([]);

    useEffect(() => {
        const config = isRightMap ? mapConfigRight : mapConfig;
        if (config) {
            const styleJson = config.stylesheetJson;
            const layers = styleJson.layers;
            setAllLayers(layers);
            const currentZoom = mapLocation.zoom;
            // Filter layers based on zoom level
            let filteredLayers = layers.filter(((layer: any) => {
                return (layer.minzoom === undefined || layer.minzoom <= currentZoom) &&
                    ((layer: any) => layer.maxzoom === undefined || layer.maxzoom >= currentZoom)
            }));
            setLayers(filteredLayers);
        }

    }, [mapConfig, mapConfigRight, isRightMap, mapLocation]);


    const hideAllLayers = () => {
        const layersIds: string[] = allLayers.map((item:any) => item.id);
        setHiddenLayers(new Set(layersIds), isRightMap);
        //allLayers.forEach((layer: any) => currentMap.getLayer(layer.id).setLayoutProperty('visibility', 'none'));
        //currentMap.fire('moveend');
    }

    const showAllLayers = () => {
        setHiddenLayers(new Set([]), isRightMap);
        //allLayers.forEach((layer: any) => currentMap.getLayer(layer.id).setLayoutProperty('visibility', 'visible'));
        //currentMap.fire('moveend');
    }


    const toggleVisibility = (id: any, index: any) => {
        const config = isRightMap ? mapConfigRight : mapConfig;
        if (config) {
            const exist = config.hiddenLayers.has(id);
            let items = null
            if (exist) {
                items = Array.from(config.hiddenLayers).filter(item => item !== id);
            } else {
                const temp = Array.from(config.hiddenLayers);
                temp.push(id);
                items = temp;
            }
            setHiddenLayers(new Set(items), isRightMap);
        }
        

        /*
        let currentVis = currentMap.getLayer(id).getLayoutProperty('visibility');
        let newVis = currentVis === 'none' ? 'visible' : 'none';
        currentMap.getLayer(id).setLayoutProperty('visibility', newVis);
        setLayerVis({ ...layerVis, [id]: newVis });
        currentMap.fire('moveend');
        */
    }

    const handleLayerClick = (value: any) => {
        // If new layer is clicked, open the split panel & update the content
        if (value.id !== selectedLayerId || splitPanelSettings.contentType !== 'json') {
            setSelectedLayerId(value.id);
            setSplitPanelContent(value, `Layer Details for ${value.id}`, 'json', null);
            // If same panel is clicked, close the panel & clear the panel content
        } else {
            setSelectedLayerId(null);
            clearSplitPanel();
        }
    };

    const isVisible = (id: string) => {
        const config = isRightMap ? mapConfigRight : mapConfig;
        if (config) {
            const visible = !config.hiddenLayers.has(id);
            return visible;
        }
        return true;
    };

    return (
        <div style={{ ...style, ...{ height: '100%', width: '300px', display: 'grid' } }} className="layers_new">
            <Container header={
                <Header variant='h2' actions={
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button onClick={hideAllLayers}>Hide All</Button>
                        <Button onClick={showAllLayers}>
                            Show All
                        </Button>
                    </SpaceBetween>
                }>
                    <SpaceBetween direction='vertical' size="s">
                        <div style={{height: '10px'}}></div>
                        <span>
                            Layers {isRightMap ? '(Right)' : ''}  <span className='count'>{`(${layers.length}/${allLayers.length})`}</span>
                        </span>
                    </SpaceBetween>
                    
                </Header>}>
                <div>
                    {layers.map((value: any, index: any) => {
                        return <div key={index} className={(value.id === selectedLayerId && splitPanelSettings.contentType === 'json') ? 'layer active-layer' : 'layer'} onClick={() => handleLayerClick(value)}>
                            <div>{value.id}</div>
                            <div onClick={(e) => { e.stopPropagation(); }}>
                                <div onClick={() => toggleVisibility(value.id, index)}><ShowHideIcon visible={isVisible(value.id)} /></div>
                            </div>
                        </div>
                    })}
                </div>

            </Container>
        </div>

    );
}

export default MapLayers;