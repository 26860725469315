import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { DevicePreviewConfig, DeviceViewStorePersist, DeviceViewStoreState } from "./models/DeviceViewStore";


const VERSION = 1;

export const DEFAULT_DEVICE_VIEWS = {
    devices: {
        'generic-phone': {
            width: 360,
            height: 640,
            skin: 'smartphone'
        },
        'generic-tablet': {
            width: 768,
            height: 1024,
            skin: 'tablet'
        },
        'no-frame': {
            width: 360,
            height: 640,
            skin: 'none'
        }
    },
    data: [{
        name: "GAIA English - OSM Based",
        columns: 2,
        items: [
            {
                lat: 47.6077,
                lon: -122.3394,
                zoom: 8.57,
                pitch: 0,
                bearing: 0,
                device: 'generic-phone',
                label: 'Seattle'
            },
            {
                lat: 40.6979,
                lon: -73.98,
                zoom: 9.99,
                pitch: 0,
                bearing: 0,
                device: 'generic-phone',
                label: 'New York'
            },
            {
                lat: 47.6037,
                lon: -122.3301,
                zoom: 16.2,
                pitch: 0,
                bearing: 0,
                device: 'generic-phone',
                label: 'Seattle - Street Level'
            },
            {
                lat: 40.7303,
                lon: -73.9887,
                zoom: 15.99,
                pitch: 0,
                bearing: 0,
                device: 'generic-phone',
                label: 'New York - Street Level'
            }
        ]
    }]
};

export const useDeviceViewStore = create<DeviceViewStoreState>(
    (persist as DeviceViewStorePersist)(
        (set, get) => ({
            deviceViews: { ...DEFAULT_DEVICE_VIEWS },
            updateDeviceViews: (items: DevicePreviewConfig) => set((state: any) => ({ deviceViews: items })),
            deviceScale: "1",
            updateDeviceScale: (scale: string) => set((state: any) => ({ deviceScale: scale })),
            selectedProfile: null,
            updateSelectedProfile: (selectedProfile: string | null) => set((state: any) => ({ selectedProfile: selectedProfile }))
        }),
        {
            name: 'device-view-config',
            storage: createJSONStorage(() => sessionStorage),
            version: VERSION
        }
    )

)

