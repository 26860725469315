import { FC } from "react";
import { useMapStore } from "../../../store/MapStore";

interface MapLocationBarProps {
    style: React.CSSProperties
}

const MapLocationBar: FC<MapLocationBarProps> = ({style}) => {
    const mapLocation = useMapStore((state) => state.mapLocation);
    return (
        <div className='sidebar' style={style}>
            Longitude: {mapLocation.lon.toFixed(4)} | Latitude: {mapLocation.lat.toFixed(4)} | Zoom: {mapLocation.zoom.toFixed(2)} | Pitch: {mapLocation.pitch.toFixed(2)} | Bearing: {mapLocation.bearing.toFixed(2)}
        </div>)
}

export default MapLocationBar;