import {
  Button,
  ExpandableSection,
  Header,
  Link,
  Modal,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import { useState } from "react";
import { RegionData } from "../../utils/offlineMapsDashboard/offlineDashboardConfig";
import {
  NAV_DETAIL_MODAL_CONTENT,
  REGION_DETAIL_MODAL_CONTENT,
  STYLESHEET_DETAIL_MODAL_CONTENT,
  TILE_DETAIL_MODAL_CONTENT,
} from "../../utils/offlineMapsDashboard/regionTableConfig";
import CopyTextField from "./CopyTextField";
import EpochDateField from "./EpochDateField";
import { DisplayContentTypeDefinition } from "./models/DisplayContentTypeDefinition";
import { DISPLAY_TYPE } from "./models/DisplayType";

/**
 * Maps an array of DisplayContentTypeDefinition to JSX Elements based on the definitions.
 *
 * @param contentDefinition - set of content definitions to generate
 * @param region - region data for the content
 * @param index - key value for the generated components
 * @returns
 */
function generateContentComponent(
  contentDefinition: DisplayContentTypeDefinition,
  region: RegionData,
  index: number,
  objectIndex?: number
): JSX.Element {
  switch (contentDefinition.displayType) {
    case DISPLAY_TYPE.Text:
      return (
        <CopyTextField
          key={index}
          description={contentDefinition.description}
          content={region[contentDefinition.regionDataKey!]}
        />
      );
    case DISPLAY_TYPE.Date:
      return (
        <EpochDateField
          key={index}
          description={contentDefinition.description}
          epochTime={region[contentDefinition.regionDataKey!] as number}
        />
      );
    case DISPLAY_TYPE.CustomText:
      return (
        <CopyTextField
          key={index}
          description={contentDefinition.description}
          content={contentDefinition.customFormat!(region, objectIndex)}
        />
      );
  }
}

/**
 * Modal which displays more detailed information about each region.
 *
 * @param region - contains information about the region to be displayed in the modal
 * @return <DetailModal region={region}/>
 */
const DetailModal = ({ region }: { region: RegionData }) => {
  const [visible, setVisible] = useState(false);
  //different order than below, medas has different order than bboxfinder
  const bboxfinderLink =
    "http://bboxfinder.com/#" +
    region.boundingBox.minLat +
    "," +
    region.boundingBox.minLong +
    "," +
    region.boundingBox.maxLat +
    "," +
    region.boundingBox.maxLong;

  const stylesheets = region["tileArtifactModel.styleSheetModels"];

  return (
    <div>
      <Button
        iconName="status-info"
        variant="icon"
        onClick={() => setVisible(true)}
      />
      <Modal
        onDismiss={() => setVisible(false)}
        visible={visible}
        header={
          <Header>
            <SpaceBetween direction="horizontal" size="s">
              <div>{region.regionName}</div>
              <Link external={true} href={bboxfinderLink}>
                BBoxFinder
              </Link>
            </SpaceBetween>
          </Header>
        }
      >
        <div style={{height: '70vh', overflow: 'auto'}}>
          <Header variant="h3">Region Info</Header>
          {REGION_DETAIL_MODAL_CONTENT.map((definition, index) =>
            generateContentComponent(definition, region, index)
          )}

          <Header variant="h3">Nav Artifacts</Header>
          {NAV_DETAIL_MODAL_CONTENT.map((definition, index) =>
            generateContentComponent(definition, region, index)
          )}

          <Header variant="h3">Tile Artifacts</Header>
          {TILE_DETAIL_MODAL_CONTENT.map((definition, index) =>
            generateContentComponent(definition, region, index)
          )}

          <Header variant="h3">Stylesheet</Header>
          {stylesheets &&
            stylesheets.map((stylesheet, stylesheetIndex) => {
              return (
                <ExpandableSection
                  key={stylesheetIndex}
                  header={stylesheet.id}
                  defaultExpanded={stylesheets.length === 1 ? true : false}
                >
                  {STYLESHEET_DETAIL_MODAL_CONTENT.map(
                    (definition, propertyIndex) =>
                      generateContentComponent(
                        definition,
                        region,
                        propertyIndex,
                        stylesheetIndex
                      )
                  )}
                </ExpandableSection>
              );
            })}
        </div>
      </Modal>
    </div>
  );
};

export default DetailModal;
