import { getUser } from "../apiConfig";

const axios = require("axios");

const apiRoot = (window as any).Configs.OFFLINE_MAPS.API_ROOT;
const apiSubdomain = (window as any).Configs.OFFLINE_MAPS.API_SUBDOMAIN;

const DASHBOARD_ENDPOINT = "dashboardDataRequest";

/**
 * Fetches data for the provided realm.
 *
 * @param realm the realm to fetch data for eg. 'NA'
 * @returns
 */
export const getRealm = async (realm: string) => {
  try {
    const user = getUser();
    const token = user?.access_token;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const url =
      apiSubdomain + `.${realm.toLowerCase()}.` + apiRoot + DASHBOARD_ENDPOINT;

    const res = await axios.get(url, config);

    if (res.data && res.data.body) {
      let data  = ''
      let response = await fetch(res.data.body, {mode: 'cors'})
      data = await response.text();
      return JSON.parse(data);
    }
    else return null;
  } catch (error) {
    console.error(error);
    return {
      message: error,
    };
  }
};
