import {
  PropertyFilterOperator,
  PropertyFilterOperatorExtended,
} from "@amzn/awsui-collection-hooks";
import { PropertyFilterProps, TableProps } from "@amzn/awsui-components-react";
import { FilteringProperty } from "@amzn/awsui-components-react/polaris/property-filter/interfaces";
import { DisplayContentTypeDefinition } from "../components/offline-maps/models/DisplayContentTypeDefinition";
import { DISPLAY_TYPE } from "../components/offline-maps/models/DisplayType";
import { RegionData } from "./offlineMapsDashboard/offlineDashboardConfig";

export interface TableContentConfigProps<T> {
  id: string;
  description: string;
  //table controls
  tableCell?: (item: T) => JSX.Element;
  sortingField?: string;
  sortingComparator?: (lhs: T, rhs: T) => number;
  filterOperators?: (
    | PropertyFilterOperator
    | PropertyFilterOperatorExtended<string>
  )[];
  filterGroup?: string;
  //detail modal
  displayType?: DISPLAY_TYPE;
  displayCustomFormat?: (region: RegionData, index?: number) => string | number;
}

/**
 * Generates Table column definitions based on a set of TableContentConfigProps models.
 * Any models missing a tableCell field will be filtered out from the result.
 *
 * @param tableContent table content definition to create column definitions from
 * @returns an array of column definitions
 */
export function configPropsToColumnDefinitions<T>(
  tableContent: TableContentConfigProps<T>[]
): TableProps.ColumnDefinition<T>[] {
  //filter out elements that won't be included in the table
  const displayContent = tableContent.filter(
    (field: TableContentConfigProps<T>) =>
      field.tableCell !== undefined ? true : false
  );
  return displayContent.map(
    (field: TableContentConfigProps<T>): TableProps.ColumnDefinition<T> => {
      if (field.sortingComparator) {
        return {
          id: field.id,
          header: field.description,
          cell: field.tableCell!,
          sortingComparator: field.sortingComparator,
        };
      } else {
        return {
          id: field.id,
          header: field.description,
          cell: field.tableCell!,
          sortingField: field.sortingField,
        };
      }
    }
  );
}

/**
 * Generates Table filter props based on a set of TableContentConfigProps models.
 * Any models missing a filterGroup field will be filtered out from the result.
 *
 * @param tableContent table content definition to create filter props from
 * @returns an array of filter props
 */
export function configPropsToFilterProps<T>(
  tableContent: TableContentConfigProps<T>[]
): FilteringProperty[] {
  //filter out elements that won't be included in the table
  const displayContent = tableContent.filter(
    (field: TableContentConfigProps<T>) =>
      field.filterGroup !== undefined ? true : false
  );
  return displayContent.map((field: TableContentConfigProps<T>) => {
    return {
      key: field.id,
      propertyLabel: field.description,
      groupValuesLabel: field.description,
      group: field.filterGroup,
      operators: field.filterOperators,
    };
  });
}

/**
 * Generates DetailModal display elements based on a set of TableContentConfigProps models.
 * Any models missing a displayType field will be filtered out from the result.
 *
 * @param tableContent table content definition to create display elements from
 * @returns an array of display elements
 */
export function configPropsToDisplay<T>(
  tableContent: TableContentConfigProps<T>[]
): DisplayContentTypeDefinition[] {
  //filter out elements that won't be displayed
  const displayContent = tableContent.filter(
    (field: TableContentConfigProps<T>) =>
      field.displayType !== undefined ? true : false
  );
  return displayContent.map((field: TableContentConfigProps<T>) => {
    return {
      regionDataKey: field.id as keyof RegionData,
      description: field.description,
      displayType: field.displayType!,
      customFormat: field.displayCustomFormat,
    };
  });
}

/**
 * Handles clean comparison between strings with potentially undefined values.
 * Undefined values are considered greater than defined values.
 *
 * @param lhs
 * @param rhs
 * @returns -1 if lhs < rhs, 0 if equal, 1 if lhs > rhs
 */
export function stringComparator(lhs: string, rhs: string): number {
  if (lhs)
    if (rhs) return lhs.localeCompare(rhs);
    else return -1;
  else if (rhs) return 1;
  return 0;
}

/**
 * Handles clean comparison between stringified integers with potentially undefined values.
 * Undefined values are considered greater than defined values.
 *
 * @param lhs
 * @param rhs
 * @returns -1 if lhs < rhs, 0 if equal, 1 if lhs > rhs
 */
export function stringIntComparator(lhs: string, rhs: string): number {
  if (lhs)
    if (rhs) return parseInt(lhs) - parseInt(rhs);
    else return -1;
  else if (rhs) return 1;
  return 0;
}

export const PROPERTY_FILTERING_I18N_CONSTANTS: PropertyFilterProps.I18nStrings =
  {
    filteringAriaLabel: "your choice",
    dismissAriaLabel: "Dismiss",

    filteringPlaceholder: "Search",
    groupValuesText: "Values",
    groupPropertiesText: "Properties",
    operatorsText: "Operators",

    operationAndText: "and",
    operationOrText: "or",

    operatorLessText: "Less than",
    operatorLessOrEqualText: "Less than or equal",
    operatorGreaterText: "Greater than",
    operatorGreaterOrEqualText: "Greater than or equal",
    operatorContainsText: "Contains",
    operatorDoesNotContainText: "Does not contain",
    operatorEqualsText: "Equals",
    operatorDoesNotEqualText: "Does not equal",

    editTokenHeader: "Edit filter",
    propertyText: "Property",
    operatorText: "Operator",
    valueText: "Value",
    cancelActionText: "Cancel",
    applyActionText: "Apply",
    allPropertiesLabel: "All properties",

    tokenLimitShowMore: "Show more",
    tokenLimitShowFewer: "Show fewer",
    clearFiltersText: "Clear filters",
    removeTokenButtonAriaLabel: (token: PropertyFilterProps.Token) =>
      `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
    enteredTextLabel: (text: any) => `Use: "${text}"`,
  };

/**
 * Converts an object with nested object properties into a single level object.
 *
 * @param object        - the target object to flatten
 * @param ignoreKeys    - an array of keys to ignore in the object
 * @returns a new object with nested properties on a single level formatted as ['prop.key']
 */
export function flattenObject(object: any, ignoreKeys: string[]) {
  const newObject: any = {};
  for (const key in object) {
    if (
      typeof object[key] === "object" &&
      !Array.isArray(object[key]) &&
      !ignoreKeys.includes(key)
    ) {
      const nestedObject = flattenObject(object[key], ignoreKeys);

      for (const nestedKey in nestedObject) {
        newObject[`${key}.${nestedKey}`] = nestedObject[nestedKey];
      }
    } else {
      newObject[key] = object[key];
    }
  }
  return newObject;
}

/**
 * Converts a UTC epoch into an epoch based on locale date for easier comparison.
 *
 * @param utcEpoch  - utc epoch in milliseconds to convert
 * @returns epoch for locale date, meant only for comparing dates
 */
export function mapLocaleAsUTC(utcEpoch: number): number {
  const utcDate = new Date(utcEpoch);

  const convertedDate = new Date(0);
  convertedDate.setUTCFullYear(
    utcDate.getFullYear(),
    utcDate.getMonth(),
    utcDate.getDate()
  );

  return Date.parse(convertedDate.toISOString());
}

/**
 * Maps a set of column definitions to options which can be toggled on and off.
 *
 * @param columnDefinitions - the set of column definitions to convert
 * @returns [{id, label}] objects which can be used by the table as options
 */
export function columnDefinitionToOption(
  columnDefinitions: any
): { id: string; label: string }[] {
  return columnDefinitions.map((column: any) => ({
    id: column.id,
    label: column.header,
  }));
}

/**
 * Maps a set of column definitions to their ids to make all columns visible by default.
 *
 * @param columnDefinitions - the set of column definitions to convert
 * @returns [id] strings which represent the visible column ids
 */
export function defaultVisibleContentOptionsFromColumns(
  columnDefinitions: any
): string[] {
  return columnDefinitions.map((column: any) => column.id);
}

//FILTER SETTINGS
export const TEXT_FIELD_OPERATORS: PropertyFilterProps.ComparisonOperator[] = [
  ":", //contains
  "!:",
  "=",
  "!=",
];

export const NUMBER_FIELD_OPERATORS: PropertyFilterProps.ComparisonOperator[] =
  ["<", "<=", ">", ">=", "=", "!="];

/**
 * Maps string operators to the built in type
 *
 * @param lhs       - left hand comparison
 * @param rhs       - right hand comparison
 * @param operator  - string representation of the the operator to use
 * @returns result of comparison
 */
export function compare(lhs: any, rhs: any, operator: string): boolean {
  switch (operator) {
    case "=":
      return lhs === rhs;
    case "!=":
      return lhs !== rhs;
    case "<":
      return lhs < rhs;
    case "<=":
      return lhs <= rhs;
    case ">":
      return lhs > rhs;
    case ">=":
      return lhs >= rhs;
    default:
      console.log("Unsupported operator: " + operator);
      return false;
  }
}

/**
 * Date filter form comparison function, takes an epoch from the table and a locale ISO string
 * from the form and converts them into epochs for simple comparison.
 *
 * @param tableEpochValue   - epoch value from the table data
 * @param formToken         - locale ISO date string from the filter form
 * @param operator          - string representation of the comparison operation to be preformed
 * @returns result of the comparison, invalid comparisons return false
 */
export function dateComparison(
  tableEpochValue: number,
  formToken: string,
  operator: string
): boolean {
  //invalid comparisons don't show up through filtering
  if (isNaN(tableEpochValue) || !formToken) return false;
  //compare locale iso vs locale iso, both mapped to epoch for a single value vs YYYY/MM/DD
  return compare(
    mapLocaleAsUTC(tableEpochValue),
    Date.parse(formToken),
    operator
  );
}

export function floatComparison(itemValue: any, tokenValue: any, operator: string): boolean {
    const itemFloat = parseFloat(itemValue);
    const tokenFloat = parseFloat(tokenValue);

    if (isNaN(itemFloat) || isNaN(tokenFloat)) {
        // If either value is not a valid float, return false
        return false;
    }

    return compare(itemFloat, tokenFloat, operator);
}