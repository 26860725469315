import { FilteringProperty } from "@amzn/awsui-components-react/polaris/property-filter/interfaces";

import CopyTextField from "../../components/offline-maps/CopyTextField";

import { StyleSheetData } from "./offlineDashboardConfig";
import {
  columnDefinitionToOption,
  configPropsToColumnDefinitions,
  configPropsToFilterProps,
  dateComparison,
  NUMBER_FIELD_OPERATORS,
  TableContentConfigProps,
  TEXT_FIELD_OPERATORS,
} from "../tableConfig";
import EpochDateField from "../../components/offline-maps/EpochDateField";
import {
  formatDate,
  TableDateFilterForm,
} from "../../components/offline-maps/TableDateFilterForm";

const STYLESHEET_TABLE_CONTENT_CONFIG: TableContentConfigProps<StyleSheetData>[] =
  [
    {
      id: "country",
      description: "Country",
      tableCell: (stylesheet: StyleSheetData) => (
        <CopyTextField content={stylesheet.country} />
      ),
      sortingField: "country",
      filterGroup: "stylesheetTableProp",
      filterOperators: TEXT_FIELD_OPERATORS,
    },
    {
      id: "created",
      description: "Created",
      tableCell: (stylesheet: StyleSheetData) => (
        <EpochDateField epochTime={stylesheet.created} />
      ),
      sortingField: "created",
      filterGroup: "stylesheetTableProp",
      filterOperators: NUMBER_FIELD_OPERATORS.map((operator) => ({
        operator,
        form: TableDateFilterForm,
        format: formatDate,
        match: (itemValue: any, tokenValue: any) =>
          dateComparison(itemValue, tokenValue, operator),
      })),
    },
    {
      id: "id",
      description: "Id",
      tableCell: (stylesheet: StyleSheetData) => (
        <CopyTextField content={stylesheet.id} />
      ),
      sortingField: "id",
      filterGroup: "stylesheetTableProp",
      filterOperators: TEXT_FIELD_OPERATORS,
    },
    {
      id: "maxZoom",
      description: "Max Zoom",
      tableCell: (stylesheet: StyleSheetData) => (
        <CopyTextField content={stylesheet.maxZoom} />
      ),
      sortingField: "maxZoom",
      filterGroup: "stylesheetTableProp",
      filterOperators: NUMBER_FIELD_OPERATORS,
    },
    {
      id: "md5",
      description: "MD5",
      tableCell: (stylesheet: StyleSheetData) => (
        <CopyTextField content={stylesheet.md5} />
      ),
      sortingField: "md5",
      filterGroup: "stylesheetTableProp",
      filterOperators: TEXT_FIELD_OPERATORS,
    },
    {
      id: "modified",
      description: "Modified",
      tableCell: (stylesheet: StyleSheetData) => (
        <EpochDateField epochTime={stylesheet.modified} />
      ),
      sortingField: "modified",
      filterGroup: "stylesheetTableProp",
      filterOperators: NUMBER_FIELD_OPERATORS.map((operator) => ({
        operator,
        form: TableDateFilterForm,
        format: formatDate,
        match: (itemValue: any, tokenValue: any) =>
          dateComparison(itemValue, tokenValue, operator),
      })),
    },
    {
      id: "name",
      description: "Name",
      tableCell: (stylesheet: StyleSheetData) => (
        <CopyTextField content={stylesheet.name} />
      ),
      sortingField: "name",
      filterGroup: "stylesheetTableProp",
      filterOperators: TEXT_FIELD_OPERATORS,
    },
    {
      id: "status",
      description: "Status",
      tableCell: (stylesheet: StyleSheetData) => (
        <CopyTextField content={stylesheet.status} />
      ),
      sortingField: "status",
      filterGroup: "stylesheetTableProp",
      filterOperators: TEXT_FIELD_OPERATORS,
    },
    {
      id: "stylesheetVersion",
      description: "Stylesheet Version",
      tableCell: (stylesheet: StyleSheetData) => (
        <CopyTextField content={stylesheet.stylesheetVersion} />
      ),
      sortingField: "stylesheetVersion",
      filterGroup: "stylesheetTableProp",
      filterOperators: TEXT_FIELD_OPERATORS,
    },
    {
      id: "url",
      description: "URL",
      tableCell: (stylesheet: StyleSheetData) => (
        <CopyTextField content={stylesheet.url} />
      ),
      sortingField: "url",
      filterGroup: "stylesheetTableProp",
      filterOperators: TEXT_FIELD_OPERATORS,
    },
    {
      id: "variant",
      description: "Variant",
      tableCell: (stylesheet: StyleSheetData) => (
        <CopyTextField content={stylesheet.variant} />
      ),
      sortingField: "variant",
      filterGroup: "stylesheetTableProp",
      filterOperators: TEXT_FIELD_OPERATORS,
    },
    {
      id: "version",
      description: "Version",
      tableCell: (stylesheet: StyleSheetData) => (
        <CopyTextField content={stylesheet.version} />
      ),
      sortingField: "version",
      filterGroup: "stylesheetTableProp",
      filterOperators: TEXT_FIELD_OPERATORS,
    },
  ];

export const STYLESHEET_TABLE_COLUMN_DEFINITIONS =
  configPropsToColumnDefinitions(STYLESHEET_TABLE_CONTENT_CONFIG);

export const STYLESHEET_TABLE_VISIBLE_CONTENT_OPTIONS: any = [
  {
    label: "Stylesheet Info",
    options: columnDefinitionToOption(STYLESHEET_TABLE_COLUMN_DEFINITIONS),
  },
];

export const STYLESHEET_TABLE_FILTER_PROPERTIES: FilteringProperty[] =
  configPropsToFilterProps(STYLESHEET_TABLE_CONTENT_CONFIG).sort((a, b) =>
    a.propertyLabel.localeCompare(b.propertyLabel)
  );
