import React from "react";
import mapboxgl, { GeoJSONSource, Map } from 'mapbox-gl';
import { RegionData } from "../../../utils/offlineMapsDashboard/offlineDashboardConfig";
import { RESIZE_TIMEOUT } from "../../common/MapUtils";

export interface OfflineBboxPreviewProps {
    region: RegionData
}

interface State {
    region: RegionData
}


class OfflineBboxPreview extends React.PureComponent<OfflineBboxPreviewProps, State> {
    private mapContainer: HTMLElement | null | undefined = undefined;
    private map: Map | undefined;
    private mapResizeObserver: ResizeObserver | undefined;
    private resizeTimeout: NodeJS.Timeout | undefined;
    constructor(props: OfflineBboxPreviewProps) {
        super(props);
        this.state = {
            region: props.region
        };
    }

    loadRegion(region: RegionData) {
        if (!this.map) return;
        this.map.fitBounds([
            [region.boundingBox.minLong, region.boundingBox.minLat],
            [region.boundingBox.maxLong, region.boundingBox.maxLat]
        ], {
            padding: { top: 100, bottom: 100, left: 100, right: 100 }
        });

        const dsId = 'map-geojson-ds';
        const geoJsonData = {
            'type': 'geojson',
            'data': {
                'type': 'Feature',
                'geometry': {
                    'type': 'Polygon',
                    'coordinates': [
                        [
                            [region.boundingBox.minLong, region.boundingBox.minLat],
                            [region.boundingBox.maxLong, region.boundingBox.minLat],
                            [region.boundingBox.maxLong, region.boundingBox.maxLat],
                            [region.boundingBox.minLong, region.boundingBox.maxLat],
                            [region.boundingBox.minLong, region.boundingBox.minLat]
                        ]
                    ]
                }
            }
        };
        if (!this.map.getSource(dsId)) {
            this.map.addSource(dsId, geoJsonData as any);
        } else {
            (this.map.getSource(dsId) as GeoJSONSource).setData(geoJsonData['data'] as any);
        }

        const fillLayerId = 'map-geojson-fill-layer';
        if (!this.map.getLayer(fillLayerId)) {
            this.map.addLayer({
                'id': fillLayerId,
                'type': 'fill',
                'source': dsId,
                'layout': {},
                'paint': {
                    'fill-color': '#232f3e',
                    'fill-opacity': 0.5
                }
            });
        }

        const lineLayerId = 'map-geojson-line-layer';
        if (!this.map.getLayer(lineLayerId)) {
            this.map.addLayer({
                'id': lineLayerId,
                'type': 'line',
                'source': dsId,
                'layout': {},
                'paint': {
                    'line-color': '#232f3e',
                    'line-width': 3
                }
            });
        }
    }


    resizeMap(maps: (Map | undefined)[]) {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() => {
            for (const map of maps) {
                map?.resize();
            }
        }, RESIZE_TIMEOUT);
    }


    componentDidMount() {
        if (!this.mapContainer) return;
        const { region } = this.state;
        this.map = new mapboxgl.Map({
            container: this.mapContainer,
            style: 'https://5.visualization.resources.maps.a2z.com/styles/amazon-delivery-rabbit-internal/AmazonDelivery1.16.0_rabbit_internal-09242021-0734.json',
            center: [(region.boundingBox.minLong + region.boundingBox.maxLong) / 2, (region.boundingBox.minLat + region.boundingBox.maxLat) / 2],
            zoom: 9
        });

        this.map.once('load', () => {
            this.loadRegion(region);
        });


        this.mapResizeObserver = new ResizeObserver(() => { this.resizeMap([this.map]) });
        this.mapResizeObserver.observe(this.mapContainer);
    }


    componentWillUnmount() {
        this.map?.remove();
        this.mapResizeObserver?.disconnect();
        clearTimeout(this.resizeTimeout);
    }

    componentDidUpdate(prevProps: Readonly<OfflineBboxPreviewProps>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.map) {
            if (JSON.stringify(prevProps.region) !== JSON.stringify(this.props.region)) {
                this.loadRegion(this.props.region);
            } 
        }
    }

    render() {
        return (
            <div ref={(el): void => {
                this.mapContainer = el;
            }} className="map-container" />
        );
    }
}

export default OfflineBboxPreview;