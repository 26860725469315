

import React from 'react';
import {
  Table,
  Box,
  Button,
  Header,
  Pagination,
  CollectionPreferences,
  SpaceBetween,
  Popover,
  StatusIndicator
} from '@amzn/awsui-components-react/polaris';
import { useBboxStore } from '../../../../store/BboxStore';
import { BboxTableViewRow } from '../../../../store/models/BboxStore';

const BboxTableView = () => {
  const setSelection = useBboxStore((state) => state.setSelection);
  const settings = useBboxStore((state) => state.settings);
  const clearSelection = useBboxStore((state) => state.clearSelection);
  const deleteSelection = useBboxStore((state) => state.deleteSelection);

  const onClearSelectionClicked = () => {
    clearSelection();
  };

  const onDeleteSelectedClicked = () => {
    deleteSelection();
  };

  const onCopySelectedClicked = () => {
    if (settings.selectedFeature) {
      const index = settings.selectedFeature - 1;
      navigator.clipboard.writeText(settings.data[index].join(', '));
    }
  };

  const onSelectionChange = ({ detail }: any) => {
    if (detail.selectedItems.length > 0) {
      setSelection(detail.selectedItems[0].featureId);
    } else {
      setSelection(null);
    }
  };

  const getSelection = (): Array<{ featureId: number }> => {
    if (settings.selectedFeature === null) {
      return [];
    }
    return [{ featureId: settings.selectedFeature }]
  };

  return (
    <Table
      onSelectionChange={onSelectionChange}
      selectedItems={getSelection()}
      columnDefinitions={[
        {
          id: 'featureId',
          header: 'FeatureId',
          cell: (e: BboxTableViewRow) => e.featureId,
          sortingField: 'featureId'
        },
        {
          id: 'xMin',
          header: 'XMin',
          cell: (e: BboxTableViewRow) => e.xMin,
          sortingField: 'xMin'
        },
        {
          id: 'yMin',
          header: 'YMin',
          cell: (e: BboxTableViewRow) => e.yMin,
          sortingField: 'yMin'
        },
        {
          id: 'xMax',
          header: 'XMax',
          cell: (e: BboxTableViewRow) => e.xMax,
          sortingField: 'xMax'
        },
        {
          id: 'yMax',
          header: 'YMax',
          cell: (e: BboxTableViewRow) => e.yMax,
          sortingField: 'yMax'
        },
      ]}
      items={settings.tableViewData}
      loadingText='Loading resources'
      selectionType='single'
      trackBy='featureId'
      visibleColumns={[
        'featureId',
        'xMin',
        'yMin',
        'xMax',
        'yMax'
      ]}
      empty={
        <Box textAlign='center' color='inherit'>
          <b>No features to display.</b>
        </Box>
      }
      header={
        <Header>
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              {(settings.selectedFeature !== null) && <Button
                onClick={onClearSelectionClicked}>
                Clear Selection
              </Button>
              }

              {(settings.selectedFeature !== null) && <Button
                iconName='copy'
                onClick={onDeleteSelectedClicked}>
                Delete Seleted Feature
              </Button>
              }

              {(settings.selectedFeature !== null) &&
                <Popover
                  size='small'
                  position='top'
                  triggerType='custom'
                  dismissButton={false}
                  content={<StatusIndicator type='success'>copied</StatusIndicator>}
                >
                  <Button
                    iconName='copy'
                    onClick={onCopySelectedClicked}>
                    Copy Seleted Feature
                  </Button>
                </Popover>
              }
            </SpaceBetween>
          </Box>
        </Header>
      }
      pagination={
        <>
          <Pagination
            currentPageIndex={1}
            pagesCount={2}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: pageNumber =>
                `Page ${pageNumber} of all pages`
            }}
          />
        </>
      }
      preferences={
        <CollectionPreferences
          title='Preferences'
          confirmLabel='Confirm'
          cancelLabel='Cancel'
          preferences={{
            pageSize: 10,
            visibleContent: [
              'featureId',
              'xMin',
              'yMin',
              'xMax',
              'yMax'
            ]
          }}
          pageSizePreference={{
            title: 'Select page size',
            options: [
              { value: 10, label: '10 resources' },
              { value: 20, label: '20 resources' }
            ]
          }}
          visibleContentPreference={{
            title: 'Select visible content',
            options: [
              {
                label: 'Main distribution properties',
                options: [
                  {
                    id: 'featureId',
                    label: 'FeatureId',
                    editable: false
                  },
                  { id: 'xMin', label: 'XMin' },
                  { id: 'yMin', label: 'YMin' },
                  { id: 'xMax', label: 'XMax' },
                  { id: 'yMax', label: 'YMax' },
                ]
              }
            ]
          }}
        />
      }
    />
  );
}


export default BboxTableView;