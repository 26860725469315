import { useState } from "react";
import Calendar from "@amzn/awsui-components-react/polaris/calendar";
import DateInput from "@amzn/awsui-components-react/polaris/date-input";
import FormField from "@amzn/awsui-components-react/polaris/form-field";

/**
 * Form used by the table filter for date field selection.
 *
 * @param props - React Props:
 *  value: current form value
 *  onChange: handles updating the form value
 * @return <TableDateFilterForm onChange={onChange} value={value}/>
 *
 */
export const TableDateFilterForm = ({
  value,
  onChange,
}: {
  value: any;
  onChange: any;
}) => {
  //types derived from collection hooks
  const [{ dateValue }, setState] = useState(value ? value : "");

  const onChangeDate = (dateValue: string) => {
    setState((state: any) => ({ ...state, dateValue }));
    onChange(dateValue);
  };

  return (
    <div className="date-form">
      <FormField description="Date" constraintText="Use YYYY/MM/DD format.">
        <DateInput
          placeholder="YYYY/MM/DD"
          value={dateValue}
          onChange={(event) => onChangeDate(event.detail.value)}
        />
      </FormField>

      <Calendar
        value={dateValue}
        //locale="en-EN"
        previousMonthAriaLabel="Previous month"
        nextMonthAriaLabel="Next month"
        todayAriaLabel="Today"
        onChange={(event) => onChangeDate(event.detail.value)}
      />
    </div>
  );
};

/**
 * Converts an ISO formated locale based date into a localeDateString.
 *
 * @param isoDate - the ISO date string to format
 * @returns string resulting from a call to date.toLocaleDateString()
 */
export function formatDate(isoDate: string): string {
  //iso is parsed as if utc
  const utcDate = new Date(isoDate);
  //convert to utc for clean locale formatting
  const localeDate = new Date(0);
  localeDate.setFullYear(
    utcDate.getUTCFullYear(),
    utcDate.getUTCMonth(),
    utcDate.getUTCDate()
  );
  return isoDate ? localeDate.toLocaleDateString() : "";
}
