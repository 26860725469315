import React from "react";
import {
  Box,
  Cards,
  ExpandableSection,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import { WorkflowData } from "../../utils/offlineMapsDashboard/offlineDashboardConfig";
import CopyTextField from "./CopyTextField";
import EpochDateField from "./EpochDateField";

/**
 * Handles formatting for the workflow details section of RealmData.
 *
 * @param workflows - WorkflowData entries for the available workflows
 * @param isFetchingData - true if the workflow data is still being fetched, false otherwise
 * @returns <WorkflowCards workflows={workflows} isFetchingData={isFetchingData}/>
 */
const WorkflowCards = ({
  workflows,
  isFetchingData,
}: {
  workflows: WorkflowData[];
  isFetchingData: boolean;
}) => {
  return (
    <Cards
      items={workflows}
      cardDefinition={{
        sections: [
          {
            content: (workflow: WorkflowData) => {
              return workflowLayout(workflow);
            },
          },
          {
            content: (workflow: WorkflowData) => {
              return valueLayout(workflow);
            },
          },
        ],
      }}
      cardsPerRow={[{ cards: workflows.length }]}
      loading={isFetchingData}
    />
  );

  /**
   * Handles formating for the workflow summary section of the workflow cards.
   *
   * @param workflow - the target workflow to format
   * @returns component with workflow properties and statusMap displayed
   */
  function workflowLayout(workflow: WorkflowData): JSX.Element {
    const statusEntries = Object.entries(workflow.value.statusMap);
    return (
      <>
        <Box variant="h4">Workflow:</Box>
        <CopyTextField
          description={"Workflow Id"}
          content={workflow.workflowId}
        />
        <CopyTextField description={"Version"} content={workflow.version} />
        <EpochDateField
          description={"Created"}
          epochTime={workflow.workflowCreationTimestamp}
        />
        <EpochDateField
          description={"Last Updated"}
          epochTime={workflow.lastUpdatedTimestamp}
        />
        <Box variant="h5">Status:</Box>
        {statusEntries.map(([key, value], index) => (
          <CopyTextField key={index} description={key} content={value} />
        ))}
      </>
    );
  }

  /**
   * Handles formatting for the value section of the workflow cards.
   *
   * @param workflow - the target workflow to format
   * @returns component with workflow.value properties displayed
   */
  function valueLayout(workflow: WorkflowData): JSX.Element {
    const valueEntries = Object.entries(workflow.value);
    const simpleEntries = [];
    const objectEntries = [];

    for (const entry of valueEntries) {
      //value type is object
      if (typeof entry[1] === "object") {
        //ignore statusMap
        if (entry[0] !== "statusMap") objectEntries.push(entry);
      } else {
        simpleEntries.push(entry);
      }
    }

    return (
      <>
        <Box variant="h4">Values:</Box>
        <SpaceBetween size="xxs">
          {simpleEntries.map(([key, value], index) => (
            <CopyTextField
              key={index}
              description={camelCaseToTitle(key)}
              content={value}
            />
          ))}
          {objectEntries.map(([key, value], index) => {
            return (
              <ExpandableSection
                defaultExpanded
                key={index}
                header={camelCaseToTitle(key)}
              >
                <CopyTextField content={JSON.stringify(value)} />
              </ExpandableSection>
            );
          })}
        </SpaceBetween>
      </>
    );
  }

  /**
   * Converts the provided string from camelCase to Title Case.
   *
   * @param value - the string to convert
   * @returns Title Case version of value
   */
  function camelCaseToTitle(value: string): string {
    //add spaces before every capital letter
    const spaceString = value.replace(/([A-Z])/g, " $1");
    //capitalize first letter
    const titleString =
      spaceString.charAt(0).toUpperCase() + spaceString.slice(1);
    return titleString;
  }
};

export default WorkflowCards;
