import Layout from '../../layout/Layout';
import { FC } from 'react';
import DevicePreviewMap from './DevicePreviewMap';
import { Box, ContentLayout, Header, SpaceBetween } from '@amzn/awsui-components-react';
import DevicePreviewControl from './DevicePreviewControl';
import { useDeviceViewStore } from '../common/store/DeviceViewStore';
import { DevicePreviewConfig } from '../common/store/models/DeviceViewStore';


const StylesheetDevicePreview: FC<any> = () => {
    const deviceViews = useDeviceViewStore((state) => state.deviceViews); // tablet, smartphone, laptop
    const deviceScale = useDeviceViewStore((state) => state.deviceScale);
    const selectedProfile = useDeviceViewStore((state) => state.selectedProfile);

    
    const getTitle = (profile: string | null, viewConfig: DevicePreviewConfig) => {
        if (!profile) return null;
        const profileViews = viewConfig.data.find(item => item.name === profile);
        if (!profileViews) {
            return null;
        }
        return profileViews.name;
    }
    const getColumns = (profile: string | null, viewConfig: DevicePreviewConfig) => {
        if (!profile) return 0;
        const profileViews = viewConfig.data.find(item => item.name === profile);
        if (!profileViews) {
            return 0;
        }
        return profileViews.columns;
    }

    const getDevices = (profile: string | null, viewConfig: DevicePreviewConfig) => {
        if (!profile) return [];
        const profileViews = viewConfig.data.find(item => item.name === profile);
        if (!profileViews) {
            return [];
        }

        const devices = viewConfig.devices;

        const result: any[] = [];
        for (let i = 0; i < profileViews.items.length; i++) {
            const item = profileViews.items[i];
            result.push((
                <div key={i} style={{ alignSelf: 'end' }}>
                    <SpaceBetween size={'m'} direction='vertical' >
                        <Box variant='h2' textAlign="center">{item.label}</Box>
                        <Box variant='h4' textAlign="center">{`${item.zoom}/${item.lon}/${item.lat}`}</Box>
                        <div className={devices[item.device]?.skin} style={{width: `${devices[item.device]?.width}px`, height: `${devices[item.device]?.height}px`}}>
                            <DevicePreviewMap
                                lat={item.lat}
                                lon={item.lon}
                                zoom={item.zoom}
                                bearing={item.bearing}
                                pitch={item.pitch} />
                        </div>
                    </SpaceBetween>
                </div>
            ))

        }
        return result;
    }
    return (
        <Layout>
            <ContentLayout header={
                <Header
                    variant={'h1'}
                    actions={
                        <DevicePreviewControl />
                    }
                >
                    Device Preview
                </Header>
            }>
                <div style={{ transform: `scale(${deviceScale})`, transformOrigin: '0px 0px', display: 'flex' }}>
                    <SpaceBetween size={'l'} direction='vertical'>
                        <Box variant='h1' textAlign="center">{getTitle(selectedProfile, deviceViews)}</Box>
                        <div style={{ display: 'grid', gap: '20px', gridTemplateColumns: `repeat(${getColumns(selectedProfile, deviceViews)}, minmax(min-content, max-content))` }}>
                            {getDevices(selectedProfile, deviceViews)}
                        </div>
                    </SpaceBetween>
                </div>
                
            </ContentLayout>
        </Layout>
    )
};

export default StylesheetDevicePreview;