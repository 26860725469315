import { Modal } from "@amzn/awsui-components-react";
import { FC } from "react";
import { useMapStore } from "../../../store/MapStore";
import { MapConfiguration } from "./MapConfiguration";


const ModalController: FC = () => {
    const modalControllerProps = useMapStore((state) => state.modalControllerProps);
    const setModelVisibility = useMapStore((state) => state.setModelVisibility);

    return ((modalControllerProps.visibility === true ? <Modal
        onDismiss={() => setModelVisibility(false)}
        visible={modalControllerProps.visibility}
        closeAriaLabel="Close modal"
        size={modalControllerProps.size}
        header={`${modalControllerProps.title} ${modalControllerProps.type === 'map-configuration-right' ? '(Right Side)' : ''}`}
    >
        {
            {
                'map-configuration': <MapConfiguration isRight={false}/>,
                'map-configuration-right': <MapConfiguration isRight={true}/>,
            }[ modalControllerProps.type ]
        }

    </Modal> : <></>));
}

export default ModalController;