import React from "react";
import {
  Table,
  Header,
  CollectionPreferences,
  Pagination,
  PropertyFilter,
  TableProps,
  CollectionPreferencesProps,
} from "@amzn/awsui-components-react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { PROPERTY_FILTERING_I18N_CONSTANTS } from "../../utils/tableConfig";

/**
 * Table component for displaying the information about each region in the given realm.
 *
 * @param itemList - list of items with contents to display in the table
 * @param columnDefinitions - header definitions for the table columns
 * @param visibleContentOptions - defines the toggleable column settings
 * @param fetchingData - true if data for the table is pending from another source
 * @param pageSizeOptions - options for the page size settings
 * @param filterProperties - filter properties for each column which enable complex filtering
 * @param preferences - table preference settings
 * @param setPreferences - set function for modification of preferences
 * @param tableName - name of the table
 * @return <ComplexFilterTable
 *              itemList={itemList}
 *              columnDefinitions={columnDefinitions}
 *              visibleContentOptions={visibleContentOptions}
 *              fetchingData={fetchingData}
 *              pageSizeOptions={pageSizeOptions}
 *              filterProperties={filterProperties}
 *              preferences={preferences}
 *              setPreferences={setPreferences}
 *         />
 */
const ComplexFilterTable = ({
  itemList,
  columnDefinitions,
  visibleContentOptions,
  fetchingData,
  pageSizeOptions,
  filterProperties,
  preferences,
  setPreferences,
  tableName,
}: {
  itemList: any[];
  columnDefinitions: TableProps.ColumnDefinition<any>[];
  visibleContentOptions: CollectionPreferencesProps.VisibleContentOptionsGroup[];
  fetchingData: boolean;
  pageSizeOptions: CollectionPreferencesProps.PageSizeOption[];
  filterProperties: any;
  preferences: any;
  setPreferences: any;
  tableName: any;
}) => {
  const {
    items,
    filteredItemsCount,
    collectionProps,
    paginationProps,
    propertyFilterProps,
  } = useCollection(itemList, {
    propertyFiltering: {
      filteringProperties: filterProperties,
    },
    sorting: {},
    pagination: { pageSize: preferences.pageSize },
  });

  return (
    <Table
      {...collectionProps}
      header={<Header counter={`(${itemList.length})`}>{tableName}</Header>}
      columnDefinitions={columnDefinitions}
      visibleColumns={preferences.visibleContent}
      items={items}
      loading={fetchingData}
      loadingText="Fetching Table Data"
      filter={
        <PropertyFilter
          {...propertyFilterProps}
          i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
          countText={
            filteredItemsCount !== itemList.length
              ? `(${filteredItemsCount} / ${itemList.length})`
              : `(${itemList.length})`
          }
          expandToViewport={true}
        />
      }
      pagination={<Pagination {...paginationProps} />}
      preferences={
        <CollectionPreferences
          title={tableName}
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
          pageSizePreference={{
            title: "Page Size",
            options: pageSizeOptions,
          }}
          visibleContentPreference={{
            title: "Select Visible Columns",
            options: visibleContentOptions,
          }}
        />
      }
    />
  );
};

export default ComplexFilterTable;
