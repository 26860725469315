import { FilteringProperty } from "@amzn/awsui-components-react/polaris/property-filter/interfaces";

import CopyTextField from "../../components/offline-maps/CopyTextField";

import { BuildVersionData } from "./offlineDashboardConfig";
import {
    columnDefinitionToOption,
    configPropsToColumnDefinitions,
    configPropsToFilterProps,
    dateComparison,
    NUMBER_FIELD_OPERATORS,
    TableContentConfigProps,
    TEXT_FIELD_OPERATORS,
} from "../tableConfig";
import EpochDateField from "../../components/offline-maps/EpochDateField";
import {
    formatDate,
    TableDateFilterForm,
} from "../../components/offline-maps/TableDateFilterForm";

const BUILDVERSION_TABLE_CONTENT_CONFIG: TableContentConfigProps<BuildVersionData>[] =
    [
        {
            id: "versionStatus",
            description: "Version Status",
            tableCell: (buildversions: BuildVersionData) => (
                <CopyTextField content={buildversions.versionStatus} />
            ),
            sortingField: "versionStatus",
            filterGroup: "buildversionTableProp",
            filterOperators: TEXT_FIELD_OPERATORS,
        },
        {
            id: "offlineRegionsListBuildVersion",
            description: "BuildVersion",
            tableCell: (buildversions: BuildVersionData) => (
                <CopyTextField content={buildversions.offlineRegionsListBuildVersion} />
            ),
            sortingField: "offlineRegionsListBuildVersion",
            filterGroup: "buildversionTableProp",
            filterOperators: TEXT_FIELD_OPERATORS,
        },
        {
            id: "modifiedTimestampUTC",
            description: "Modified Timestamp",
            tableCell: (buildversions: BuildVersionData) => (
                <EpochDateField epochTime={buildversions.modifiedTimestampUTC} />
            ),
            sortingField: "modifiedTimestampUTC",
            filterGroup: "buildversionTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS.map((operator) => ({
                operator,
                form: TableDateFilterForm,
                format: formatDate,
                match: (itemValue: any, tokenValue: any) =>
                    dateComparison(itemValue, tokenValue, operator),
            })),
        },
    ];

export const BUILDVERSION_TABLE_COLUMN_DEFINITIONS =
    configPropsToColumnDefinitions(BUILDVERSION_TABLE_CONTENT_CONFIG);

export const BUILDVERSION_TABLE_VISIBLE_CONTENT_OPTIONS: any = [
    {
        label: "Buildversion Info",
        options: columnDefinitionToOption(BUILDVERSION_TABLE_COLUMN_DEFINITIONS),
    },
];

export const BUILDVERSION_TABLE_FILTER_PROPERTIES: FilteringProperty[] =
    configPropsToFilterProps(BUILDVERSION_TABLE_CONTENT_CONFIG).sort((a, b) =>
        a.propertyLabel.localeCompare(b.propertyLabel)
    );
