import {
  Box,
  Button,
  Popover,
  SpaceBetween,
  StatusIndicator,
} from "@amzn/awsui-components-react";
import { useState } from "react";

/**
 * Text field which handles copying data to the clipboard and displaying a confirmation.
 *
 * @param content - visual content that is able to be copied through the component
 * @param description? - label for the text field prefixed with a colon
 * @param altCopyText? - alternate text to copied in the case of the visual display being different from what is desired to copy into the clipboard
 * @return <CopyTextField content={content} description={description} altCopyText={altCopyText}/>
 */
const CopyTextField = ({
  content,
  description,
  altCopyText,
}: {
  content: any;
  description?: string;
  altCopyText?: string;
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const nonEmptyContent = (content: any) => {
    if (content === null || content === undefined || content === '') return false
    return true;
  }
  return (
    <div
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <SpaceBetween size="xxs" direction="horizontal">
        {description && <Box variant="awsui-key-label">{description}:</Box>}
        <Box>{content}</Box>
        {(isHovering && nonEmptyContent(content)) && (
          <Popover
            dismissButton={false}
            position="top"
            size="small"
            triggerType="custom"
            content={
              <StatusIndicator type="success">Copied attribute</StatusIndicator>
            }
          >
            <Button
              iconName="copy"
              variant="inline-icon"
              onClick={() =>
                navigator.clipboard.writeText(altCopyText ? altCopyText : content)
              }
            />
          </Popover>
        )}
      </SpaceBetween>


    </div>
  );
};

export default CopyTextField;
