
export const MapEditGeometryTypes = {
    POLYGON: 'draw_polygon',
    LINE: 'draw_line_string',
    POINT: 'draw_point',
    BBOX: 'draw_rectangle'
};


export interface MapEditActiveSession {
    name: null | string,
    geometryType: null | string;
}

export interface MapEditSessionStoreState {
    activeSession: MapEditActiveSession,
    setActiveSession: (settings: MapEditActiveSession) => void,
    clearActiveSession: () => void,
    geometry: { session: string | null, data: any | null },
    setGeometry: (geom: any) => void
};