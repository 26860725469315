import {PreferencesType} from "./tileValidationConfig";
import {defaultVisibleContentOptionsFromColumns} from "../tableConfig";
import {AGGREGATEDTILEMETRICS_TABLE_COLUMN_DEFINITIONS} from "./aggregatedTileMetricsTableConfig";
import {VIOLATEDTILEMETRICS_TABLE_COLUMN_DEFINITIONS} from "./violatedTileMetricsTableConfig";
import {CHANGETILEMETRICS_TABLE_COLUMN_DEFINITIONS} from "./changeTileMetricsTableConfig";
import {
    TILESIZEMETRICS_TABLE_1_COLUMN_DEFINITIONS,
    TILESIZEMETRICS_TABLE_2_COLUMN_DEFINITIONS,
    TILESIZEMETRICS_TABLE_3_COLUMN_DEFINITIONS
} from "./tileSizeMetricsTableConfig";

const DEFAULT_AGGREGATEDTILESMETRICS_PREFERENCES_KEY = "DEFAULT_AGGREGATEDTILESMETRICS_PREFERENCES";
const DEFAULT_VIOLATEDTILESMETRICS_PREFERENCES_KEY = "DEFAULT_VIOLATEDTILESMETRICS_PREFERENCES";
const DEFAULT_CHANGETILESMETRICS_PREFERENCES_KEY = "DEFAULT_CHANGETILESMETRICS_PREFERENCES";
const DEFAULT_TILESIZEMETRICS_PREFERENCES_KEY_1 = "DEFAULT_TILESIZEMETRICS_PREFERENCES_1";
const DEFAULT_TILESIZEMETRICS_PREFERENCES_KEY_2 = "DEFAULT_TILESIZEMETRICS_PREFERENCES_2";
const DEFAULT_TILESIZEMETRICS_PREFERENCES_KEY_3 = "DEFAULT_TILESIZEMETRICS_PREFERENCES_3";
/**
 * Retrieves table preferences from local storage, defaulting to DEFAULT_PREFERENCES
 * if not present
 *
 * @returns most recent table preferences
 */
export const getDefaultPreferences = (type: PreferencesType, number?: number): any => {
    switch (type) {
        case PreferencesType.AggregatedTilesMetrics:
            const aggregatedPreferences = localStorage.getItem(
                DEFAULT_AGGREGATEDTILESMETRICS_PREFERENCES_KEY
            );
            return aggregatedPreferences
                ? JSON.parse(aggregatedPreferences)
                : {
                    pageSize: 30,
                    visibleContent: defaultVisibleContentOptionsFromColumns(
                        AGGREGATEDTILEMETRICS_TABLE_COLUMN_DEFINITIONS
                    ),
                };
        case PreferencesType.ViolatedTilesMetrics:
            const violatedPreferences = localStorage.getItem(
                DEFAULT_VIOLATEDTILESMETRICS_PREFERENCES_KEY
            );
            return violatedPreferences
                ? JSON.parse(violatedPreferences)
                : {
                    pageSize: 30,
                    visibleContent: defaultVisibleContentOptionsFromColumns(
                        VIOLATEDTILEMETRICS_TABLE_COLUMN_DEFINITIONS
                    ),
                };
        case PreferencesType.ChangeTilesMetrics:
            const changePreferences = localStorage.getItem(
                DEFAULT_CHANGETILESMETRICS_PREFERENCES_KEY
            );
            return changePreferences
                ? JSON.parse(changePreferences)
                : {
                    pageSize: 10,
                    visibleContent: defaultVisibleContentOptionsFromColumns(
                        CHANGETILEMETRICS_TABLE_COLUMN_DEFINITIONS
                    ),
                };
        case PreferencesType.TileSizeMetrics:
            switch (number) {
                case 1:
                    const sizePreferences1 = localStorage.getItem(
                        DEFAULT_TILESIZEMETRICS_PREFERENCES_KEY_1
                    );
                    return sizePreferences1
                        ? JSON.parse(sizePreferences1)
                        : {
                            pageSize: 10,
                            visibleContent: defaultVisibleContentOptionsFromColumns(
                                TILESIZEMETRICS_TABLE_1_COLUMN_DEFINITIONS
                            ),
                        };
                case 2:
                    const sizePreferences2 = localStorage.getItem(
                        DEFAULT_TILESIZEMETRICS_PREFERENCES_KEY_2
                    );
                    return sizePreferences2
                        ? JSON.parse(sizePreferences2)
                        : {
                            pageSize: 10,
                            visibleContent: defaultVisibleContentOptionsFromColumns(
                                TILESIZEMETRICS_TABLE_2_COLUMN_DEFINITIONS
                            ),
                        };
                case 3:
                    const sizePreferences3 = localStorage.getItem(
                        DEFAULT_TILESIZEMETRICS_PREFERENCES_KEY_3
                    );
                    return sizePreferences3
                        ? JSON.parse(sizePreferences3)
                        : {
                            pageSize: 10,
                            visibleContent: defaultVisibleContentOptionsFromColumns(
                                TILESIZEMETRICS_TABLE_3_COLUMN_DEFINITIONS
                            ),
                        };
            }
    }
};
/**
 * Saves the last set of table preferences used.
 *
 * @param preferences set of preferences to save
 */
export const saveDefaultPreferences = (
    preferences: any,
    type: PreferencesType,
    number?: number
) => {
    switch (type) {
        case PreferencesType.AggregatedTilesMetrics:
            localStorage.setItem(
                DEFAULT_AGGREGATEDTILESMETRICS_PREFERENCES_KEY,
                JSON.stringify(preferences)
            );
            break;

        case PreferencesType.ViolatedTilesMetrics:
            localStorage.setItem(
                DEFAULT_VIOLATEDTILESMETRICS_PREFERENCES_KEY,
                JSON.stringify(preferences)
            );
            break;
        case PreferencesType.ChangeTilesMetrics:
            localStorage.setItem(
                DEFAULT_CHANGETILESMETRICS_PREFERENCES_KEY,
                JSON.stringify(preferences)
            );
            break;
        case PreferencesType.TileSizeMetrics:
            switch (number) {
                case 1:
                    localStorage.setItem(
                        DEFAULT_TILESIZEMETRICS_PREFERENCES_KEY_1,
                        JSON.stringify(preferences)
                    );
                    break;
                case 2:
                    localStorage.setItem(
                        DEFAULT_TILESIZEMETRICS_PREFERENCES_KEY_2,
                        JSON.stringify(preferences)
                    );
                    break;
                case 3:
                    localStorage.setItem(
                        DEFAULT_TILESIZEMETRICS_PREFERENCES_KEY_3,
                        JSON.stringify(preferences)
                    );
                    break;
            }
            break;
    }
};