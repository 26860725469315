import { OptionDefinition, OptionGroup } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { getUser } from './apiConfig';

const configData = (window as any).Configs;
const axios = require('axios');


function getToken() {
    const user = getUser();
    const token = user?.access_token;
    return token;
}


/* The next 2 methods fetch stylesheets from the locally running MapsTileGenToolsAPI */
export const fetchStylesheet = async (url: string, version: string, env: string) => {
    try {

        const config = {
            headers: { 'Authorization': `Bearer ${getToken()}` }
        };
        const res = await axios.get(`${configData.MAPS_TILEGEN_TOOLS_API_URL}/api/v1/online/basemaps/stylesheet?url=${url}&version=${version}&stage=${env}`, config);
        let data = res.data;
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const updateTileUrl = async (tileUrl: string, stylesheetJson: string) => {
    const styleBody = JSON.parse(JSON.stringify(stylesheetJson));
    // fetch the tile json
    for (let source in styleBody.sources) {
      // find the tilejson with a url property or if it's type vector
      if (styleBody.sources[source].url !== undefined && styleBody.sources[source].type === 'vector') {
        // fetch the tile json url
        const tileJson = styleBody.sources[source].url;
        const tileResponse = await (fetch(tileJson));
        const tileBody = await tileResponse.json();

        // switchout the tile url and add the tile json as a source in the stylesheet json
        delete styleBody.sources[source].url;

        styleBody.sources[source] = { type: 'vector', ...tileBody };
      }

      const sourcesToUpdate = ['composite', 'AmznVector'];
      if (tileUrl !== null && sourcesToUpdate.indexOf(source) !== -1) {
        styleBody.sources[source].tiles = [tileUrl];
      }
    }
    return styleBody;
  }

export const fetchResourceFromUrl = async (url: string) => {
    try {
        const res = await axios.get(`${url}`);
        let data = res.data;
        return data;
    } catch (error) {
        console.error(error);
    }
}

// remove placeholder versions like 1
const removePlaceholderVersions = (data: any) => {
    // console.log('removing placeholder versions');
    const stylesheets = data.styleSheets;
    for (var key in stylesheets) {
        if (stylesheets.hasOwnProperty(key)) {

            for (var i = 0; i < stylesheets[key].length; i++) {
                if (stylesheets[key][i]['version'] === '1') {
                    stylesheets[key].splice(i, 1);
                    i--;
                }
            }

            if (stylesheets[key].length === 0)
                delete stylesheets[key];
        }
    }


    return data;
}


export const fetchStylesheets = async (env: any) => {
    try {
        const config = {
            headers: { Authorization: `Bearer ${getToken()}` }
        };
        const res = await axios.get(`${configData.MAPS_TILEGEN_TOOLS_API_URL}/api/v1/online/basemaps/stylesheets?stage=${env}`, config)
        let data = removePlaceholderVersions(res.data);
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const formatLiveBasemapData = (data: any) => {
    const body = data.styleSheets;
    let formatted: any = {};
    let dropdownItems: any[] = [];
    for (const country in body) {
        let dropdownOptions = [];
        for (const index in body[country]) {
            let stylesheet = body[country][index];
            let style_id = stylesheet.id;
            if (formatted[style_id]) {
                formatted[style_id].versions.push({ id: stylesheet.version, status: stylesheet.status });
            } else {
                formatted[style_id] = {
                    maxZoom: stylesheet.maxZoom,
                    country: stylesheet.country,
                    name: stylesheet.name,
                    url: stylesheet.url,
                    provider: stylesheet.provider,
                    variant: stylesheet.variant,
                    versions: [{ id: stylesheet.version, status: stylesheet.status }]
                }
                dropdownOptions.push({ value: style_id, label: `${stylesheet.name} (${style_id})` })
            }
        }
        dropdownItems.push({ label: country, options: dropdownOptions })
    }
    const stylesheetsByCountry: OptionGroup[] = dropdownItems.sort((a: any, b: any): any => a.id > b.id);
    return { formatted: formatted, dropdown: stylesheetsByCountry };
}

export const getStylesheetUrl = (id: any, basemaps: any) => {
    return basemaps[id].url
}
export const getVersionsForDropdown = (id: any, basemaps: any) => {
    let versions = basemaps[id].versions;
    let versionsByStatus: any = {};
    versions.forEach((version: any) => {
        if (versionsByStatus[version.status]) {
            versionsByStatus[version.status].unshift({ value: version.id, label: version.id });
        } else {
            versionsByStatus[version.status] = [{ value: version.id, label: version.id }];
        }
    });
    let options: any = [];
    ['ACTIVE', 'EXPERIMENTAL', 'DEPRECATED'].forEach((status) => {
        if (versionsByStatus[status]) {
            options.push({ label: status, options: versionsByStatus[status] });
        }
    })
    return options;
}

export const getTileJson = async (url: string) => {
    try {
        const res = await axios.get(url)
        let data = res.data;
        return data;
    } catch (error) {
        console.error(error);
    }
}