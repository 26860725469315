import { create } from 'zustand';
import { MapEditActiveSession, MapEditSessionStoreState } from './models/MapEditSessionStore';



export const useMapEditSessionStore = create<MapEditSessionStoreState>((set, get) => ({
    // geojson settings
    activeSession: { name: null, geometryType: null },
    setActiveSession: (settings: MapEditActiveSession) => {
        if (settings.name === null) {
            console.log('invalid session name');
        }
        set({ activeSession: { ...get().activeSession, name: settings.name, geometryType: settings.geometryType } });
    },

    clearActiveSession: () => {
        set({ activeSession: { ...get().activeSession, name: null, geometryType: null } });
    },

    geometry: { session: null, data: null },
    setGeometry: (geom: any) => {
        const sessionName = get().activeSession.name;
        if (geom && sessionName) {
            set({ geometry: { ...get().geometry, session: sessionName, data: geom } });
        }
        // clear the tracing once editing is completed
        get().clearActiveSession();
    }
}));