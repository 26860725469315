import { ButtonDropdownProps } from "@amzn/awsui-components-react";
import { defaultVisibleContentOptionsFromColumns } from "../tableConfig";
import { PreferencesType, REALM, RegionData } from "./offlineDashboardConfig";
import { REGION_TABLE_COLUMN_DEFINITIONS } from "./regionTableConfig";
import { STYLESHEET_TABLE_COLUMN_DEFINITIONS } from "./stylesheetTableConfig";
import { BUILDVERSION_TABLE_COLUMN_DEFINITIONS } from "./buildversionTableConfig";

const DEFAULT_REALM_KEY = "DEFAULT_REALM";
const DEFAULT_REGION_PREFERENCES_KEY = "DEFAULT_REGION_PREFERENCES";
const DEFAULT_STYLESHEET_PREFERENCES_KEY = "DEFAULT_STYLESHEET_PREFERENCES";
const DEFAULT_BUILDVERSION_PREFERENCES_KEY= "DEFAULT_BUILDVERSION_PREFERENCES";
const DEFAULT_REALM = "NA";

//local storage preferences
/**
 * Retrieves the last realm used from local storage, defaulting to DEFAULT_REALM
 * if not present.
 *
 * @returns most recent active realm
 */
export const getDefaultRealm = (): REALM => {
  const defaultRealm = localStorage.getItem(DEFAULT_REALM_KEY);
  const realm = defaultRealm ? defaultRealm : DEFAULT_REALM;
  return REALM[realm as keyof typeof REALM];
};

/**
 * Saves the last realm used to local storage.
 *
 * @param realm last realm used eg. 'NA'
 */
export const saveDefaultRealm = (realm: REALM) => {
  localStorage.setItem(DEFAULT_REALM_KEY, realm);
};

/**
 * Retrieves table preferences from local storage, defaulting to DEFAULT_PREFERENCES
 * if not present
 *
 * @returns most recent table preferences
 */
export const getDefaultPreferences = (type: PreferencesType): any => {
  switch (type) {
    case PreferencesType.Region:
      const regionPreferences = localStorage.getItem(
        DEFAULT_REGION_PREFERENCES_KEY
      );
      return regionPreferences
        ? JSON.parse(regionPreferences)
        : {
            pageSize: 30,
            visibleContent: defaultVisibleContentOptionsFromColumns(
              REGION_TABLE_COLUMN_DEFINITIONS
            ),
          };

    case PreferencesType.Stylesheet:
      const stylesheetPreferences = localStorage.getItem(
        DEFAULT_STYLESHEET_PREFERENCES_KEY
      );
      return stylesheetPreferences
        ? JSON.parse(stylesheetPreferences)
        : {
            pageSize: 30,
            visibleContent: defaultVisibleContentOptionsFromColumns(
              STYLESHEET_TABLE_COLUMN_DEFINITIONS
            ),
          };
      case PreferencesType.BuildVersion:
          const buildversionPreferences = localStorage.getItem(
              DEFAULT_BUILDVERSION_PREFERENCES_KEY
          );
          return buildversionPreferences
              ? JSON.parse(buildversionPreferences)
              : {
                  pageSize: 10,
                  visibleContent: defaultVisibleContentOptionsFromColumns(
                      BUILDVERSION_TABLE_COLUMN_DEFINITIONS
                  ),
              };
  }
};

/**
 * Saves the last set of table preferences used.
 *
 * @param preferences set of preferences to save
 */
export const saveDefaultPreferences = (
  preferences: any,
  type: PreferencesType
) => {
  switch (type) {
    case PreferencesType.Region:
      localStorage.setItem(
        DEFAULT_REGION_PREFERENCES_KEY,
        JSON.stringify(preferences)
      );
      break;

    case PreferencesType.Stylesheet:
      localStorage.setItem(
        DEFAULT_STYLESHEET_PREFERENCES_KEY,
        JSON.stringify(preferences)
      );
      break;
  }
};

/**
 * Maps the REALM_LIST to a structure usable by a Dropdown component.
 *
 * @returns array of {id, text} for each realm
 */
export const getRealmDropdownOptions =
  (): ButtonDropdownProps.ItemOrGroup[] => {
    return (Object.keys(REALM) as Array<keyof typeof REALM>).map((realm) => {
      return { id: realm, text: realm };
    });
  };

/**
 * Validates pack size values and returns the sum of the available packs.
 *
 * @param region - a region entry which contains pack size information
 * @return total pack size
 */
export function getTotalPackSize(region: RegionData): number {
  let packSize: number = 0;

  const tilePack = region["packSizeModel.tilePackSize"];
  const navPack = region["packSizeModel.navPackSize"];
  const mapPack = region["packSizeModel.mapPackSize"];

  packSize = isNaN(tilePack) ? packSize : packSize + tilePack;
  packSize = isNaN(navPack) ? packSize : packSize + navPack;
  packSize = isNaN(mapPack) ? packSize : packSize + mapPack;

  return packSize;
}
