import Layout from '../layout/Layout';
import { FC, useEffect, useRef } from 'react';
import { Button, ButtonDropdown, Select, SpaceBetween } from '@amzn/awsui-components-react';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import { getRealm } from '../../utils/offlineViewer/apiCall';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';


const MapViewer: FC<any> = () => {
    const mapContainer = useRef<any>(null);
    const map = useRef<any>(null);

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
        });
    });

    const [
        environmentOptions,
        setEnvironmentOptions
    ] = React.useState([{ label: "Online", value: "online" },
    { label: "Local", value: "local" }]);
    const [
        offlinePacksOptions,
        setOfflinePacksOptions
    ] = React.useState([
        { label: "OfflineMap1.mbtiles", value: "offline_map1" },
        { label: "OfflineMap2.mbtiles", value: "offline_map2" },
    ]);
    const [
        styleSheetOptions,
        setStyleSheetOptions
    ] = React.useState([
        { label: "Stylesheet1", value: "stylesheet1" },
        { label: "Stylesheet2", value: "stylesheet2" },
    ]);
    const [
        selectionEnvironment,
        setSelectionEnvironment
    ] = React.useState<OptionDefinition | null>(null);
    const [
        selectionOfflinePacks,
        setSelectionOfflinePacks
    ] = React.useState(null);
    const [
        selectionStylesheet,
        setSelectionStylesheet
    ] = React.useState(null);
    const [
        loadingStatus,
        setLoadingStatus
    ] = React.useState<any>({
        environmentStatus: null, offlinePacksStatus: null, stylesheetStatus: null, loadStylesheetStatus: null
    }
    );
    const setLoading = (key: string, value: null | string) => {
        const status = {
            ...loadingStatus
        }
        status[key] = value;
        setLoadingStatus(status);

    }



    const environmentSelectorChang = (e: any) => {
        setSelectionEnvironment(e.selectedOption);
        const envEnvironment = e.selectedOption.value;
        if (envEnvironment == 'online') {
            setLoading('offlinePacksStatus', 'loading');
            setOfflinePacksOptions([]);
            getRealm('na').then(data => {
                const options = [];
                const regions = data.regions;

                for (const region of regions) {

                    options.push({ label: region.regionName, value: region.regionName })

                }
                setOfflinePacksOptions(options)
                setLoading('offlinePacksStatus', null);

            })
        } else {
            const options = [{ label: "OfflineMap1.mbtiles", value: "offline_map1" },
            { label: "OfflineMap2.mbtiles", value: "offline_map2" }]
            setOfflinePacksOptions(options)
        }

    }
    const offlinePacksSelectorChang = (e: any) => {
        setSelectionOfflinePacks(e.selectedOption);


    }
    const styleSheetSelectorChang = (e: any) => {
        setSelectionStylesheet(e.selectedOption);


    }
    const isLoadStylesheetDisabled = () => {
        if (selectionEnvironment == null) {
            return true;
        }
        if (selectionOfflinePacks == null) {
            return true;
        }
        if (selectionStylesheet == null) {
            return true;
        }
        return false;

    }


    return (
        <Layout>
            <SpaceBetween direction="vertical" size="l">
                <SpaceBetween direction="horizontal" size="xs">
                    <Select
                        selectedOption={selectionEnvironment}
                        onChange={({ detail }) =>
                            environmentSelectorChang(detail)
                        }
                        options={environmentOptions}
                        placeholder="Select Environment"
                        selectedAriaLabel="Selected"
                        loadingText="Loading"
                        statusType={loadingStatus['environmentStatus']}
                    />
                    <Select
                        selectedOption={selectionOfflinePacks}
                        onChange={({ detail }) =>
                            offlinePacksSelectorChang(detail)
                        }
                        options={offlinePacksOptions}
                        placeholder="Select OfflinePacks"
                        selectedAriaLabel="Selected"
                        loadingText="Loading"
                        statusType={loadingStatus['offlinePacksStatus']}
                    />

                    <Select
                        selectedOption={selectionStylesheet}
                        onChange={({ detail }) =>
                            styleSheetSelectorChang(detail)
                        }
                        options={styleSheetOptions}
                        placeholder="Select Stylesheet"
                        selectedAriaLabel="Selected"
                        loadingText="Loading"
                        statusType={loadingStatus['stylesheetStatus']}
                    />


                    <Button variant="primary" disabled={isLoadStylesheetDisabled()}
                        loading={loadingStatus['loadStylesheetStatus']}
                    >
                        Load Stylesheet
                    </Button>
                </SpaceBetween>
                <div ref={mapContainer} className="map-container-offlineMap" />

            </SpaceBetween>

        </Layout>


    )
};

export default MapViewer;