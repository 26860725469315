import { create } from 'zustand';
import { BboxStoreState, BboxTableViewRow } from './models/BboxStore';
import { BBox } from '@turf/helpers';
import BBoxPolygon from '@turf/bbox-polygon';

const getCodeViewData = (jsonData: any) => {
    return JSON.stringify(jsonData, null, '\t');
};

const getGeoJsonFromTableViewData = (jsonData: any) => {
    return JSON.parse(jsonData);
};

const getTableViewData = (data: BBox[]) => {
    const result: BboxTableViewRow[] = [];
    for (let i = 0; i < data.length; i++) {
        const bbox = data[i];
        result.push({
            featureId: i + 1,
            xMin: bbox[0],
            yMin: bbox[1],
            xMax: bbox[0],
            yMax: bbox[1],
        });
    }
    return result;
};

// add feature id as attribute needed for selection
const getMapViewData = (geojsonData: BBox[]) => {
    const result: any = {
        'type': 'FeatureCollection',
        'features': []
    };
    for (let i = 0; i < geojsonData.length; i++) {
        const bbox = geojsonData[i];
        const feature = {
            'geometry': BBoxPolygon(bbox).geometry,
            'properties': {
                'featureId': i + 1
            }
        };
        result.features.push(feature);
    }
    return result;
};

export const useBboxStore = create<BboxStoreState>((set, get) => ({
    // bbox settings
    settings: {
        data: [],
        codeViewData: getCodeViewData([]),
        tableViewData: getTableViewData([]),
        mapViewData: getMapViewData([]),
        selectedFeature: null
    },
    setData: (data: BBox[]) => {
        set(
            {
                settings:
                {
                    ...get().settings,
                    data: data,
                    codeViewData: getCodeViewData(data),
                    tableViewData: getTableViewData(data),
                    mapViewData: getMapViewData(data),
                }
            }
        );
    },
    resetData: () => {
        get().clearSelection();
        get().setData([]);
    },
    setCodeViewData: (data: any) => {
        const geojson = getGeoJsonFromTableViewData(data);
        const tableData = getTableViewData(geojson);
        const mapViewData = getMapViewData(geojson);
        set(
            {
                settings:
                {
                    ...get().settings,
                    data: geojson,
                    codeViewData: data,
                    tableViewData: tableData,
                    mapViewData: mapViewData
                }
            }
        );
    },
    setSelection: (id: any) => {
        set({ settings: { ...get().settings, selectedFeature: id } });
    },
    clearSelection: () => {
        set({ settings: { ...get().settings, selectedFeature: null } });
    },
    deleteSelection: () => {
        const selected = get().settings.selectedFeature;
        if (selected) {
            const selectedIndex = selected - 1;
            get().clearSelection();

            const data = [ ...get().settings.data ];
            if (data.length > selectedIndex) {
                data.splice(selectedIndex, 1);
            }
            get().setData(data);
        }
    },
    addFeature: (bbox: BBox) => {
        const data = [ ...get().settings.data ];
        data.push(bbox);
        get().setData(data);
    }
}));