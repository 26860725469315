import { CollectionPreferencesProps } from "@amzn/awsui-components-react";

export enum PreferencesType {
  Region,
  Stylesheet,
  BuildVersion,
}

export enum REALM {
  "NA" = "NA",
  "EU" = "EU",
  "FE" = "FE",
  "IN" = "IN",
}

export const NUMBER_OF_REALMS = Object.keys(REALM).length;

export const PAGE_SIZE_OPTIONS: CollectionPreferencesProps.PageSizeOption[] = [
  { value: 10, label: "10 Entries" },
  { value: 30, label: "30 Entries" },
  { value: 50, label: "50 Entries" },
];

export const FLATTEN_IGNORE_COLUMNS = ["boundingBox"];

export interface BoundingBox {
  minLat: number;
  minLong: number;
  maxLat: number;
  maxLong: number;
}

export interface StylesheetUrlModel {
  url: string;
  variant: string;
  minZoom: string;
  maxZoom: string;
  country: string;
  locale: string;
  version: string;
  status: string;
  id: string;
  provider: string;
}

export interface RegionData {
  regionId: string;
  releaseId: string;
  regionName: string;
  countryCode: string;
  serviceAreaId: string;
  boundingBox: BoundingBox;
  regionDefinition: string;
  //flattened model keys
  "packSizeModel.mapPackSize": number;
  "packSizeModel.navPackSize": number;
  "packSizeModel.tilePackSize": number;
  "navArtifactModel.buildVersion": string;
  "navArtifactModel.createTimestampEpoch": number;
  "navArtifactModel.expirationTimestampEpoch": number;
  "navArtifactModel.rsmVersion": string;
  "navArtifactModel.workflowId": string;
  "tileArtifactModel.buildVersion": string;
  "tileArtifactModel.createTimestampEpoch": number;
  "tileArtifactModel.expirationTimestampEpoch": number;
  "tileArtifactModel.workflowId": string;
  "tileArtifactModel.styleSheetModels": StylesheetUrlModel[];
}

export interface StyleSheetData {
  country: string;
  created: number;
  id: string;
  maxZoom: number;
  md5: string;
  modified: number;
  name: string;
  status: string;
  stylesheetVersion: string;
  url: string;
  variant: string;
  version: string;
}

export interface WorkflowData {
  value: any;
  lastUpdatedTimestamp: number;
  workflowCreationTimestamp: number;
  version: number;
  workflowId: string;
}

export interface BuildVersionData{
  versionStatus: string;
  offlineRegionsListBuildVersion: string;
  modifiedTimestampUTC: number;
}
export interface RealmData {
  regions: RegionData[];
  stylesheets: StyleSheetData[];
  workflows: WorkflowData[];
  buildversions: BuildVersionData[];
}
