import {
  Box,
  ButtonDropdown,
  ButtonDropdownProps,
  SpaceBetween,
} from "@amzn/awsui-components-react";

import { useOfflineMapsDashboardStore } from "../../store/OfflineMapsDashboardStore";

/**
 * Controller for which realm is considered active to be displayed in the table
 * section of the Offline Maps Dashboard.
 *
 * @param dropdownOptions - list of the realm options to choose from
 * @return <TableRealmControl dropdownOptions={dropdownOptions}/>
 */
const TableRealmControl = ({
  dropdownOptions,
}: {
  dropdownOptions: ButtonDropdownProps.ItemOrGroup[];
}) => {
  const offlineMapsActiveRealm = useOfflineMapsDashboardStore(
    (state) => state.offlineMapsActiveRealm
  );
  const setOfflineMapsActiveRealm = useOfflineMapsDashboardStore(
    (state) => state.setOfflineMapsActiveRealm
  );

  return (
    <SpaceBetween direction="horizontal" size="xs">
      <Box variant="h3">Realm:</Box>
      <ButtonDropdown
        items={dropdownOptions}
        onItemClick={(target) => setOfflineMapsActiveRealm(target)}
      >
        {offlineMapsActiveRealm}
      </ButtonDropdown>
    </SpaceBetween>
  );
};

export default TableRealmControl;
