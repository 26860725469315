import React from "react";
import mapboxgl, { Map, PointLike } from 'mapbox-gl';
import { addMapTools, loadMapConfig, renderPopup, RESIZE_TIMEOUT, updateMapSettings } from "../../common/MapUtils";
import { MapConfigResult, MapLocation, MapStoreState, NavigateByLocationProps } from "../../../store/models/MapStore";
import { canvasWithStore, CanvasWithStoreProps } from "./CanvasStore";
import { MapSettings, SettingsStoreState } from "../../../store/models/SettingsStore";
import { useSettingsStore } from "../../../store/SettingsStore";
import { useMapStore } from "../../../store/MapStore";
import { LOG_SEVERITY } from "../../../store/models/NotificationsStore";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';
import { useMapEditSessionStore } from "../../../store/MapEditSessionStore";
import { MapEditActiveSession, MapEditSessionStoreState } from "../../../store/models/MapEditSessionStore";
import { BboxLayer } from "./layers/BboxLayer";
import { GeoJsonLayer } from "./layers/GeoJsonLayer";


mapboxgl.accessToken = 'pk.eyJ1IjoiZ2VveW9nZXNoIiwiYSI6ImNpbDFvaGFocjNhbmN1Z20zN2V2ajc1ejIifQ.y1AvSabUgTs0OrQnqiDbOw';

export interface MapViewerCanvasProps {
    style: React.CSSProperties
}

interface State {
    lastZoomToLocation: NavigateByLocationProps | null;
    mapConfigResult: MapConfigResult | null;
    mapSettings: MapSettings;
    activeSession: MapEditActiveSession;
}


class MapViewerCanvas extends React.PureComponent<MapViewerCanvasProps & CanvasWithStoreProps, State> {
    private mapContainer: HTMLElement | null | undefined = undefined;
    private map: Map | undefined;
    private mapResizeObserver: ResizeObserver | undefined;
    private resizeTimeout: NodeJS.Timeout | undefined;

    private mapStore = this.props.mapStore;
    private settingsStore = this.props.settingsStore;
    private notificationStore = this.props.notificationStore;
    private splitPanelStore = this.props.splitPanelStore;
    private mapEditSessionStore = this.props.mapEditSessionStore;
    private subscriptions: Array<(() => void)> = [];
    private mapSubscriptions: Array<any[]> = [];
    private mapDraw: any | null = null;

    constructor(props: MapViewerCanvasProps & CanvasWithStoreProps) {
        super(props);
        this.state = {
            lastZoomToLocation: null,
            mapConfigResult: null,
            mapSettings: this.settingsStore.mapSettings,
            activeSession: this.mapEditSessionStore.activeSession
        };
    }

    // tracing logic
    onFeatureCreate(e: any) {
        if (e.features && e.features.length > 0 && e.features[0]) {
            const geom = e.features[0].geometry;
            this.mapDraw?.trash(); // clear current features

            // hack to prevent inspect while doubleclick feature (polyline and polygon) complete
            setTimeout(() => {
                this.mapEditSessionStore.setGeometry(JSON.parse(JSON.stringify(geom)));
            }, 150);

        }
    }





    resizeMap(maps: (Map | undefined)[]) {
        //console.log('resize triggered');
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() => {
            for (const map of maps) {
                // console.log('resizing');
                map?.resize();
            }
        }, RESIZE_TIMEOUT);
    }

    onError(e: any) {
        console.error(e.error);
        this.notificationStore.addNewNotificationMessage(
            {
                title: 'Mapbox Encountered an Error',
                message: `${e.error.message}. Additional errors may be present in the console.`,
                severity: LOG_SEVERITY.WARN
            })
    };

    onFeatureInspect(e: any) {
        if (!this.map) {
            return;
        }
        if (this.state.mapSettings.inspectType === null) {
            return
        }

        // edit session in progress
        if (this.state.activeSession.name !== null) {
            return
        }

        // set a bbox around the pointer
        var selectThreshold = 3;
        var queryBox: [PointLike, PointLike] = [
            [
                e.point.x - selectThreshold,
                e.point.y + selectThreshold
            ], // bottom left (SW)
            [
                e.point.x + selectThreshold,
                e.point.y - selectThreshold
            ] // top right (NE)
        ];

        var features = this.map.queryRenderedFeatures(queryBox);

        if (this.state.mapSettings.inspectType === 'panel') {
            const displayProperties = [
                'type',
                'properties',
                'id',
                'layer',
                'source',
                'sourceLayer',
                'state'
            ];

            const displayFeatures = features.map((feat: any) => {
                const displayFeat: any = {};
                displayProperties.forEach((prop) => {
                    displayFeat[prop] = feat[prop];
                });
                return displayFeat;
            });

            this.splitPanelStore.setSplitPanelContent(displayFeatures, 'Feature Details', 'feature-array', null);
        }
        else if (this.state.mapSettings.inspectType === 'popup') {
            if (features.length) {
                const popupContent = renderPopup(features);
                new mapboxgl.Popup({
                    maxWidth: '350px'
                })
                    .setLngLat(e.lngLat)
                    .setHTML(popupContent)
                    .addTo(this.map);
            }
        }
    };

    onMoveEnd() {
        if (!this.map) return;
        const mapLocation: MapLocation = {
            lon: this.map.getCenter().lng,
            lat: this.map.getCenter().lat,
            zoom: this.map.getZoom(),
            pitch: this.map.getPitch(),
            bearing: this.map.getBearing(),
        };
        this.mapStore.setMapLocation(mapLocation);
    }

    useMapStoreCallback(state: MapStoreState, prevState: MapStoreState) {
        if (this.state.lastZoomToLocation !== state.lastNavigateByLocationProps) {
            this.setState({ ...this.state, ...{ lastZoomToLocation: state.lastNavigateByLocationProps } }, () => {
                if (this.map && state.lastNavigateByLocationProps) {
                    this.map.flyTo({
                        zoom: state.lastNavigateByLocationProps.zoom,
                        center: [state.lastNavigateByLocationProps.longitude, state.lastNavigateByLocationProps.latitude],
                        animate: false
                    });
                }
            });

        }

        if (this.state.mapConfigResult !== state.mapConfig) {

            if (this.map && state.mapConfig) {
                loadMapConfig(this.map, state.mapConfig, this.state.mapConfigResult);
            }
            this.setState({ ...this.state, ...{ mapConfigResult: state.mapConfig } }, ()=> {
                this.map?.fire('moveend');
            });
            

        }
    }


    useMapEditSessionStoreCallback(state: MapEditSessionStoreState, prevState: MapEditSessionStoreState) {
        if (!this.map || !this.mapDraw) return;
        if (JSON.stringify(state.activeSession) !== JSON.stringify(this.state.activeSession)) {
            if (state.activeSession.name !== null) {
                if (state.activeSession.geometryType !== null) {
                    this.mapDraw.changeMode(state.activeSession.geometryType);
                }
            } else {
                // console.log('cancel tracing');
                this.mapDraw.changeMode('simple_select');
            }
            this.setState({ ...this.state, ...{ activeSession: state.activeSession } });
        }
    }

    useSettingsStoreCallback(state: SettingsStoreState, prevState: SettingsStoreState) {
        if (this.map) {
            updateMapSettings(this.map, state.mapSettings);
        }
        this.setState({ ...this.state, ...{ mapSettings: state.mapSettings } });
    }


    componentDidMount() {
        if (!this.mapContainer) return;
        const { lon, lat, zoom, bearing, pitch } = this.mapStore.mapLocation;
        this.map = new mapboxgl.Map({
            container: this.mapContainer,
            style: this.mapStore.mapConfig?.stylesheetJson,
            center: [lon, lat],
            zoom: zoom,
            bearing: bearing,
            pitch: pitch
        });
        const onErrorRef = this.onError.bind(this);
        this.mapSubscriptions.push(['error', onErrorRef]);
        this.map.on('error', onErrorRef);

        updateMapSettings(this.map, this.state.mapSettings);

        addMapTools(this.map);


        // feature inspect logic
        const onFeatureInspectRef = this.onFeatureInspect.bind(this);
        this.mapSubscriptions.push(['click', onFeatureInspectRef]);
        this.map.on('click', onFeatureInspectRef);


        // feature create must be present before feature inspect
        const onFeatureCreateRef = this.onFeatureCreate.bind(this);
        this.mapSubscriptions.push(['draw.create', onFeatureCreateRef]);
        this.map.on('draw.create', onFeatureCreateRef);

        const modes = MapboxDraw.modes;
        modes.draw_rectangle = DrawRectangle;

        this.mapDraw = new MapboxDraw({
            displayControlsDefault: false,
            keybindings: false,
            touchEnabled: false,
            boxSelect: false,
            modes: modes,
            controls:
            {
                point: false,
                line_string: false,
                polygon: false,
                trash: false,
                combine_features: false,
                uncombine_features: false
            }
        });
        this.map.addControl(this.mapDraw, 'top-right');


        const onMoveEndRef = this.onMoveEnd.bind(this);
        this.mapSubscriptions.push(['moveend', onMoveEndRef]);
        this.map.on('moveend', onMoveEndRef);


        this.mapResizeObserver = new ResizeObserver(() => { this.resizeMap([this.map]) });
        this.mapResizeObserver.observe(this.mapContainer);

        this.subscriptions.push(useMapEditSessionStore.subscribe(this.useMapEditSessionStoreCallback.bind(this)));
        this.subscriptions.push(useSettingsStore.subscribe(this.useSettingsStoreCallback.bind(this)));
        this.subscriptions.push(useMapStore.subscribe(this.useMapStoreCallback.bind(this)));
    }


    componentWillUnmount() {
        if (this.map) {
            for (const [event, func] of this.mapSubscriptions) {
                this.map.off(event, func);
            }
        }

        this.map?.remove();
        this.mapResizeObserver?.disconnect();
        clearTimeout(this.resizeTimeout);

        for (const subscription of this.subscriptions) {
            if (subscription !== null) {
                subscription();
            }
        }


    }

    render() {
        return (
            <div ref={(el): void => {
                this.mapContainer = el;
            }} style={this.props.style} >
                <GeoJsonLayer currentMap={this.map} />
                <BboxLayer currentMap={this.map} />
            </div>
        );
    }
}

export default canvasWithStore(MapViewerCanvas);