import { create } from 'zustand';
import { LOG_SEVERITY, NotificationMessage, NotificationsStoreState } from './models/NotificationsStore';

export const useNotificationsStore = create<NotificationsStoreState>((set, get) => ({
    notificationSettings: { messages: [], hasNew: false, hasFilteredSeverity: false },
    addNewNotificationMessage: (message: NotificationMessage) => {
        message.createdOn = new Date();
        let hasFilteredSeverity = get().notificationSettings.hasFilteredSeverity;
        const filteredLevel = LOG_SEVERITY.ERROR;
        if (message.severity >= filteredLevel) {
            hasFilteredSeverity = true;
        }
        set(
            { 
                notificationSettings: 
                { 
                    ...get().notificationSettings, 
                    messages: [...get().notificationSettings.messages, message], 
                    hasNew: true,
                    hasFilteredSeverity: hasFilteredSeverity 
                } 
            });
    },
    readAllNotifications: () => {
        set({ notificationSettings: { ...get().notificationSettings, hasNew: false, hasFilteredSeverity: false } });
    },
    readFilteredNotifications: () => {
        set({ notificationSettings: { ...get().notificationSettings, hasFilteredSeverity: false } });
    },
    getLastFilteredNotification: () => {
        const filteredLevel = LOG_SEVERITY.ERROR;
        const messages = get().notificationSettings.messages.filter(item => item.severity >= filteredLevel);
        if (messages.length === 0) {
            return null;
        }
        return messages[messages.length - 1];
    }
}));