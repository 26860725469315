const tileToCenter = (x: number, y: number, z: number, wgs84 = true) => {
    // Width of world in EPSG:3857
    const worldMercMax = 20037508.3427892
    const worldMercMin = -1 * worldMercMax
    const worldMercSize = worldMercMax - worldMercMin
    // Width in tiles
    const worldTileSize = 2 ** z
    // Tile width in EPSG:3857
    const tileMercSize = worldMercSize / worldTileSize
    // Calculate geographic bounds from tile coordinates
    // XYZ tile coordinates are in 'image space' so origin is
    // top-left, not bottom right
    const env = {
        xmin: worldMercMin + (tileMercSize * x),
        xmax: worldMercMin + (tileMercSize * (x + 1)),
        ymin: worldMercMax - (tileMercSize * (y + 1)),
        ymax: worldMercMax - (tileMercSize * y)
    }

    if (wgs84 === true) {
        env.xmin = xConvertWgs84WebMercator(env.xmin);
        env.ymin = yConvertWgs84WebMercator(env.ymin);
        env.xmax = xConvertWgs84WebMercator(env.xmax);
        env.ymax = yConvertWgs84WebMercator(env.ymax);
    }
    return [(env.xmin + env.xmax) / 2, (env.ymin + env.ymax) / 2]
}

const xConvertWgs84WebMercator = (x: number) => {
    var lon = x * 180 / 20037508.34;
    return lon;
}

const yConvertWgs84WebMercator = (y: number) => {
    var lat = Math.atan(Math.exp(y * Math.PI / 20037508.34)) * 360 / Math.PI - 90;
    return lat
}

const apiRoot = (window as any).Configs.OFFLINE_MAPS.API_ROOT;

const mapViewerURLGenerator = (x: string, y: string, z: string, leftStyleSheetType: string,
                               rightStyleSheetType: string, leftStyleSheetVersion: string,
                               rightStyleSheetVersion: string, leftURL: string,
                               rightURL: string) => {
    const coordinates = tileToCenter(parseInt(x), parseInt(y), parseInt(z));
    const url = "https://mapstilegentools." + apiRoot + "general-map-viewer-slider?loc="
    + coordinates[0] + "|" + coordinates[1] + "|" + z + "|0|0&map-config=select|medas_prod|"
    + leftStyleSheetType + "|" + leftStyleSheetVersion + "|" + leftURL + "&map-config-right=select|medas_prod|"
    + rightStyleSheetType + "|" + rightStyleSheetVersion + "|" + rightURL;
    return url;
}

export default mapViewerURLGenerator;

