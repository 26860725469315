import { create } from 'zustand';
import { GeoJsonStoreState } from './models/GeojsonStore';

const EMPTY_JSON = {
    'type': 'FeatureCollection',
    'features': []
};


const getCodeViewData = (jsonData: any) => {
    return JSON.stringify(jsonData, null, '\t');
};

const getGeoJsonFromTableViewData = (jsonData: any) => {
    return JSON.parse(jsonData);
};

const getTableViewData = (geojsonData: any) => {
    const data = [];
    for (let i = 0; i < geojsonData.features.length; i++) {
        const feature = geojsonData.features[i];
        data.push({
            featureId: i + 1,
            geometryType: feature.geometry.type,
        });
    }
    return data;
};

// add feature id as attribute needed for selection
const getMapViewData = (geojsonData: any) => {
    const result = JSON.parse(JSON.stringify(geojsonData));
    for (let i = 0; i < result.features.length; i++) {
        const feature = result.features[i];
        feature.properties['featureId'] = i + 1;
    }
    return result;
};

export const useGeoJsonStore = create<GeoJsonStoreState>((set, get) => ({
    // geojson settings
    settings: {
        data: EMPTY_JSON,
        codeViewData: getCodeViewData(EMPTY_JSON),
        tableViewData: getTableViewData(EMPTY_JSON),
        mapViewData: getMapViewData(EMPTY_JSON),
        selectedFeature: null
    },
    setData: (data: any) => {
        set(
            {
                settings:
                {
                    ...get().settings,
                    data: data,
                    codeViewData: getCodeViewData(data),
                    tableViewData: getTableViewData(data),
                    mapViewData: getMapViewData(data),
                }
            }
        );
    },
    resetData: () => {
        const EMPTY_JSON = {
            'type': 'FeatureCollection',
            'features': []
        };
        get().clearSelection();
        get().setData(EMPTY_JSON);
    },
    setCodeViewData: (data: any) => {
        const geojson = getGeoJsonFromTableViewData(data);
        const tableData = getTableViewData(geojson);
        const mapViewData = getMapViewData(geojson);
        set(
            {
                settings:
                {
                    ...get().settings,
                    data: geojson,
                    codeViewData: data,
                    tableViewData: tableData,
                    mapViewData: mapViewData
                }
            }
        );
    },
    setSelection: (id: any) => {
        set({ settings: { ...get().settings, selectedFeature: id } });
    },
    clearSelection: () => {
        set({ settings: { ...get().settings, selectedFeature: null } });
    },
    deleteSelection: () => {
        const selected = get().settings.selectedFeature;
        if (selected) {
            const selectedIndex = selected - 1;
            get().clearSelection();

            const data = { ...get().settings.data };
            if (data['features'].length > selectedIndex) {
                data['features'].splice(selectedIndex, 1);
            }
            get().setData(data);
        }
    },
    addFeature: (geom: any) => {
        const data = { ...get().settings.data };
        data['features'].push({
            'type': 'Feature',
            'properties': {},
            'geometry': geom
        });
        get().setData(data);
    }
}));