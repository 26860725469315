export const isValidUrl = (str: string) => {
  try {
    new URL(str);
    return true;
  } catch (err) {
    return false;
  }
}


export const requiredValidation = (str: string) => {
  if (str === null || str === undefined) {
    return false;
  }
  if (!str.trim()){
    return false;
  }
  return true;
}
