import { Button, Container, Form, FormField, Header, Input, Select, SpaceBetween } from "@amzn/awsui-components-react";
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import { useEffect, useState } from "react";
import { useMapStore } from "../../../../store/MapStore";


const ZoomToLocation = () => {
    const setLastNavigateByLocationProps = useMapStore((state) => state.setLastNavigateByLocationProps);
    const items: OptionDefinition[] = Array.from(Array(16).keys()).map(item => ({ label: (item + 1).toString(), value: (item + 1).toString() }));
    const [selectedZ, setSelectedZ] = useState<OptionDefinition>({ label: "7", value: "7" });
    const [x, setX] = useState<string>("-78.0000");
    const [y, setY] = useState<string>("37.0000");
    const [validated, setValidated] = useState<boolean>(false);

    useEffect(() => {
        try {
            
            const z = parseInt(selectedZ.value!.toString());
            const lat = parseFloat(x);
            const long = parseFloat(y);

            // Set zoom to 5 if it is not a valid number
            if (isNaN(z)) {
                throw new Error('Invalid Z');
            }
            // Throw error if lat or long is not valid
            if (isNaN(long) || long < -90 || long > 90 || isNaN(lat) || lat < -180 || lat > 180) {
                throw new Error('Invalid Location - Latitude must be from -180 to 180. Longitude must be from -90 to 90.');
            }
            
            setValidated(true);
            
        } catch (error) {
            setValidated(false);
        }
    }, [x, y, selectedZ])



    const onNavigateClicked = () => {
        const z = parseInt(selectedZ.value!.toString());
        const latitude = parseFloat(x);
        const longitude = parseFloat(y);
        setLastNavigateByLocationProps({
            zoom: z,
            latitude: longitude,
            longitude: latitude
        });
    }

    return (<form onSubmit={e => e.preventDefault()}>
        <Form
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="primary" onClick={onNavigateClicked} disabled={!validated}>Navigate</Button>
                </SpaceBetween>
            }
        >
            <Container
                header={
                    <Header variant="h2">
                        Location
                    </Header>
                }
            >
                <SpaceBetween direction="vertical" size="l">
                    <FormField label="Z">
                        <Select
                            selectedOption={selectedZ}
                            onChange={({ detail }) => {
                                setSelectedZ(detail.selectedOption);
                            }}
                            options={items}
                        />
                    </FormField>
                    <FormField label="Longitude">
                        <Input onChange={({ detail }) => {
                            setX(detail.value);
                        }} value={x} inputMode="numeric" />
                    </FormField>
                    <FormField label="Latitude">
                        <Input onChange={({ detail }) => {
                            setY(detail.value);
                        }} value={y} inputMode="numeric" />
                    </FormField>
                </SpaceBetween>
            </Container>
        </Form>
    </form>)
}

export default ZoomToLocation;