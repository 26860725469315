import { Button, CodeEditor, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React, { FC, useEffect, useState } from 'react';
import { DEFAULT_DEVICE_VIEWS, useDeviceViewStore } from '../../store/DeviceViewStore';


const DeviceViewConfigPanel: FC = () => {
    const deviceViews = useDeviceViewStore((state) => state.deviceViews);
    const updateDeviceViews = useDeviceViewStore((state) => state.updateDeviceViews);
    const [preferences, setPreferences] = React.useState(
        undefined
    );
    const [loading, setLoading] = React.useState(false);

    const [ace, setAce] = useState();

    useEffect(() => {
        setLoading(true);

        import('ace-builds')
            .then((instance: any) =>
                import('ace-builds/webpack-resolver')
                    .then(() => {
                        instance.config.set('useStrictCSP', true);
                        instance.config.set('loadWorkerFromBlob', false);
                        setLoading(false);
                        setAce(instance);
                    })
                    .catch(() => setLoading(false)),
            )
            .catch(() => setLoading(false));
    }, []);


    const onChange = (data: any) => {
        try {
            updateDeviceViews(JSON.parse(data));
        } catch {
            console.info('user entered invalid json');
        }
        
    };


    return (
        <Container header={
            <Header
                actions={
                    <SpaceBetween
                        direction="horizontal"
                        size="xs"
                    >
                        <Button onClick={() => updateDeviceViews(DEFAULT_DEVICE_VIEWS)}>Reset to Default</Button>
                    </SpaceBetween>
                }
            >
            </Header>
        }>
            <CodeEditor
                ace={ace}
                language='json'
                value={JSON.stringify(deviceViews, null, "\t")}
                // Without `onChange` the `onDelayedChange` handler was not colled
                onChange={() => { }}
                onDelayedChange={(event: any) => onChange(event.detail.value)}

                preferences={preferences}
                onPreferencesChange={(e: any) => setPreferences(e.detail)}
                loading={loading}
                i18nStrings={{
                    loadingState: 'Loading code editor',
                    errorState:
                        'There was an error loading the code editor.',
                    errorStateRecovery: 'Retry',
                    editorGroupAriaLabel: 'Code editor',
                    statusBarGroupAriaLabel: 'Status bar',
                    cursorPosition: (row: number, column: number) =>
                        `Ln ${row}, Col ${column}`,
                    errorsTab: 'Errors',
                    warningsTab: 'Warnings',
                    preferencesButtonAriaLabel: 'Preferences',
                    paneCloseButtonAriaLabel: 'Close',
                    preferencesModalHeader: 'Preferences',
                    preferencesModalCancel: 'Cancel',
                    preferencesModalConfirm: 'Confirm',
                    preferencesModalWrapLines: 'Wrap lines',
                    preferencesModalTheme: 'Theme',
                    preferencesModalLightThemes: 'Light themes',
                    preferencesModalDarkThemes: 'Dark themes'
                }}
            />
        </Container>

    );
};



export default DeviceViewConfigPanel;