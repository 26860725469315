import {TextContent} from '@amzn/awsui-components-react/polaris';
import Layout from './layout/Layout';

const NotFound = () => {
    return (
        <Layout>
            <TextContent><p>Not Found</p></TextContent>
        </Layout>
    );
}

export default NotFound;