import {create} from "zustand";
import {getDefaultPreferences, saveDefaultPreferences} from "../utils/tileValidationUtils/tileValidationUtil";
import {PreferencesType, TILESETTYPE} from "../utils/tileValidationUtils/tileValidationConfig";
import {TileValidationStoreState} from "./models/TileValidationStoreState";
import {getMetrics} from "../utils/tileValidationUtils/tileValidationAPICall";

export const useTileValidationStore =
    create<TileValidationStoreState>((set: any, get: any) => ({
        aggregatedTileMetricsTablePreferences: getDefaultPreferences(
            PreferencesType.AggregatedTilesMetrics
        ),
        violatedTileMetricsTablePreferences: getDefaultPreferences(
            PreferencesType.ViolatedTilesMetrics
        ),
        changeTileMetricsTablePreferences: getDefaultPreferences(
            PreferencesType.ChangeTilesMetrics
        ),
        tileSizeMetricsTablePreferences1: getDefaultPreferences(
            PreferencesType.TileSizeMetrics, 1
        ),
        tileSizeMetricsTablePreferences2: getDefaultPreferences(
            PreferencesType.TileSizeMetrics, 2
        ),
        tileSizeMetricsTablePreferences3: getDefaultPreferences(
            PreferencesType.TileSizeMetrics, 3
        ),

        /**
         * Sets parameters in the global validationTable preferences.
         *
         * @param detail the collection of parameters to modify in the existing preferences
         */
        setAggregatedTileMetricsTablePreferences: (detail: any) => {
            set({
                aggregatedTileMetricsTablePreferences: {
                    ...get().aggregatedTileMetricsTablePreferences,
                    ...detail,
                },
            });
            //update preferences
            saveDefaultPreferences(
                get().aggregatedTileMetricsTablePreferences,
                PreferencesType.AggregatedTilesMetrics
            );
        },

        /**
         * Sets parameters in the global validationTable preferences.
         *
         * @param detail the collection of parameters to modify in the existing preferences
         */
        setViolatedTileMetricsTablePreferences: (detail: any) => {
            set({
                violatedTileMetricsTablePreferences: {
                    ...get().violatedTileMetricsTablePreferences,
                    ...detail,
                },
            });
            //update preferences
            saveDefaultPreferences(
                get().violatedTileMetricsTablePreferences,
                PreferencesType.ViolatedTilesMetrics
            );
        },

        /**
         * Sets parameters in the global validationTable preferences.
         *
         * @param detail the collection of parameters to modify in the existing preferences
         */
        setChangeTileMetricsTablePreferences: (detail: any) => {
            set({
                changeTileMetricsTablePreferences: {
                    ...get().changeTileMetricsTablePreferences,
                    ...detail,
                },
            });
            //update preferences
            saveDefaultPreferences(
                get().changeTileMetricsTablePreferences,
                PreferencesType.ChangeTilesMetrics
            );
        },

        /**
         * Sets parameters in the global validationTable preferences.
         *
         * @param detail the collection of parameters to modify in the existing preferences
         */
        setTileSizeMetricsTablePreferences1: (detail: any) => {
            set({
                tileSizeMetricsTablePreferences1: {
                    ...get().tileSizeMetricsTablePreferences1,
                    ...detail,
                },
            });
            //update preferences
            saveDefaultPreferences(
                get().tileSizeMetricsTablePreferences1,
                PreferencesType.TileSizeMetrics,
                1
            );
        },

        setTileSizeMetricsTablePreferences2: (detail: any) => {
            set({
                tileSizeMetricsTablePreferences2: {
                    ...get().tileSizeMetricsTablePreferences2,
                    ...detail,
                },
            });
            //update preferences
            saveDefaultPreferences(
                get().tileSizeMetricsTablePreferences2,
                PreferencesType.TileSizeMetrics,
                2
            );
        },

        setTileSizeMetricsTablePreferences3: (detail: any) => {
            set({
                tileSizeMetricsTablePreferences3: {
                    ...get().tileSizeMetricsTablePreferences3,
                    ...detail,
                },
            });
            //update preferences
            saveDefaultPreferences(
                get().tileSizeMetricsTablePreferences3,
                PreferencesType.TileSizeMetrics,
                3
            );
        },
        aggregatedTileMetrics: [],
        violatedTileMetrics: [],
        changeTileMetrics: [],
        sizeTileMetrics: [],
        sizeZoomTileMetrics: [],
        sizeZoomLayerTileMetrics: [],
        aggregatedHeaders: [],
        violatedHeaders: [],
        changeHeaders: [],
        sizeHeaders: [],
        sizeZoomHeaders: [],
        sizeZoomLayerHeaders: [],
        incorrectAggregatedTileMetrics: false,
        incorrectViolatedTileMetrics: false,
        incorrectChangeTileMetrics: false,
        incorrectSizeTileMetrics: false,
        aggregatedTileSetValidationType: TILESETTYPE.amd,
        aggregatedTileSetValidationVersion: 0,
        violatedTileSetValidationType: TILESETTYPE.amd,
        violatedTileSetValidationVersion: 0,
        changeTileSetValidationType:  TILESETTYPE.amd,
        changeTileSetValidationVersion: 0,
        sizeTileSetValidationType: TILESETTYPE.amd,
        sizeTileSetValidationVersion: 0,
        aggregatedLoaded: false,
        violatedLoaded: false,
        changeLoaded: false,
        sizeLoaded: false,

        /**
         * Sets the tileset type and version in the store
         * @param tileType tileset type
         * @param tileVersion tileset version
         * @param api api type (i.e. aggregated)
         */
        setTileSetTypeAndVersion: (tileType: TILESETTYPE, tileVersion: number, api: string) => {
            switch (api) {
                case "aggregated":
                    set({
                        aggregatedTileSetValidationType: tileType,
                        aggregatedTileSetValidationVersion: tileVersion,
                    });
                    break;
                case "violated":
                    set({
                        violatedTileSetValidationType: tileType,
                        violatedTileSetValidationVersion: tileVersion,
                    });
                    break;
                case "change":
                    set({
                        changeTileSetValidationType: tileType,
                        changeTileSetValidationVersion: tileVersion,
                    });
                    break;
                case "size":
                    set({
                        sizeTileSetValidationType: tileType,
                        sizeTileSetValidationVersion: tileVersion,
                    })
                    break;
            }
        },

        /**
         * Returns tileset type and version stored in the store
         * @param api api type (i.e. aggregated)
         * @returns tileset type and version
         */
        getTileSetTypeAndVersion: (api: string) => {
            switch (api) {
                case "aggregated":
                    return [get().aggregatedTileSetValidationType, get().aggregatedTileSetValidationVersion];
                case "violated":
                    return [get().violatedTileSetValidationType, get().violatedTileSetValidationVersion];
                case "change":
                    return [get().changeTileSetValidationType, get().changeTileSetValidationVersion];
                case "size":
                    return [get().sizeTileSetValidationType, get().sizeTileSetValidationVersion];
                default:
                    console.log("Invalid API");
                    return [];
            }
        },

        /**
         * Returns whether the tile metrics data is incorrect for aggregated data
         * @returns boolean of if data is incorrect
         */
        getIncorrectAggregatedTileMetrics: () => {
            return get().incorrectAggregatedTileMetrics;
        },

        /**
         * Returns whether the tile metrics data is incorrect for violated data
         * @returns boolean of if data is incorrect
         */
        getIncorrectViolatedTileMetrics: () => {
            return get().incorrectViolatedTileMetrics;
        },

        /**
         * Returns whether the tile metrics data is incorrect for change data
         * @returns boolean of if data is incorrect
         */
        getIncorrectChangeTileMetrics: () => {
            return get().incorrectChangeTileMetrics;
        },

        /**
         * Returns whether the tile metrics data is incorrect for size data
         * @returns boolean of if data is incorrect
         */
        getIncorrectSizeTileMetrics: () => {
            return get().incorrectSizeTileMetrics;
        },

        /**
         * Gets the headers of the aggregated tile metrics data (column names)
         * @returns headers of the aggregated tile metrics data
         */
        getAggregatedHeaders: () => {
            return get().aggregatedHeaders;
        },

        /**
         * Gets the headers of the violated tile metrics data (column names)
         * @returns headers of the violated tile metrics data
         */
        getViolatedHeaders: () => {
            return get().violatedHeaders;
        },

        /**
         * Gets the headers of the change tile metrics data (column names)
         * @returns headers of the change tile metrics data
         */
        getChangeHeaders: () => {
            return get().changeHeaders;
        },

        /**
         * Gets the headers of the size tile metrics data (column names)
         * @returns headers of the size tile metrics data
         */
        getSizeHeaders: () => {
            return get().sizeHeaders;
        },

        getSizeZoomHeaders: () => {
            return get().sizeZoomHeaders;
        },

        getSizeZoomLayerHeaders: () => {
            return get().sizeZoomLayerHeaders;
        },

        /**
         * Retrieves if aggregated metrics are done fetching
         */
        isAggregatedLoaded: () => {
            return get().aggregatedLoaded;
        },

        /**
         * Retrieves if violated metrics are done fetching
         */
        isViolatedLoaded: () => {
            return get().violatedLoaded;
        },

        /**
         * Retrieves if change metrics are done fetching
         */
        isChangeLoaded: () => {
            return get().changeLoaded;
        },

        /**
         * Retrieves if size metrics are done fetching
         */
        isSizeLoaded: () => {
            return get().sizeLoaded;
        },

        /**
         * Returns the aggregated tile metrics data
         * @returns aggregated tile metrics data
         */
        getAggregatedTileMetricsData: () => {
            return get().aggregatedTileMetrics;
        },

        /**
         * Returns the violated tile metrics data
         * @returns violated tile metrics data
         */
        getViolatedTileMetricsData: () => {
            return get().violatedTileMetrics;
        },

        /**
         * Returns the change tile metrics data
         * @returns change tile metrics data
         */
        getChangeTileMetricsData: () => {
            return get().changeTileMetrics;
        },

        /**
         * Returns the size tile metrics data
         * @returns size tile metrics data
         */
        getSizeTileMetricsData: () => {
            return get().sizeTileMetrics;
        },

        getSizeZoomTileMetricsData: () => {
            return get().sizeZoomTileMetrics;
        },

        getSizeZoomLayerTileMetricsData: () => {
            return get().sizeZoomLayerTileMetrics;
        },

        /**
         * Sets the aggregated tile metrics data in the store
         * @param aggregatedData aggregated data fetched
         */
        setAggregatedTileMetricsData: (aggregatedData: any) => {
            if (aggregatedData === null) {
                set({
                    aggregatedTileMetrics: [],
                    incorrectAggregatedTileMetrics: true,
                    aggregatedHeaders: []
                });
            } else {
                set({
                    aggregatedTileMetrics: aggregatedData,
                    incorrectAggregatedTileMetrics: false,
                    aggregatedHeaders: Object.keys(aggregatedData[0])
                });
            }
        },

        /**
         * Fetches the aggregated tile metrics data from the API
         */
        fetchAggregatedTileMetricsData: async () => {
            set({aggregatedLoaded: false});

            const requestType = "aggregate";
            getMetrics(get().aggregatedTileSetValidationType, get().aggregatedTileSetValidationVersion, requestType).then((metricsData) => {
                if (metricsData === null) {
                    get().setAggregatedTileMetricsData(null);
                } else {
                    get().setAggregatedTileMetricsData(metricsData);
                }
                set({aggregatedLoaded: true });
            });
        },

        /**
         * Sets the violated tile metrics data in the store
         * @param violatedData violated data fetched
         */
        setViolatedTileMetricsData: (violatedData: any) => {
            if (violatedData === null || violatedData === undefined) {
                set({
                    violatedTileMetrics: [],
                    incorrectViolatedTileMetrics: true,
                    violatedHeaders: []
                });
            } else if (Array.isArray(violatedData) && violatedData.length > 0) {
                set({
                    violatedTileMetrics: violatedData,
                    incorrectViolatedTileMetrics: false,
                    violatedHeaders: Object.keys(violatedData[0])
                });
            } else {
                set({
                    violatedTileMetrics: [],
                    incorrectViolatedTileMetrics: false,
                    violatedHeaders: []
                });
            }
        },

        /**
         * Fetches the violated tile metrics data from the API
         */
        fetchViolatedTileMetricsData: async () => {
            set({violatedLoaded: false});

            const requestType = "violate";
            getMetrics(get().violatedTileSetValidationType, get().violatedTileSetValidationVersion, requestType).then((metricsData) => {
                if (metricsData.length === 0) {
                    get().setViolatedTileMetricsData(null);
                } else {
                    get().setViolatedTileMetricsData(metricsData);
                }
                set({violatedLoaded: true });
            });
        },

        /**
         * Sets the change tile metrics data in the store
         * @param changeData change data fetched
         */
        setChangeTileMetricsData: (changeData: any) => {
            if (changeData === null) {
                set({
                    changeTileMetrics: [],
                    incorrectChangeTileMetrics: true,
                    changeHeaders: []
                });
            } else {
                set({
                    changeTileMetrics: changeData,
                    incorrectChangeTileMetrics: false,
                    changeHeaders: Object.keys(changeData[0])
                });
            }
        },

        /**
         * Fetches the change tile metrics data from the API
         */
        fetchChangeTileMetricsData: async () => {
            set({changeLoaded: false});

            const requestType = "change";
            getMetrics(get().changeTileSetValidationType, get().changeTileSetValidationVersion, requestType).then((metricsData) => {
                if (metricsData.length === 0) {
                    get().setChangeTileMetricsData(null);
                } else {
                    get().setChangeTileMetricsData(metricsData);
                }
                set({changeLoaded: true});
            });
        },

        /**
         * Sets the size tile metrics data in the store
         * @param sizeData size data fetched
         */
        setSizeTileMetricsData: (sizeData: any) => {
            if (sizeData === null) {
                set({
                    sizeTileMetrics: [],
                    sizeZoomTileMetrics: [],
                    sizeZoomLayerTileMetrics: [],
                    incorrectSizeTileMetrics: true,
                    sizeHeaders: [],
                    sizeZoomHeaders: [],
                    sizeZoomLayerHeaders: []
                });
            } else {
                set({
                    sizeTileMetrics: sizeData.tileset_agg,
                    sizeZoomTileMetrics: sizeData.tileset_agg_z,
                    sizeZoomLayerTileMetrics: sizeData.tileset_agg_z_layer,
                    incorrectSizeTileMetrics: false,
                    sizeHeaders: Object.keys(sizeData.tileset_agg[0]),
                    sizeZoomHeaders: Object.keys(sizeData.tileset_agg_z[0])
                        .sort((a, b) => {
                            if (a === "z") return -1;
                            if (b === "z") return 1;
                            else return 0;
                        }),
                    sizeZoomLayerHeaders: Object.keys(sizeData.tileset_agg_z_layer[0])
                        .sort((a, b) => {
                            if (a === "z") return -1;
                            if (b === "z") return 1;
                            else return 0;
                        }),
                });
            }
        },

        /**
         * Fetches the size tile metrics data from the API
         */
        fetchSizeTileMetricsData: async () => {
            set({sizeLoaded: false});

            const requestType = "size";
            getMetrics(get().sizeTileSetValidationType, get().sizeTileSetValidationVersion, requestType).then((metricsData) => {
                if (metricsData === null) {
                    get().setSizeTileMetricsData(null);
                } else {
                    get().setSizeTileMetricsData(metricsData);
                }
                set({sizeLoaded: true});
            });
        },
    }));
