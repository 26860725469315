import React, { useEffect } from 'react';
import {
  TopNavigation,
  Modal,
  Box,
  SpaceBetween,
  Button,
  TopNavigationProps
} from '@amzn/awsui-components-react/polaris';


import { AuthContextProps, withAuth } from 'react-oidc-context';
import { Settings } from './settings/Settings';
import { useSplitPanelStore } from '../../store/SplitPanelStore';
import { useNotificationsStore } from '../../store/NotificationsStore';


function NavBar({ user }: AuthContextProps) {
  const [visible, setVisible] = React.useState(false);
  const [utils, setUtils] = React.useState<TopNavigationProps.Utility[]>([]);

  const splitPanelSettings = useSplitPanelStore((state) => state.splitPanelSettings);
  const setSplitPanelContent = useSplitPanelStore((state) => state.setSplitPanelContent);
  const clearSplitPanel = useSplitPanelStore((state) => state.clearSplitPanel);
  const notificationSettings = useNotificationsStore((state) => state.notificationSettings);




  useEffect(() => {
    const toggleSplitPanel = () => {
      if (splitPanelSettings.contentType === 'notifications') {
        clearSplitPanel();
      } else {
        setSplitPanelContent(null, 'Notifications', 'notifications', null);
      }
    }

    const currentUtils: TopNavigationProps.Utility[] = [
      {
        type: "button",
        variant: "link",
        text: "User Guide",
        title: "User Guide",
        href: "https://quip-amazon.com/3fFeAj2MfmGz/MapsTileGen-Tools-User-Guide",
        external: true,
        externalIconAriaLabel: "User Guide",
      },
      {
        type: "button",
        iconName: "notification",
        title: "Notifications",
        ariaLabel: "Notifications (unread)",
        badge: notificationSettings.hasNew,
        disableUtilityCollapse: false,
        onClick: () => {
          toggleSplitPanel();
        }
      },
      {
        type: 'button',
        iconName: 'settings',
        title: 'Settings',
        ariaLabel: 'Settings',
        onClick: () => {
          setVisible(true);
        }
      },
      {
        type: 'menu-dropdown',
        text: user ? `${user.profile.given_name} ${user.profile.family_name}` : 'user not logged in',
        description: user ? user.profile.email : 'user not logged in',
        iconName: 'user-profile',
        items: [
        ]
      }
    ];

    setUtils(currentUtils);
  }, [notificationSettings, user, clearSplitPanel, setSplitPanelContent, splitPanelSettings])



  return (
    <>
      <TopNavigation
        id='h'
        identity={{
          href: '/',
          title: 'MapsDataConduit',
          logo: {
            src: 'MAJIX_logo_wht.png',
            alt: 'MAJIX logo'
          }
        }}
        utilities={utils}
        i18nStrings={{
          searchIconAriaLabel: 'Search',
          searchDismissIconAriaLabel: 'Close search',
          overflowMenuTriggerText: 'More',
          overflowMenuTitleText: 'All',
          overflowMenuBackIconAriaLabel: 'Back',
          overflowMenuDismissIconAriaLabel: 'Close menu'
        }}
      />
      <Modal
        onDismiss={() => setVisible(false)}
        visible={visible}
        closeAriaLabel='Close Settings'
        disableContentPaddings
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button variant='primary' onClick={() => setVisible(false)}>Ok</Button>
            </SpaceBetween>
          </Box>
        }
        header='Settings'
      >
        <Settings />
      </Modal>
    </>

  );
}


export default withAuth(NavBar)