import { Button, Container, Form, FormField, Header, Input, Select, SpaceBetween } from "@amzn/awsui-components-react";
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import { useEffect, useState } from "react";
import { useMapStore } from "../../../../store/MapStore";

const ZoomToTile = () => {
    const setLastNavigateByLocationProps = useMapStore((state) => state.setLastNavigateByLocationProps);
    const items: OptionDefinition[] = Array.from(Array(16).keys()).map(item => ({ label: (item + 1).toString(), value: (item + 1).toString() }));
    const [selectedZ, setSelectedZ] = useState<OptionDefinition>({ label: "5", value: "5" });
    const [x, setX] = useState<string>("9");
    const [y, setY] = useState<string>("12");
    const [validated, setValidated] = useState<boolean>(false);

    useEffect(() => {
        try {
            const zoom = parseInt(selectedZ.value!.toString());
            if (zoom < 0 || parseInt(x) < 0 || parseInt(y) < 0) {
                throw new Error('Invalid');
            }
            const coordinates = tileToCenter(parseInt(x), parseInt(y), zoom);
            let lat = coordinates[1];
            let long = coordinates[0];
            let z = zoom;

            // Set zoom to 5 if it is not a valid number
            if (isNaN(z)) {
                throw new Error('Invalid Z');
            }
            // Throw error if lat or long is not valid
            if (isNaN(lat) || lat < -90 || lat > 90 || isNaN(long) || long < -180 || long > 180) {
                throw new Error('Invalid Location - Latitude must be from -180 to 180. Longitude must be from -90 to 90.');
            }
            setValidated(true);
            
        } catch (error) {
            setValidated(false);
        }
    }, [x, y, selectedZ])

    const tileToCenter = (x: number, y: number, z: number, wgs84 = true) => {
        // Width of world in EPSG:3857
        const worldMercMax = 20037508.3427892
        const worldMercMin = -1 * worldMercMax
        const worldMercSize = worldMercMax - worldMercMin
        // Width in tiles
        const worldTileSize = 2 ** z
        // Tile width in EPSG:3857
        const tileMercSize = worldMercSize / worldTileSize
        // Calculate geographic bounds from tile coordinates
        // XYZ tile coordinates are in 'image space' so origin is
        // top-left, not bottom right
        const env = {
            xmin: worldMercMin + (tileMercSize * x),
            xmax: worldMercMin + (tileMercSize * (x + 1)),
            ymin: worldMercMax - (tileMercSize * (y + 1)),
            ymax: worldMercMax - (tileMercSize * y)
        }

        if (wgs84 === true) {
            env.xmin = xConvertWgs84WebMercator(env.xmin);
            env.ymin = yConvertWgs84WebMercator(env.ymin);
            env.xmax = xConvertWgs84WebMercator(env.xmax);
            env.ymax = yConvertWgs84WebMercator(env.ymax);
        }
        return [(env.xmin + env.xmax) / 2, (env.ymin + env.ymax) / 2]
    }

    const xConvertWgs84WebMercator = (x: number) => {
        var lon = x * 180 / 20037508.34;
        return lon;
    }

    const yConvertWgs84WebMercator = (y: number) => {
        var lat = Math.atan(Math.exp(y * Math.PI / 20037508.34)) * 360 / Math.PI - 90;
        return lat
    }

    //
    // console.log(tileToCenter(10, 11, 7));

    const onNavigateClicked = () => {
        const z = parseInt(selectedZ.value!.toString());
        const coordinates = tileToCenter(parseInt(x), parseInt(y), z);
        setLastNavigateByLocationProps({
            zoom: z,
            latitude: coordinates[1],
            longitude: coordinates[0]
        });
    }

    return (
        <form onSubmit={e => e.preventDefault()}>
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="primary" onClick={onNavigateClicked} disabled={!validated}>Navigate</Button>
                    </SpaceBetween>
                }>
                <Container
                    header={
                        <Header variant="h2">
                            Tile
                        </Header>
                    }
                >
                    <SpaceBetween direction="vertical" size="l">
                        <FormField label="Z">
                            <Select
                                selectedOption={selectedZ}
                                onChange={({ detail }) => {
                                    setSelectedZ(detail.selectedOption);
                                }}
                                options={items}
                            />
                        </FormField>
                        <FormField label="X">
                            <Input onChange={({ detail }) => {
                                setX(detail.value);
                            }} value={x} inputMode="numeric" />
                        </FormField>
                        <FormField label="Y">
                            <Input onChange={({ detail }) => {
                                setY(detail.value);
                            }} value={y} inputMode="numeric" />
                        </FormField>
                    </SpaceBetween>
                </Container>
            </Form>
        </form>
    );
}

export default ZoomToTile;