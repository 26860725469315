import * as React from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";


const MapConfigModal = ({closeMapModal, showMapModal, onSubmit}: {closeMapModal: any, showMapModal: boolean, onSubmit: any}) => {

    const [styleSheetTypeLeft, setStyleSheetTypeLeft] = React.useState("")
    const [styleSheetVersionLeft, setStyleSheetVersionLeft] = React.useState("")
    const [tilesURLLeft, setTilesURLLeft] = React.useState("")
    const [styleSheetTypeRight, setStyleSheetTypeRight] = React.useState("")
    const [styleSheetVersionRight, setStyleSheetVersionRight] = React.useState("")
    const [tilesURLRight, setTilesURLRight] = React.useState("")
    const handleSubmit = () => {
        onSubmit({
            inputStyleSheetTypeLeft: styleSheetTypeLeft,
            inputStyleSheetVersionLeft: styleSheetVersionLeft,
            inputTilesURLLeft: tilesURLLeft,
            inputStyleSheetTypeRight: styleSheetTypeRight,
            inputStyleSheetVersionRight: styleSheetVersionRight,
            inputTilesURLRight: tilesURLRight
        });
        closeMapModal();
    };

    return (
        <Modal
            onDismiss={closeMapModal}
            header="Map Configuration"
            visible={showMapModal}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={closeMapModal}>Cancel</Button>
                        <Button variant="primary" onClick={handleSubmit}>Ok</Button>
                    </SpaceBetween>
                </Box>
            }>
            <p>Optionally specify specific stylesheet type/version/url for left and right panel
                in Map Viewer.</p>
            <p>Inputs left blank will be defaulted to stylesheet type: "amazon-delivery-us",
                stylesheet version: "3.1", and stylesheet URL: "".</p>
            <FormField
                label="Stylesheet Type Left"
                description="i.e. amazon-delivery-us, amazon-delivery-ca, amazon-dark-sa, ..."
            >
                <Input
                    onChange={({detail}) => setStyleSheetTypeLeft(detail.value)}
                    value={styleSheetTypeLeft}
                />
            </FormField>
            <FormField
                label="Stylesheet Version Left"
                description="i.e. 1.16.1, 3.1, 3.5.1, ..."
            >
                <Input
                    onChange={({detail}) => setStyleSheetVersionLeft(detail.value)}
                    value={styleSheetVersionLeft}
                />
            </FormField>
            <FormField
                label="Tiles URL Left"
                description="i.e. https://d3mvcjzzl0gtxr.cloudfront.net/{prefix}/4.0/{z}/{x}/{y}/tile.mvt, ..."
            >
                <Input
                    onChange={({detail}) => setTilesURLLeft(detail.value)}
                    value={tilesURLLeft}
                />
            </FormField>
            <FormField
                label="Stylesheet Type Right"
                description="i.e. amazon-delivery-us, amazon-delivery-ca, amazon-dark-sa, ..."
            >
                <Input
                    onChange={({detail}) => setStyleSheetTypeRight(detail.value)}
                    value={styleSheetTypeRight}
                />
            </FormField>
            <FormField
                label="Stylesheet Version Right"
                description="i.e. 1.16.1, 3.1, 3.5.1, ..."
            >
                <Input
                    onChange={({detail}) => setStyleSheetVersionRight(detail.value)}
                    value={styleSheetVersionRight}
                />
            </FormField>
            <FormField
                label="Tiles URL Right"
                description="i.e. https://d3mvcjzzl0gtxr.cloudfront.net/{prefix}/4.0/{z}/{x}/{y}/tile.mvt, ..."
            >
                <Input
                    onChange={({detail}) => setTilesURLRight(detail.value)}
                    value={tilesURLRight}
                />
            </FormField>
        </Modal>
    )
}

export default MapConfigModal;