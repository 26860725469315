import {
  Button,
  Cards,
  CardsProps,
  Container,
  Header,
  Spinner,
} from "@amzn/awsui-components-react";

import { useOfflineMapsDashboardStore } from "../../store/OfflineMapsDashboardStore";
import {
  NUMBER_OF_REALMS,
  REALM,
  WorkflowData,
} from "../../utils/offlineMapsDashboard/offlineDashboardConfig";
import CopyTextField from "./CopyTextField";
import RealmStatusCardInfo from "./RealmStatusCardInfo";

interface RealmStatusInfo {
  realm: REALM;
  isFetchingData: boolean;
  timestamp: number;
  workflows: WorkflowData[];
}

/**
 * Top level component for the Realm Status section of the Offline Maps Dashboard.
 *
 * @return <RealmStatus/>
 */
const RealmStatus = () => {
  const isFetchingData = useOfflineMapsDashboardStore((state) =>
    state.getAnyFetchingOfflineRealmData()
  );
  const fetchAllOfflineRealmData = useOfflineMapsDashboardStore(
    (state) => state.fetchAllOfflineRealmData
  );
  const fetchOfflineRealmData = useOfflineMapsDashboardStore(
    (state) => state.fetchOfflineRealmData
  );
  const isFetchingOfflineRealmData = useOfflineMapsDashboardStore<any>(
    (state) => state.isFetchingOfflineRealmData
  );
  const fetchedOfflineRealmDataTimestamp = useOfflineMapsDashboardStore<any>(
    (state) => state.fetchedOfflineRealmDataTimestamp
  );
  const getRealmWorkflowData = useOfflineMapsDashboardStore(
    (state) => state.getRealmWorkflowData
  );

  return (
    <Container
      header={
        <Header
          variant="h1"
          actions={
            <Button
              iconAlign="right"
              iconName="refresh"
              disabled={isFetchingData}
              onClick={() => {
                fetchAllOfflineRealmData();
              }}
            >
              Refresh All Regions
            </Button>
          }
        >
          Realm Status
        </Header>
      }
    >
      <Cards
        cardDefinition={{
          header: (realmData: RealmStatusInfo) => {
            return (
              <Header
                actions={
                  <Button
                    iconName="refresh"
                    onClick={() => {
                      fetchOfflineRealmData(realmData.realm);
                    }}
                    disabled={realmData.isFetchingData}
                  />
                }
              >
                {realmData.realm}
              </Header>
            );
          },
          sections: generateCardDefinitions(),
        }}
        cardsPerRow={[
          { cards: NUMBER_OF_REALMS },
          { minWidth: 500, cards: NUMBER_OF_REALMS },
        ]}
        items={generateRealmDetails()}
      />
    </Container>
  );

  /**
   * Handles the generation of card definitions to hide contents when the data is being fetched.
   *
   * @returns <Spinner/> if content is being retrieved, else workflow status content
   */
  function generateCardDefinitions(): CardsProps.SectionDefinition<RealmStatusInfo>[] {
    const cardDefinitions = [];
    //data fetching status section
    cardDefinitions.push({
      content: (realmData: RealmStatusInfo) => {
        const date = new Date(realmData.timestamp);
        const dateTime = date.toLocaleString();
        return realmData.isFetchingData ? (
          <Spinner size="large" />
        ) : (
          <CopyTextField
            description={"Last Fetched Data"}
            content={realmData.timestamp === 0 ? "-" : dateTime}
          />
        );
      },
    });
    //recent workflow section
    cardDefinitions.push({
      content: (realmData: RealmStatusInfo) => {
        return realmData.isFetchingData ? (
          <></>
        ) : (
          <RealmStatusCardInfo workflows={realmData.workflows} />
        );
      },
    });

    return cardDefinitions;
  }

  /**
   * Collects data about each realm for use in the RealmStatus component.
   *
   * @returns RealmStatusInfo array containing data about each realm, including their data fetching status
   */
  function generateRealmDetails(): RealmStatusInfo[] {
    const realmDetails: RealmStatusInfo[] = [];

    for (const realm in REALM) {
      const details: RealmStatusInfo = {
        realm: REALM[realm as keyof typeof REALM],
        isFetchingData: isFetchingOfflineRealmData[realm],
        timestamp: fetchedOfflineRealmDataTimestamp[realm],
        workflows: getRealmWorkflowData(REALM[realm as keyof typeof REALM]),
      };
      realmDetails.push(details);
    }

    return realmDetails;
  }
};

export default RealmStatus;
