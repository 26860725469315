import React from "react";
import mapboxgl, { Map } from 'mapbox-gl';
import { RESIZE_TIMEOUT } from "../../common/MapUtils";
import { useMapStore } from "../../../store/MapStore";
import { MapConfigResult, MapStoreState } from "../../../store/models/MapStore";
import { canvasWithStore, CanvasWithStoreProps } from "../../general/common/CanvasStore";
import { LOG_SEVERITY } from "../../../store/models/NotificationsStore";

interface Props {
    lat: number;
    lon: number;
    zoom: number;
    pitch: number;
    bearing: number;
}

interface State {
    lon: number;
    lat: number;
    zoom: number;
    pitch: number;
    bearing: number;
    mapConfigResult: MapConfigResult | null;
}


class DevicePreviewMap extends React.PureComponent<Props & CanvasWithStoreProps, State> {
    private mapContainer: HTMLElement | null | undefined = undefined;
    private map: Map | undefined;
    private mapResizeObserver: ResizeObserver | undefined;
    private resizeTimeout: NodeJS.Timeout | undefined;
    private subscriptions: Array<(() => void)> = [];

    private mapStore = this.props.mapStore;
    private notificationStore = this.props.notificationStore;

    constructor(props: Props & CanvasWithStoreProps) {
        super(props);
        this.state = {
            lon: props.lon,
            lat: props.lat,
            zoom: props.zoom,
            pitch: props.pitch,
            bearing: props.bearing,
            mapConfigResult: null
        };
    }

    resizeMap(maps: (Map | undefined)[]) {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() => {
            for (const map of maps) {
                map?.resize();
            }
        }, RESIZE_TIMEOUT);
    }

    onError(e: any) {
        console.error(e.error);
        this.notificationStore.addNewNotificationMessage(
            {
                title: 'Mapbox Encountered an Error',
                message: `${e.error.message}. Additional errors may be present in the console.`,
                severity: LOG_SEVERITY.WARN
            })
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.map) {
            if (
                prevProps.lat !== this.props.lat ||
                prevProps.lon !== this.props.lon ||
                prevProps.zoom !== this.props.zoom ||
                prevProps.bearing !== this.props.bearing ||
                prevProps.pitch !== this.props.pitch) {
                this.map.flyTo({
                    center: { lng: this.props.lon, lat: this.props.lat },
                    zoom: this.props.zoom,
                    bearing: this.props.bearing,
                    pitch: this.props.pitch,
                    animate: false
                });
            }

        }
    }

    componentDidMount() {
        if (!this.mapContainer) return;
        const { lon, lat, zoom, bearing, pitch } = this.state;
        this.map = new mapboxgl.Map({
            container: this.mapContainer,
            style: this.mapStore.mapConfig?.stylesheetJson,
            center: [lon, lat],
            zoom: zoom,
            bearing: bearing,
            pitch: pitch
        });
        this.map.on('error', this.onError.bind(this));

        // disable map zoom when using scroll
        this.map.scrollZoom.disable();
        this.map.boxZoom.disable();
        this.map.dragPan.disable();
        this.map.doubleClickZoom.disable();
        this.map.dragRotate.disable();
        this.map.keyboard.disable();
        this.map.touchPitch.disable();
        this.map.touchZoomRotate.disable();

        this.mapResizeObserver = new ResizeObserver(() => { this.resizeMap([this.map]) });
        this.mapResizeObserver.observe(this.mapContainer);


        this.subscriptions.push(useMapStore.subscribe(
            (state: MapStoreState, prevState: MapStoreState) => {
                if (this.state.mapConfigResult !== state.mapConfig) {

                    if (this.map && state.mapConfig) {
                        if (state.mapConfig.enableXrayMode === false && state.mapConfig.stylesheetJson) {
                            if (state.mapConfig.stylesheetJson !== this.state.mapConfigResult?.stylesheetJson) {
                                this.map.setStyle(state.mapConfig.stylesheetJson);
                            }
                        } else if (state.mapConfig.enableXrayMode === true && state.mapConfig.stylesheetXrayJson) {
                            if (state.mapConfig.stylesheetXrayJson !== this.state.mapConfigResult?.stylesheetXrayJson) {
                                this.map.setStyle(state.mapConfig.stylesheetXrayJson);
                            }
                        }

                        if (state.mapConfig.hiddenLayers !== this.state.mapConfigResult?.hiddenLayers) {
                            if (this.map && state.mapConfig && this.map.isStyleLoaded()) {
                                const layers = this.map.getStyle().layers;
                                for (const layer of layers) {
                                    if (state.mapConfig.hiddenLayers.has(layer.id)){
                                        const mapLayer = this.map.getLayer(layer.id)
                                        if (mapLayer) {
                                            (mapLayer as any).setLayoutProperty('visibility', 'none');
                                        }
                                    } else {
                                        const mapLayer = this.map.getLayer(layer.id)
                                        if (mapLayer) {
                                            (mapLayer as any).setLayoutProperty('visibility', 'visible');
                                        }
                                    }
                                }
                                this.map.fire('moveend');
                            }
                        }
                        
                    }
                    this.setState({ ...this.state, ...{ mapConfigResult: state.mapConfig } });

                }
                
            }
        ));
    }


    componentWillUnmount() {
        this.map?.remove();
        this.mapResizeObserver?.disconnect();
        clearTimeout(this.resizeTimeout);
    }

    render() {
        return (
            <div ref={(el): void => {
                this.mapContainer = el;
            }} style={{ display: 'flex', width: '100%', height: '100%' }} />
        );
    }
}

export default canvasWithStore(DevicePreviewMap);
