

import { useEffect } from 'react';
import { Tabs, Popover, StatusIndicator, Button, Box, SpaceBetween } from '@amzn/awsui-components-react/polaris';
import GeoJsonCodeView from './BboxCodeView';
import BboxTableView from './BboxTableView'
import bbox from '@turf/bbox';
import { useBboxStore } from '../../../../store/BboxStore';
import { useMapEditSessionStore } from '../../../../store/MapEditSessionStore';
import { MapEditGeometryTypes, MapEditSessionStoreState } from '../../../../store/models/MapEditSessionStore';

const BboxDisplay = () => {
    const settings = useBboxStore((state) => state.settings);
    const resetData = useBboxStore((state) => state.resetData);
    const addFeature = useBboxStore((state) => state.addFeature);

    const geometry = useMapEditSessionStore((state: MapEditSessionStoreState) => state.geometry);
    const activeSession = useMapEditSessionStore((state: MapEditSessionStoreState) => state.activeSession);
    const setActiveSession = useMapEditSessionStore((state) => state.setActiveSession);
    const clearActiveSession = useMapEditSessionStore((state) => state.clearActiveSession);

    const editSessionName = 'bbox-editor';

    const onClickCopyClipboard = () => {
        navigator.clipboard.writeText(JSON.stringify(settings.data));
    };

    const onPolylineClicked = () => {
        setActiveSession({ name: editSessionName, geometryType: MapEditGeometryTypes.LINE });
    };

    const onPolygonClicked = () => {
        setActiveSession({ name: editSessionName, geometryType: MapEditGeometryTypes.POLYGON });
    };

    const onBBoxClicked = () => {
        setActiveSession({ name: editSessionName, geometryType: MapEditGeometryTypes.BBOX });
    };

    const onCancelTracingClicked = () => {
        clearActiveSession();
    };

    const onClearClicked = () => {
        resetData();
    }


    useEffect(() => {
        if (geometry.session === editSessionName && addFeature) {
            addFeature(bbox(geometry.data));
        }
    }, [geometry, addFeature]);


    const isTracing = () => {
        if (activeSession.name === editSessionName) {
            return true
        }
        return false
    };


    return (
        <SpaceBetween size='xs'>
            <Box float='right'>
                <SpaceBetween direction='horizontal' size='xs'>
                    {!isTracing() && <Button
                        onClick={onBBoxClicked}>
                        BBox
                    </Button>
                    }

                    {!isTracing() && <Button
                        onClick={onPolylineClicked}>
                        Polyline
                    </Button>
                    }
                    {!isTracing() && <Button
                        onClick={onPolygonClicked}>
                        Polygon
                    </Button>
                    }


                    {!isTracing() && <Button
                        onClick={onClearClicked}>
                        Clear
                    </Button>
                    }

                    {isTracing() && <Button
                        onClick={onCancelTracingClicked}>
                        Cancel Tracing
                    </Button>
                    }

                    <Popover
                        size='small'
                        position='top'
                        triggerType='custom'
                        dismissButton={false}
                        content={<StatusIndicator type='success'>copied</StatusIndicator>}
                    >
                        <Button
                            iconName='copy'
                            onClick={onClickCopyClipboard}>
                            Copy
                        </Button>
                    </Popover>
                </SpaceBetween>
            </Box>
            <Tabs
                tabs={[
                    {
                        label: 'JSON',
                        id: 'json',
                        content: <GeoJsonCodeView />
                    },
                    {
                        label: 'Table',
                        id: 'table',
                        content: <BboxTableView />
                    }
                ]}
            />
        </SpaceBetween>
    );
}


export default BboxDisplay;