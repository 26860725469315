import React, { useEffect } from 'react';
import { SideNavbar } from './SideNavbar';
import NavBar from './NavBar';
import SplitPanelDisplay from './SplitPanelDisplay';
import { AppLayout } from '@amzn/awsui-components-react/polaris';
import { useSplitPanelStore } from '../../store/SplitPanelStore';
import { useLocation } from 'react-router-dom';
import { useRouterStore } from '../../store/NavigationStore';


function Layout({ children }: any) {
  const splitPanelSettings = useSplitPanelStore((state) => state.splitPanelSettings);
  const setSplitPanelPreference = useSplitPanelStore((state) => state.setSplitPanelPreference);
  const clearSplitPanel = useSplitPanelStore((state) => state.clearSplitPanel);
  const navigationSettings = useRouterStore((state) => state.navigationSettings);
  const setIsNavigationOpen = useRouterStore((state) => state.setIsNavigationOpen);
  const location = useLocation();

  const setCurrentUrl = useRouterStore((state)=> state.setCurrentUrl);

  const stageName = (window as any).Configs['STAGE'];

  const getStageName = (stage: string) => {
    switch (stage) {
      case 'dev':
        return 'Dev Environment';
      case 'local':
        return 'Local Environment';
      case 'beta':
        return 'Beta Environment';
      case 'gamma':
        return 'Gamma Environment';
      case 'prod':
        return 'Production Environment';
      default:
        return stage;
    }
  };

  useEffect(() => {
    // When user navigates to a new page, clear and close the split panel
    clearSplitPanel();
  }, [clearSplitPanel]);

  const handleSplitPanelToggle = () => {
    clearSplitPanel();
  }

  const handleNavigationChange = (evt: any) => {
    setIsNavigationOpen(!navigationSettings.isNavigationOpen);
  }


  // listern to route url changes
  useEffect(() => {
    if (location.pathname) {
      setCurrentUrl(location.pathname);
    }
  }, [location, location.pathname, setCurrentUrl]);
  
 
  return (
    <>
      {stageName !== 'prod' && <div className='ribbon right'>{getStageName(stageName)}</div>}
      <NavBar />
      <AppLayout
        headerSelector='#h'
        contentType='default'
        content={children}
        splitPanel={
          splitPanelSettings.contentType !== null && <SplitPanelDisplay {...splitPanelSettings} />
        }
        splitPanelOpen={splitPanelSettings.contentType !== null}
        navigation={<SideNavbar />}
        navigationOpen={navigationSettings.isNavigationOpen}
        onSplitPanelToggle={handleSplitPanelToggle}
        splitPanelPreferences={splitPanelSettings.preference}
        onSplitPanelPreferencesChange={({ detail }) => {
          setSplitPanelPreference(detail);
        }}
        onNavigationChange={handleNavigationChange}
      />
    </>
  );
}

export default Layout;