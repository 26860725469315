import { Button, SpaceBetween, Box, Icon, Select } from '@amzn/awsui-components-react/polaris';
import Input from '@amzn/awsui-components-react/polaris/input';
import { useSplitPanelStore } from '../../../store/SplitPanelStore';
import { SplitPanelContentTypes } from '../../../store/models/SplitPanelStore';
import { useDeviceViewStore } from '../common/store/DeviceViewStore';
import { useMapStore } from '../../../store/MapStore';
import ModalController from '../../common/popup/ModalController';


const DevicePreviewControl = () => {
    const setModelControllerProps = useMapStore((state) => state.setModelControllerProps);
    const deviceScale = useDeviceViewStore((state) => state.deviceScale);
    const updateDeviceScale = useDeviceViewStore((state) => state.updateDeviceScale);

    const selectedProfile = useDeviceViewStore((state) => state.selectedProfile);
    const updateSelectedProfile = useDeviceViewStore((state) => state.updateSelectedProfile);

    const deviceViews = useDeviceViewStore((state) => state.deviceViews);


    // first time set the first profile by default
    if (!selectedProfile) {
        if (deviceViews?.data?.length > 0) {
            updateSelectedProfile(deviceViews.data[0].name);
        }
    }


    const splitPanelSettings = useSplitPanelStore((state) => state.splitPanelSettings);
    const setSplitPanelContent = useSplitPanelStore((state) => state.setSplitPanelContent);
    const clearSplitPanel = useSplitPanelStore((state) => state.clearSplitPanel);
    //'smartphone', 'tablet', 'laptop'
    const displaySplitPanel = (title: string, contentType: SplitPanelContentTypes) => {
        if (splitPanelSettings.contentType === contentType) {
            clearSplitPanel();
        } else {
            setSplitPanelContent(null, title, contentType, null);
        }
    }

    return (
        <div className='map-control-content' style={{ marginLeft: '10px' }}>
            <SpaceBetween direction='horizontal' size='xs'>
                <Box variant='p'>
                    Profile:
                </Box>
                <div style={{ minWidth: '200px' }}>
                    <Select
                        selectedOption={{ label: selectedProfile!, value: selectedProfile! }}
                        onChange={({ detail }) =>
                            updateSelectedProfile(detail.selectedOption.value!)
                        }
                        options={deviceViews.data.map(item => ({ label: item.name, value: item.name }))}
                        selectedAriaLabel="Selected"
                    />
                </div>

                <Box variant='p'>
                    Scale:
                </Box>
                <Input
                    onChange={({ detail }) => updateDeviceScale(detail.value)}
                    value={deviceScale}
                    type='number'
                    step={0.1}
                />
                <Button onClick={() => displaySplitPanel('Device View Configuration', 'device-view-config')}><Icon name="view-vertical" variant={splitPanelSettings.contentType === 'device-view-config' ? "link" : "normal"} /> Config</Button>
                <Button onClick={() => {
                    setModelControllerProps({ size: 'large', title: 'Map Configuration', type: 'map-configuration', visibility: true });
                }}> Map Configuration</Button>
                <ModalController />
            </SpaceBetween>
        </div>
    );
}

export default DevicePreviewControl;