import {Header} from "@amzn/awsui-components-react";
import Layout from "../layout/Layout";
import * as React from "react";
import Button from "@amzn/awsui-components-react/polaris/button";
import Input from "@amzn/awsui-components-react/polaris/input";
import Select from "@amzn/awsui-components-react/polaris/select";
import Flashbar from "@amzn/awsui-components-react/polaris/flashbar"
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";
import {
    AGGREGATEDTILEMETRICS_TABLE_COLUMN_DEFINITIONS, AGGREGATEDTILEMETRICS_TABLE_FILTER_PROPERTIES,
    AGGREGATEDTILEMETRICS_TABLE_VISIBLE_CONTENT_OPTIONS
} from "../../utils/tileValidationUtils/aggregatedTileMetricsTableConfig";
import setViolatedTileMetricsURLConfig, {
    VIOLATEDTILEMETRICS_TABLE_COLUMN_DEFINITIONS, VIOLATEDTILEMETRICS_TABLE_FILTER_PROPERTIES,
    VIOLATEDTILEMETRICS_TABLE_VISIBLE_CONTENT_OPTIONS
} from "../../utils/tileValidationUtils/violatedTileMetricsTableConfig";
import setChangeTileMetricsURLConfig, {
    CHANGETILEMETRICS_TABLE_COLUMN_DEFINITIONS, CHANGETILEMETRICS_TABLE_FILTER_PROPERTIES,
    CHANGETILEMETRICS_TABLE_VISIBLE_CONTENT_OPTIONS
} from "../../utils/tileValidationUtils/changeTileMetricsTableConfig";
import {
    TILESIZEMETRICS_TABLE_1_COLUMN_DEFINITIONS,
    TILESIZEMETRICS_TABLE_1_FILTER_PROPERTIES,
    TILESIZEMETRICS_TABLE_1_VISIBLE_CONTENT_OPTIONS,
    TILESIZEMETRICS_TABLE_2_COLUMN_DEFINITIONS,
    TILESIZEMETRICS_TABLE_2_FILTER_PROPERTIES,
    TILESIZEMETRICS_TABLE_2_VISIBLE_CONTENT_OPTIONS,
    TILESIZEMETRICS_TABLE_3_COLUMN_DEFINITIONS,
    TILESIZEMETRICS_TABLE_3_FILTER_PROPERTIES,
    TILESIZEMETRICS_TABLE_3_VISIBLE_CONTENT_OPTIONS
} from "../../utils/tileValidationUtils/tileSizeMetricsTableConfig";
import {PAGE_SIZE_OPTIONS, TILESETTYPE} from "../../utils/tileValidationUtils/tileValidationConfig";
import ComplexFilterTable from "../offline-maps/ComplexFilterTable";
import {useTileValidationStore} from "../../store/TileValidationStore";
import MapConfigModal from "./MapConfigModal";

/**
 * Download data helper function
 * @param data - data to download
 * @param headers - column names
 * @param fileName - name of downloaded file
 */
const downloadData = (data: any[], headers: string[], fileName: string) => {
    const csvRows = [];
    csvRows.push(headers.join(','));
    for (const row of data) {
        const values = headers.map(header => {
            let value = row[header];
            if (header === "validation_metrics") {
                value = "old: " +row[header]["validation_metrics_old"] + " new: " + row[header]["validation_metrics_new"]
            }
            // Handle nested JSON strings
            let escaped = (`${value || ''}`).replace(/, /g, '\\,').replace(/\n/g, '');
            if (header === "validation_metrics" || header === "change_rate_metrics" || header === "change_difference_metrics" || header === "threshold_violation_metrics" || header === "missing_layer_metrics") {
                escaped = escaped.replace(/,/g, '; ');
            }
            return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
    }
    try {
        const csvData = csvRows.join('\n');
        const blob = new Blob([csvData], {type: 'text/csv;charset=utf-8;'});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `${fileName}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.log(error);
    }
}

const TileValidation = () => {
    const setTileSetTypeAndVersion = useTileValidationStore(
        (state) => state.setTileSetTypeAndVersion
    )
    const activeValidationDataFetchStatus = false;

    // API 1
    const fetchAggregatedTileMetricsData = useTileValidationStore(
        (state) => state.fetchAggregatedTileMetricsData
    );
    const isAggregatedTileMetricsDataInvalid = useTileValidationStore(
        (state) => state.getIncorrectAggregatedTileMetrics()
    );
    const aggregatedTileSetTypeAndVersion = useTileValidationStore(
        (state) => state.getTileSetTypeAndVersion("aggregated")
    );
    const activeAggregatedValidationData: any[] = useTileValidationStore(
        (state) => state.getAggregatedTileMetricsData()
    );
    const isAggregatedLoaded: boolean = useTileValidationStore(
        (state) => state.isAggregatedLoaded()
    );

    // API 2
    const fetchViolatedTileMetricsData = useTileValidationStore(
        (state) => state.fetchViolatedTileMetricsData
    );
    const isViolatedTileMetricsDataInvalid = useTileValidationStore(
        (state) => state.getIncorrectViolatedTileMetrics()
    );
    const violatedTileSetTypeAndVersion = useTileValidationStore(
        (state) => state.getTileSetTypeAndVersion("violated")
    );
    const activeViolatedValidationData: any[] = useTileValidationStore(
        (state) => state.getViolatedTileMetricsData()
    );
    const isViolatedLoaded: boolean = useTileValidationStore(
        (state) => state.isViolatedLoaded()
    );

    // API 3
    const fetchChangeTileMetricsData = useTileValidationStore(
        (state) => state.fetchChangeTileMetricsData
    );
    const isChangeTileMetricsDataInvalid = useTileValidationStore(
        (state) => state.getIncorrectChangeTileMetrics()
    );
    const changeTileSetTypeAndVersion = useTileValidationStore(
        (state) => state.getTileSetTypeAndVersion("change")
    );
    const activeChangeValidationData: any[] = useTileValidationStore(
        (state) => state.getChangeTileMetricsData()
    );
    const isChangeLoaded: boolean = useTileValidationStore(
        (state) => state.isChangeLoaded()
    );

    // API 4
    const fetchSizeTileMetricsData = useTileValidationStore(
        (state) => state.fetchSizeTileMetricsData
    );
    const isSizeTileMetricsDataInvalid = useTileValidationStore(
        (state) => state.getIncorrectSizeTileMetrics()
    );
    const sizeTileSetTypeAndVersion = useTileValidationStore(
        (state) => state.getTileSetTypeAndVersion("size")
    );
    const activeSizeValidationData: any[] = useTileValidationStore(
        (state) => state.getSizeTileMetricsData()
    );
    const activeSizeZoomValidationData: any[] = useTileValidationStore(
        (state) => state.getSizeZoomTileMetricsData()
    );
    const activeSizeZoomLayerValidationData: any[] = useTileValidationStore(
        (state) => state.getSizeZoomLayerTileMetricsData()
    );
    const isSizeLoaded: boolean = useTileValidationStore(
        (state) => state.isSizeLoaded()
    );

    // Downloading Parameters
    const aggregatedTableFileName: string = "aggregated_table_metrics";
    const aggregatedTableDownloadHeaders: string[] = useTileValidationStore(
        (state) => state.getAggregatedHeaders()
    );
    const violatedTableFileName: string = "violated_table_metrics";
    const violatedTableDownloadHeaders: string[] = useTileValidationStore(
        (state) => state.getViolatedHeaders()
    );
    const changeTableFileName: string = "change_table_metrics";
    const changeTableDownloadHeaders: string[] = useTileValidationStore(
        (state) => state.getChangeHeaders()
    );
    const sizeTableFileName1: string = "size_table_metrics1";
    const sizeTableDownloadHeaders1: string[] = useTileValidationStore(
        (state) => state.getSizeHeaders()
    );
    const sizeTableFileName2: string = "size_table_metrics2";
    const sizeTableDownloadHeaders2: string[] = useTileValidationStore(
        (state) => state.getSizeZoomHeaders()
    );
    const sizeTableFileName3: string = "size_table_metrics3";
    const sizeTableDownloadHeaders3: string[] = useTileValidationStore(
        (state) => state.getSizeZoomLayerHeaders()
    );

    // Table Preferences
    const aggregatedTileMetricsPreferences = useTileValidationStore(
        (state) => state.aggregatedTileMetricsTablePreferences
    );
    const setAggregatedTileMetricsPreferences = useTileValidationStore(
        (state) => state.setAggregatedTileMetricsTablePreferences
    );
    const violatedTileMetricsPreferences = useTileValidationStore(
        (state) => state.violatedTileMetricsTablePreferences
    );
    const setViolatedTileMetricsPreferences = useTileValidationStore(
        (state) => state.setViolatedTileMetricsTablePreferences
    );
    const changeTileMetricsPreferences = useTileValidationStore(
        (state) => state.changeTileMetricsTablePreferences
    );
    const setChangeTileMetricsPreferences = useTileValidationStore(
        (state) => state.setChangeTileMetricsTablePreferences
    );
    const tileSizeMetricsPreferences1 = useTileValidationStore(
        (state) => state.tileSizeMetricsTablePreferences1
    );
    const setTileSizeMetricsPreferences1 = useTileValidationStore(
        (state) => state.setTileSizeMetricsTablePreferences1
    );
    const tileSizeMetricsPreferences2 = useTileValidationStore(
        (state) => state.tileSizeMetricsTablePreferences2
    );
    const setTileSizeMetricsPreferences2 = useTileValidationStore(
        (state) => state.setTileSizeMetricsTablePreferences2
    );
    const tileSizeMetricsPreferences3 = useTileValidationStore(
        (state) => state.tileSizeMetricsTablePreferences3
    );
    const setTileSizeMetricsPreferences3 = useTileValidationStore(
        (state) => state.setTileSizeMetricsTablePreferences3
    );

    // Button States
    const [tileVersion, setTileVersion] = React.useState("");
    const [selectedType, setSelectedType] = React.useState<{
        label: string; value: string
    }>({label: "Enter tileset type (i.e. amd): ", value: "0"});
    type CustomFlashbarProps = {
        tilesetType: string;
        tilesetVersion: string;
    };
    const CustomFlashbar: React.FC<CustomFlashbarProps> = ({tilesetType, tilesetVersion}) => {
        return <Flashbar items={[
            {
                header: "Input Error",
                type: undefined,
                content: "Tileset input type (" + tilesetType
                    + ") or version (" + tilesetVersion + ") is invalid.",
                dismissible: false,
                dismissLabel: "Dismiss message",
                id: "message_1"
            }
        ]}/>;
    }
    const [showMapModal, setShowMapModal] = React.useState(false);
    const handleMapConfigClick = () => {
        setShowMapModal(true);
    };
    const closeMapModal = () => setShowMapModal(false);

    // Table States
    const [showAggregatedTable, setShowAggregatedTable] = React.useState(false);
    const [showViolatedTable, setShowViolatedTable] = React.useState(false);
    const [showChangeTable, setShowChangeTable] = React.useState(false);
    const [showSizeTable, setShowSizeTable] = React.useState(false);
    const handleAggregatedButtonClick = () => {
        if (!showAggregatedTable && tileVersion && selectedType.value !== "0"
            && !isAggregatedTileMetricsDataInvalid) {
            setShowAggregatedTable(true);
        }
    };
    const handleViolatedButtonClick = () => {
        if (!showViolatedTable && tileVersion && selectedType.value !== "0"
            && !isViolatedTileMetricsDataInvalid) {
            setShowViolatedTable(true);
        }
    };
    const handleChangeButtonClick = () => {
        if (!showChangeTable && tileVersion && selectedType.value !== "0"
            && !isChangeTileMetricsDataInvalid)
            setShowChangeTable(true);
    };
    const handleSizeButtonClick = () => {
        if (!showSizeTable && tileVersion && selectedType.value !== "0"
            && !isSizeTileMetricsDataInvalid)
            setShowSizeTable(true);
    };

    // Stylesheets configs
    const [styleSheetTypeLeft, setStyleSheetTypeLeft] = React.useState("")
    const [styleSheetVersionLeft, setStyleSheetVersionLeft] = React.useState("")
    const [tilesURLLeft, setTilesURLLeft] = React.useState("")
    const [styleSheetTypeRight, setStyleSheetTypeRight] = React.useState("")
    const [styleSheetVersionRight, setStyleSheetVersionRight] = React.useState("")
    const [tilesURLRight, setTilesURLRight] = React.useState("")
    const handleModalSubmit = ({inputStyleSheetTypeLeft, inputStyleSheetVersionLeft, inputTilesURLLeft,
                               inputStyleSheetTypeRight, inputStyleSheetVersionRight, inputTilesURLRight} : {inputStyleSheetTypeLeft: string,
        inputStyleSheetVersionLeft: string, inputTilesURLLeft: string, inputStyleSheetTypeRight: string,
        inputStyleSheetVersionRight: string, inputTilesURLRight: string}) => {
        setStyleSheetTypeLeft(inputStyleSheetTypeLeft);
        setStyleSheetVersionLeft(inputStyleSheetVersionLeft);
        setTilesURLLeft(inputTilesURLLeft);
        setStyleSheetTypeRight(inputStyleSheetTypeRight);
        setStyleSheetVersionRight(inputStyleSheetVersionRight);
        setTilesURLRight(inputTilesURLRight);
        setViolatedTileMetricsURLConfig(inputStyleSheetTypeLeft, inputStyleSheetVersionLeft, inputTilesURLLeft,
        inputStyleSheetTypeRight, inputStyleSheetVersionRight, inputTilesURLRight);
        setChangeTileMetricsURLConfig(inputStyleSheetTypeLeft, inputStyleSheetVersionLeft, inputTilesURLLeft,
        inputStyleSheetTypeRight, inputStyleSheetVersionRight, inputTilesURLRight);
    };

    return (
        <Layout>
            <Header variant="h1">Tile Validation Metrics Tool</Header>
            <br/>
            <Header variant="h3">Input Fields</Header>
            <Select
                selectedOption={selectedType}
                onChange={({detail}) =>
                    setSelectedType(detail.selectedOption as { label: string; value: string })
                }
                options={[
                    {label: "amd", value: "1"},
                    {label: "here", value: "2"},
                    {label: "mmi", value: "3"},
                    {label: "zenrin", value: "4"}
                ]}
            />
            <br/>
            <Input
                onChange={({detail}) => setTileVersion(detail.value)}
                value={tileVersion}
                placeholder="Enter tileset version (i.e. 209): "
            />
            <br/>
            <div>
                <Button
                    onClick={handleMapConfigClick}
                >
                    Map Viewer Configurations (Optional)
                </Button>
                <MapConfigModal closeMapModal={closeMapModal} showMapModal={showMapModal}
                                onSubmit={handleModalSubmit}></MapConfigModal>
            </div>
            <br/>
            <Header variant="h3">Fetch Tables</Header>
            <ExpandableSection headerText="Aggregated Table Column Information">
                Table shows information of all metrics aggregated.
                <br/>
                <b> POI: </b> Points of interests in selected tileset
                <br/>
                <b> Area: </b> Area of each polygon / multipolygon type POI in selected tileset
                <br/>
                <b> Count: </b> Number of POIs in selected tileset
                <br/>
                <b> Count Complex: </b>
                <br/>
                <b> Length: </b> Length of line / multiline type POI in selected tileset
            </ExpandableSection>
            <ExpandableSection headerText="Violated Table Column Information">
                Table shows information of all tiles with violated metrics.
                <br/>
                <b> Tile ID: </b> x (latitude index), y (longitude index), z (zoom level) coordinates for tile
                <br/>
                <b> Tileset Name: </b> Tileset type
                <br/>
                <b> Tileset Mode: </b> Incremental or full refresh
                <br/>
                <b> Validation Metrics: </b> All metrics comparing new vs. old value for selected tileset
                <br/>
                <b> Change Rate metrics: </b> All metrics rate of change for selected tileset
                <br/>
                <b> Change Difference Metrics: </b> All metrics absolute change for selected tileset
                <br/>
                <b> Threshold Violation Metrics: </b> Specific metrics that violated metric thresholds for selected tilset
            </ExpandableSection>
            <ExpandableSection headerText="Change Table Column Information">
                Table shows information of change values for violated metrics.
                <br/>
                <b> Label: </b> Attribute descriptions for POIs, also shown in aggregated tile metrics table
                <br/>
                <b> Type: </b> Point of interests' attributes
                <br/>
                <b> Tile ID: </b> x (latitude), y (longitude), z (zoom level) coordinates for tile
                <br/>
                <b> Old/New Value: </b> Value of point of interests' attributes
                <br/>
                <b> Change Rate: </b> Rate of change of value
                <br/>
                <b> Change Difference: </b> Absolute change of value
            </ExpandableSection>
            <ExpandableSection headerText="Size Table Column Information">
                Table shows information of memory size for different metrics.
                <br/>
                <b> Zoom: </b> Zoom Layer on selected tileset
                <br/>
                <b> Layer Name: </b> Points of interests
                <br/>
                <b> Metric Count: </b> Number of POIs in certain tileset or zoom level or zoom level and layer
                <br/>
                <b> Metric Size Average: </b> Average memory size of point of interest
                <br/>
                <b> Metric Size Max: </b> Maximum memory size of point of interest
                <br/>
                <b> Metric Size Min: </b> Minimum memory size of point of interest
                <br/>
                <b> P50: </b> 50th percentile - expect memory size to exceed amount 50% of time
                <br/>
                <b> P90: </b> 90th percentile - expect memory size to exceed amount 90% of time
                <br/>
                <b> P95: </b> 95th percentile - expect memory size to exceed amount 95% of time
                <br/>
                <b> P99: </b> 99th percentile - expect memory size to exceed amount 99% of time
                <br/>
                <b> P99.5: </b> 99.5th percentile - expect memory size to exceed amount 99.5% of time
                <br/>
                <b> Metric Size Sum: </b> Total size in certain tilset or zoom level or zoom level and layer
            </ExpandableSection>
            <br/>
            {/*Table 1*/}
            <Button variant="primary" onClick={() => {
                handleAggregatedButtonClick();
                setTileSetTypeAndVersion(TILESETTYPE[selectedType.label as keyof typeof TILESETTYPE], parseInt(tileVersion), "aggregated");
                fetchAggregatedTileMetricsData();
            }}>
                Aggregated Tile Metrics
            </Button>
            <br/><br/>
            {isAggregatedTileMetricsDataInvalid && isAggregatedLoaded ? ([<CustomFlashbar
                    tilesetType={TILESETTYPE[aggregatedTileSetTypeAndVersion[0] as keyof typeof TILESETTYPE]}
                    tilesetVersion={aggregatedTileSetTypeAndVersion[1]}
                />, <br/>])
                : (showAggregatedTable && (!isAggregatedLoaded ? ([
                        <Spinner size="large"/>,
                        <br/>, <br/>]
                ) : ([<ComplexFilterTable
                    itemList={activeAggregatedValidationData}
                    columnDefinitions={AGGREGATEDTILEMETRICS_TABLE_COLUMN_DEFINITIONS}
                    visibleContentOptions={AGGREGATEDTILEMETRICS_TABLE_VISIBLE_CONTENT_OPTIONS}
                    fetchingData={activeValidationDataFetchStatus}
                    pageSizeOptions={PAGE_SIZE_OPTIONS}
                    filterProperties={AGGREGATEDTILEMETRICS_TABLE_FILTER_PROPERTIES}
                    preferences={aggregatedTileMetricsPreferences}
                    setPreferences={setAggregatedTileMetricsPreferences}
                    tableName={"Aggregated Tiles Metrics: (" + TILESETTYPE[aggregatedTileSetTypeAndVersion[0] as keyof typeof TILESETTYPE]
                        + " " + aggregatedTileSetTypeAndVersion[1] + ")"}
                />, <br/>, <Button
                    onClick={() => downloadData(activeAggregatedValidationData, aggregatedTableDownloadHeaders, aggregatedTableFileName)}>
                    Download
                </Button>, <br/>, <br/>, <br/>])))}
            {/*Table 2*/}
            <Button variant="primary" onClick={() => {
                handleViolatedButtonClick();
                setTileSetTypeAndVersion(TILESETTYPE[selectedType.label as keyof typeof TILESETTYPE], parseInt(tileVersion), "violated");
                fetchViolatedTileMetricsData();
            }}>
                Violated Tiles Metrics
            </Button>
            <br/> <br/>
            {isViolatedTileMetricsDataInvalid && isViolatedLoaded ? ([<CustomFlashbar
                    tilesetType={TILESETTYPE[violatedTileSetTypeAndVersion[0] as keyof typeof TILESETTYPE]}
                    tilesetVersion={violatedTileSetTypeAndVersion[1]}
                />, <br/>])
                : (showViolatedTable && (!isViolatedLoaded ? ([
                        <Spinner size="large"/>,
                        <br/>, <br/>]
                ) : ([<ComplexFilterTable
                    itemList={activeViolatedValidationData}
                    columnDefinitions={VIOLATEDTILEMETRICS_TABLE_COLUMN_DEFINITIONS}
                    visibleContentOptions={VIOLATEDTILEMETRICS_TABLE_VISIBLE_CONTENT_OPTIONS}
                    fetchingData={activeValidationDataFetchStatus}
                    pageSizeOptions={PAGE_SIZE_OPTIONS}
                    filterProperties={VIOLATEDTILEMETRICS_TABLE_FILTER_PROPERTIES}
                    preferences={violatedTileMetricsPreferences}
                    setPreferences={setViolatedTileMetricsPreferences}
                    tableName={"Violated Tiles Metrics: (" + TILESETTYPE[violatedTileSetTypeAndVersion[0] as keyof typeof TILESETTYPE]
                        + " " + violatedTileSetTypeAndVersion[1] + ")"}
                />, <br/>, <Button
                    onClick={() => downloadData(activeViolatedValidationData, violatedTableDownloadHeaders, violatedTableFileName)}>
                    Download
                </Button>, <br/>, <br/>, <br/>])))}
            {/*Table 3*/}
            <Button variant="primary" onClick={() => {
                handleChangeButtonClick();
                setTileSetTypeAndVersion(TILESETTYPE[selectedType.label as keyof typeof TILESETTYPE], parseInt(tileVersion), "change");
                fetchChangeTileMetricsData();
            }}>
                Change Tiles Metrics
            </Button>
            <br/> <br/>
            {isChangeTileMetricsDataInvalid && isChangeLoaded ? ([<CustomFlashbar
                    tilesetType={TILESETTYPE[changeTileSetTypeAndVersion[0] as keyof typeof TILESETTYPE]}
                    tilesetVersion={changeTileSetTypeAndVersion[1]}
                />, <br/>])
                : (showChangeTable && (!isChangeLoaded ? ([
                        <Spinner size="large"/>,
                        <br/>, <br/>]
                ) : [<ComplexFilterTable
                    itemList={activeChangeValidationData}
                    columnDefinitions={CHANGETILEMETRICS_TABLE_COLUMN_DEFINITIONS}
                    visibleContentOptions={CHANGETILEMETRICS_TABLE_VISIBLE_CONTENT_OPTIONS}
                    fetchingData={activeValidationDataFetchStatus}
                    pageSizeOptions={PAGE_SIZE_OPTIONS}
                    filterProperties={CHANGETILEMETRICS_TABLE_FILTER_PROPERTIES}
                    preferences={changeTileMetricsPreferences}
                    setPreferences={setChangeTileMetricsPreferences}
                    tableName={"Change Tiles Metrics: (" + TILESETTYPE[changeTileSetTypeAndVersion[0] as keyof typeof TILESETTYPE]
                        + " " + changeTileSetTypeAndVersion[1] + ")"}
                />, <br/>, <Button
                    onClick={() => downloadData(activeChangeValidationData, changeTableDownloadHeaders, changeTableFileName)}>
                    Download
                </Button>, <br/>, <br/>, <br/>]))}
            {/*Table 4*/}
            <Button variant="primary" onClick={() => {
                handleSizeButtonClick();
                setTileSetTypeAndVersion(TILESETTYPE[selectedType.label as keyof typeof TILESETTYPE], parseInt(tileVersion), "size");
                fetchSizeTileMetricsData();
            }}>
                Size Metrics
            </Button>
            <br/> <br/>
            {isSizeTileMetricsDataInvalid && isSizeLoaded ? ([<CustomFlashbar
                    tilesetType={TILESETTYPE[sizeTileSetTypeAndVersion[0] as keyof typeof TILESETTYPE]}
                    tilesetVersion={sizeTileSetTypeAndVersion[1]}
                />, <br/>])
                : (showSizeTable && (!isSizeLoaded ? ([
                        <Spinner size="large"/>,
                        <br/>, <br/>]
                ) : [<ComplexFilterTable
                    itemList={activeSizeValidationData}
                    columnDefinitions={TILESIZEMETRICS_TABLE_1_COLUMN_DEFINITIONS}
                    visibleContentOptions={TILESIZEMETRICS_TABLE_1_VISIBLE_CONTENT_OPTIONS}
                    fetchingData={activeValidationDataFetchStatus}
                    pageSizeOptions={PAGE_SIZE_OPTIONS}
                    filterProperties={TILESIZEMETRICS_TABLE_1_FILTER_PROPERTIES}
                    preferences={tileSizeMetricsPreferences1}
                    setPreferences={setTileSizeMetricsPreferences1}
                    tableName={"Tile Size Metrics: (" + TILESETTYPE[sizeTileSetTypeAndVersion[0] as keyof typeof TILESETTYPE]
                        + " " + sizeTileSetTypeAndVersion[1] + ")"}
                />,
                    <ComplexFilterTable
                        itemList={activeSizeZoomValidationData}
                        columnDefinitions={TILESIZEMETRICS_TABLE_2_COLUMN_DEFINITIONS}
                        visibleContentOptions={TILESIZEMETRICS_TABLE_2_VISIBLE_CONTENT_OPTIONS}
                        fetchingData={activeValidationDataFetchStatus}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        filterProperties={TILESIZEMETRICS_TABLE_2_FILTER_PROPERTIES}
                        preferences={tileSizeMetricsPreferences2}
                        setPreferences={setTileSizeMetricsPreferences2}
                        tableName={"Tile Size Metrics (by zoom level): (" + TILESETTYPE[sizeTileSetTypeAndVersion[0] as keyof typeof TILESETTYPE]
                            + " " + sizeTileSetTypeAndVersion[1] + ")"}
                    />,
                    <ComplexFilterTable
                        itemList={activeSizeZoomLayerValidationData}
                        columnDefinitions={TILESIZEMETRICS_TABLE_3_COLUMN_DEFINITIONS}
                        visibleContentOptions={TILESIZEMETRICS_TABLE_3_VISIBLE_CONTENT_OPTIONS}
                        fetchingData={activeValidationDataFetchStatus}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        filterProperties={TILESIZEMETRICS_TABLE_3_FILTER_PROPERTIES}
                        preferences={tileSizeMetricsPreferences3}
                        setPreferences={setTileSizeMetricsPreferences3}
                        tableName={"Tile Size Metrics (by zoom level and layer type): (" + TILESETTYPE[sizeTileSetTypeAndVersion[0] as keyof typeof TILESETTYPE]
                            + " " + sizeTileSetTypeAndVersion[1] + ")"}
                    />, <br/>,
                    <Button
                        onClick={() => [downloadData(activeSizeValidationData, sizeTableDownloadHeaders1, sizeTableFileName1)
                            , downloadData(activeSizeZoomValidationData, sizeTableDownloadHeaders2, sizeTableFileName2)
                            , downloadData(activeSizeZoomLayerValidationData, sizeTableDownloadHeaders3, sizeTableFileName3)]}>
                        Download
                    </Button>]))}
        </Layout>
    );
};

export default TileValidation;