import { Icon, Popover } from "@amzn/awsui-components-react";
import { SplitPanelContentTypes } from "../../../store/models/SplitPanelStore";
import { useSplitPanelStore } from "../../../store/SplitPanelStore";
import { RegionData } from "../../../utils/offlineMapsDashboard/offlineDashboardConfig";


const SidePanelMapPreviewFieldIcon = ({ region }: { region: RegionData }) => {
  const splitPanelSettings = useSplitPanelStore((state) => state.splitPanelSettings);
  const setSplitPanelContent = useSplitPanelStore((state) => state.setSplitPanelContent);
  const clearSplitPanel = useSplitPanelStore((state) => state.clearSplitPanel);


  const displaySplitPanel = (title: string, contentType: SplitPanelContentTypes) => {
    if (splitPanelSettings.contentType === contentType && splitPanelSettings.contentId === region.regionId) {
      clearSplitPanel();
    } else {
      setSplitPanelContent(region, title, contentType, region.regionId);
    }
  }

  const popupContent = (regionId: string) => {
    if (isSelected(regionId)) {
      return 'Previewing Map'
    } else {
      return 'Preview Map'
    }
  }

  const isSelected = (regionId: string) => {
    if (splitPanelSettings.contentType === 'offline-bbox-preview' && splitPanelSettings.contentId === regionId) {
      return true
    } else {
      return false
    }
  }

  return (
    <Popover
      dismissButton={false}
      position="top"
      size="small"
      triggerType="custom"
      content={
        <>{popupContent(region.regionId)}</>
      }
    >
      <div style={{cursor: 'pointer'}} onClick={() =>
          displaySplitPanel(`Map Preview - ${region.regionName}`, 'offline-bbox-preview')
        }>
        <Icon name="view-vertical" variant={isSelected(region.regionId) ? "link" : "normal"}/>
      </div>
    </Popover>
  );
}

export default SidePanelMapPreviewFieldIcon;
