import React from "react";
import { WorkflowData } from "../../utils/offlineMapsDashboard/offlineDashboardConfig";
import CopyTextField from "./CopyTextField";

/**
 * Displays a brief summary of the most recent workflow.
 * Meant for use with RealmStatus cards.
 *
 * @param workflows set of workflows for the realm, will be sorted internally to find the most recent workflow
 * @returns <RealmStatusCardInfo workflows={workflows}/>
 */
const RealmStatusCardInfo = ({ workflows }: { workflows: WorkflowData[] }) => {
  return (
    <div>
      {"Most Recent Workflow:"}
      {getDetails()}
    </div>
  );

  function getDetails() {
    if (workflows.length) {
      workflows.sort((a, b): number => {
        return b.workflowCreationTimestamp - a.workflowCreationTimestamp;
      });

      const recentWorkflow = workflows[0];
      const workflowDate = new Date(recentWorkflow.workflowCreationTimestamp);
      return (
        <>
          <CopyTextField
            description={"Workflow Id"}
            content={recentWorkflow.workflowId}
          />
          <CopyTextField
            description={"Workflow Started"}
            content={workflowDate.toLocaleString()}
          />
          {Object.keys(recentWorkflow.value.statusMap).map((key, index) => (
            <CopyTextField
              key={index}
              description={key}
              content={recentWorkflow.value.statusMap[key]}
            />
          ))}
        </>
      );
    } else return <> -</>;
  }
};

export default RealmStatusCardInfo;
