import React from 'react';
import {
    SplitPanel, TextContent
} from '@amzn/awsui-components-react/polaris';
import FeatureDisplay from '../general/side-panels/feature-inspect/FeatureDisplay';
import { SplitPanelStoreSettings } from '../../store/models/SplitPanelStore';
import OfflineBboxPreview from '../offline-maps/split-panel/OfflineBboxPreview';
import DeviceViewConfigPanel from '../stylesheets/common/split-panel/device-view-config/DeviceViewConfigPanel';
import Notifications from '../common/side-panels/notifications/Notifications';
import ZoomToLocation from '../general/side-panels/navigate/ZoomToLocation';
import ZoomToTile from '../general/side-panels/navigate/ZoomToTile';
import Sprites from '../general/side-panels/style-assets/Sprites';
import BboxDisplay from '../general/side-panels/bbox-display/BboxDisplay';
import JsonContent from '../general/side-panels/json-content/JsonContent';
import GeoJsonDisplay from '../general/side-panels/geojson-display/GeoJsonDisplay';

export default function SplitPanelDisplay({ content, title, contentType }: SplitPanelStoreSettings) {

    const SPLIT_PANEL_I18NSTRINGS = {
        preferencesTitle: 'Split panel preferences',
        preferencesPositionLabel: 'Split panel position',
        preferencesPositionDescription: 'Choose the default split panel position for the service.',
        preferencesPositionSide: 'Side',
        preferencesPositionBottom: 'Bottom',
        preferencesConfirm: 'Confirm',
        preferencesCancel: 'Cancel',
        closeButtonAriaLabel: 'Close panel',
        openButtonAriaLabel: 'Open panel',
        resizeHandleAriaLabel: 'Resize split panel'
    };

    const getJsonContent = (content: any) => {
        const json = content !== null ? content : {};
        return JSON.stringify(json, null, 4);
    };


    const headerTitle = title !== null ? title : 'not set';
    return (
        <SplitPanel header={headerTitle} i18nStrings={SPLIT_PANEL_I18NSTRINGS}>
            {
                {
                    'fonts': <TextContent><p>Fonts Not Found</p></TextContent>,
                    'feature-array': <FeatureDisplay content={content} />,
                    'notifications': <Notifications />,
                    'json': <JsonContent content={getJsonContent(content)} />,
                    'sprites': <Sprites url={content}/>,
                    'sprites-right': <Sprites url={content}/>,
                    'geojson': <GeoJsonDisplay />,
                    'bbox': <BboxDisplay />,
                    'offline-bbox-preview': <OfflineBboxPreview region={content}/>,
                    'device-view-config': <DeviceViewConfigPanel />,
                    'zoom-to-xy': <ZoomToLocation />,
                    'zoom-to-tile': <ZoomToTile />
                }[contentType!]
            }
        </SplitPanel>
    );
}