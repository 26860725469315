import * as React from "react";
import Wizard from "@amzn/awsui-components-react/polaris/wizard";
import { Checkbox, Container, FormField, Input, SegmentedControl, Select, SpaceBetween, Spinner } from "@amzn/awsui-components-react";
import { FC, useEffect, useState } from "react";
import { OptionDefinition, OptionGroup } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import { useSettingsStore } from "../../../store/SettingsStore";
import { useNotificationsStore } from "../../../store/NotificationsStore";
import { LOG_SEVERITY } from "../../../store/models/NotificationsStore";
import { fetchStylesheet, fetchResourceFromUrl, fetchStylesheets, formatLiveBasemapData, getStylesheetUrl, getVersionsForDropdown, updateTileUrl } from "../../../utils/apiCall";
import { DropdownStatusProps } from "@amzn/awsui-components-react/polaris/internal/components/dropdown-status";
import { useMapStore } from "../../../store/MapStore";
import { MapConfigResult } from "../../../store/models/MapStore";
import { isValidUrl, requiredValidation } from "../form";
import { getXrayJson } from "../MapUtils";

interface MapConfigLoadingProps {
  basemap: DropdownStatusProps.StatusType;
  getStylesheet: DropdownStatusProps.StatusType;
}

interface MapConfigurationProps {
  isRight: boolean;
}

export const MapConfiguration: FC<MapConfigurationProps> = ({ isRight }) => {
  const mapConfig = useMapStore((state) => state.mapConfig);
  const mapConfigRight = useMapStore((state) => state.mapConfigRight);
  const setMapConfig = useMapStore((state) => state.setMapConfig);
  const setModelVisibility = useMapStore((state) => state.setModelVisibility);
  const developmentSettings = useSettingsStore((state) => state.developmentSettings);
  const addNewNotificationMessage = useNotificationsStore((state) => state.addNewNotificationMessage);
  const [
    activeStepIndex,
    setActiveStepIndex
  ] = React.useState(0);


  const [loading, setLoading] = useState<MapConfigLoadingProps>({
    basemap: "finished",
    getStylesheet: "finished"
  });

  const [sourceOptions, setSourceOptions] = useState<OptionDefinition[]>([]);
  const [basemapOptions, setBasemapOptions] = useState<OptionGroup[]>([]);
  const [versionOptions, setVersionOptions] = useState<OptionGroup[]>([]);

  const [formattedBasemapsList, setFormattedBasemapsList] = useState<any>(null);
  const [pageSpinner, setPageSpinner] = useState<boolean>(false);

  useEffect(() => {

    const loadData = async () => {
      // always start the wizard from beginning 
      try {
        setPageSpinner(true);
        setActiveStepIndex(0);
        const config = isRight ? mapConfigRight : mapConfig;
        if (config) {
          if (config.selectionType === 'select') {
            if (config.environment) {
              setLoading({ ...loading, ...{ basemap: "loading" } });
              const [formatted, dropdown] = await fetchBasemaps(config.environment);
              setFormattedBasemapsList(formatted);
              setBasemapOptions(dropdown);
              setLoading({ ...loading, ...{ basemap: "finished" } });
              if (config.stylesheetName) {
                const versions = getVersionsForDropdown(config.stylesheetName, formatted);
                setVersionOptions(versions);
              }
            }
          }
          setBasemapType(config.selectionType);
          setSelectedSource(config.environment);
          setSelectedBasemap(config.stylesheetName);
          setSelectedVerion(config.stylesheetVersion);
          setTilesetUrl(config.tilesetUrl ? config.tilesetUrl : "");
          setManualStylesheetUrl(config.manualSylesheetUrl ? config.manualSylesheetUrl : "");
          setEnableXrayMode(config.enableXrayMode);
          setPageSpinner(false);
        }

      } catch (error) {
        console.error(error);
      }

    }

    loadData()
      .catch(console.error);
  }, []);



  useEffect(() => {
    const options = [
      { label: "MEDAS Prod", value: "medas_prod" },
      { label: "MEDAS Gamma", value: "medas_gamma" }
    ];

    if (developmentSettings.enableLocalDevelopment) {
      options.push({ value: 'local_api', label: 'Local API' });
    }
    setSourceOptions(options);
  }, [developmentSettings]);

  const fetchBasemaps = async (source: string | undefined) => {
    if (source === undefined) {
      // TODO reset
    } else {
      let response: any = null;
      if (source === 'medas_prod' || source === 'medas_gamma') {
        response = await fetchStylesheets(source);
      } else {
        //response = await fetchStylesheets();
      }
      try {
        const data = formatLiveBasemapData(await response);
        return [data.formatted, data.dropdown]
      } catch (e: any) {
        console.error(e.message);
        addNewNotificationMessage(
          {
            title: 'Stylesheets Loading Failed',
            message: 'Could not fetch stylesheets list from the selected source. View console for errors. TileGenTools Api might be down.',
            severity: LOG_SEVERITY.ERROR
          });
        // TODO reset
      }
    }

    return [null, null]

  }

  const [
    selectedSource,
    setSelectedSource
  ] = useState<string | undefined>(undefined);

  const [
    selectedBasemap,
    setSelectedBasemap
  ] = useState<string | undefined>(undefined);

  const [
    selectedVerion,
    setSelectedVerion
  ] = useState<string | undefined>(undefined);

  const [
    enableXrayMode,
    setEnableXrayMode
  ] = useState<boolean>(false);

  const [
    basemapType,
    setBasemapType
  ] = useState<string>("select");

  const [
    manualStylesheetUrl,
    setManualStylesheetUrl
  ] = useState<string>("");


  const [
    tilesetUrl,
    setTilesetUrl
  ] = useState<string>("");
  const [
    errorMessage,
    setErrorMessage
  ] = useState<string | null>(null);

  const boxStyle = { minHeight: '260px' };

  const getSelectedBasemap = (basemap: string | undefined) => {
    if (!basemap) {
      return null;
    }
    for (const item of basemapOptions) {
      const selected = item.options.find(item => item.value === basemap);
      if (selected) {
        return selected;
      }
    }
    return null;
  }

  const getSelectedVersion = (version: string | undefined) => {
    if (!version) {
      return null;
    }
    for (const item of versionOptions) {
      const selected = item.options.find(item => item.value === version);
      if (selected) {
        return selected;
      }
    }
    return null;
  }

  return (
    pageSpinner === true ? <Spinner size="large" /> :
      <Wizard
        i18nStrings={{
          stepNumberLabel: stepNumber =>
            `Step ${stepNumber}`,
          collapsedStepsLabel: (stepNumber, stepsCount) =>
            `Step ${stepNumber} of ${stepsCount}`,
          skipToButtonLabel: (step, stepNumber) =>
            `Skip to ${step.title}`,
          navigationAriaLabel: "Steps",
          cancelButton: "Cancel",
          previousButton: "Previous",
          nextButton: "Next",
          submitButton: "Apply Changes",
          optional: "optional"
        }}
        onNavigate={({ detail }) =>
          setActiveStepIndex(detail.requestedStepIndex)
        }
        onSubmit={async () => {
          setErrorMessage(null);
          setLoading({ ...loading, ...{ getStylesheet: "loading" } });
          let stylesheetJson = null
          try {
            if (basemapType === 'manual') {
              stylesheetJson = await fetchResourceFromUrl(manualStylesheetUrl);
            } else if (basemapType === 'select') {
              if (!selectedBasemap || !selectedVerion || !selectedSource) {
                throw Error('Invalid Input');
              }
              const stylesheetUrl = getStylesheetUrl(selectedBasemap, formattedBasemapsList)
              stylesheetJson = await fetchStylesheet(stylesheetUrl, selectedVerion, selectedSource);
            }
            if (tilesetUrl.trim()) {
              stylesheetJson = await updateTileUrl(tilesetUrl.trim(), stylesheetJson);
            }

            const result: MapConfigResult = {
              stylesheetJson: stylesheetJson,
              stylesheetXrayJson: getXrayJson(stylesheetJson),
              selectionType: basemapType,
              environment: selectedSource,
              stylesheetName: selectedBasemap,
              stylesheetVersion: selectedVerion,
              manualSylesheetUrl: manualStylesheetUrl.trim(),
              tilesetUrl: tilesetUrl.trim(),
              enableXrayMode: enableXrayMode,
              hiddenLayers: new Set([])
            };
            setMapConfig(result, isRight);
            setLoading({ ...loading, ...{ getStylesheet: "finished" } });
            setModelVisibility(false);
          } catch (error) {
            setErrorMessage(`${error}`);
          }
        }}
        onCancel={() => {
          setModelVisibility(false);
        }}
        activeStepIndex={activeStepIndex}
        allowSkipTo
        isLoadingNextStep={loading.basemap === 'loading' || loading.getStylesheet === 'loading'}
        steps={[
          {
            title: "Choose Basemap",
            content: (
              <div style={boxStyle}>
                <Container>
                  <SpaceBetween direction="vertical" size="m">
                    <FormField label="Type">
                      <SegmentedControl
                        selectedId={basemapType}
                        onChange={({ detail }) =>
                          setBasemapType(detail.selectedId)
                        }
                        options={[
                          { text: "Select", id: "select" },
                          { text: "Manual", id: "manual" }
                        ]}
                      />
                    </FormField>
                    {
                      basemapType === 'select' &&
                      <>
                        <FormField label="Source">
                          <Select
                            selectedOption={
                              sourceOptions.find(item => item.value === selectedSource) as OptionDefinition | null
                            }
                            onChange={async ({ detail }) => {
                              const source = detail.selectedOption.value;
                              setSelectedSource(source);
                              setLoading({ ...loading, ...{ basemap: "loading" } });
                              const [formatted, dropdown] = await fetchBasemaps(source);
                              setFormattedBasemapsList(formatted);
                              setBasemapOptions(dropdown);
                              setLoading({ ...loading, ...{ basemap: "finished" } });

                              // clear
                              setSelectedBasemap(undefined);
                              setSelectedVerion(undefined);
                            }

                            }
                            options={sourceOptions}
                            selectedAriaLabel="Selected"
                          />
                        </FormField>
                        <FormField label="Basemap">
                          <Select
                            selectedOption={getSelectedBasemap(selectedBasemap)}
                            onChange={({ detail }) => {
                              const basemap = detail.selectedOption.value;
                              setSelectedBasemap(basemap);
                              const versions = getVersionsForDropdown(basemap, formattedBasemapsList);
                              setVersionOptions(versions);

                              // clear
                              setSelectedVerion(undefined);
                            }

                            }
                            options={basemapOptions}
                            selectedAriaLabel="Selected"
                            statusType={loading.basemap}
                          />
                        </FormField>
                        <FormField label="Version">
                          <Select
                            selectedOption={getSelectedVersion(selectedVerion)}
                            onChange={async ({ detail }) => {
                              const version = detail.selectedOption.value;
                              setSelectedVerion(version);
                            }

                            }
                            options={versionOptions}
                            selectedAriaLabel="Selected"
                          />
                        </FormField>
                      </>
                    }
                    {
                      basemapType === 'manual' &&
                      <>
                        <FormField label="Stylesheet Url">
                          <Input value={manualStylesheetUrl} onChange={async (evt) => {
                            setManualStylesheetUrl(evt.detail.value);
                          }} inputMode="url" type="url" invalid={basemapType === 'manual' ? !requiredValidation(manualStylesheetUrl) || !isValidUrl(manualStylesheetUrl) : false} />
                        </FormField>
                      </>
                    }


                  </SpaceBetween>
                </Container>
              </div>

            )
          },
          {
            title: "Configure Tileset Manually",
            content: (
              <div style={boxStyle}>
                <Container>
                  <SpaceBetween direction="vertical" size="l">
                    <FormField label="Tileset Url">
                      <Input value={tilesetUrl} onChange={(evt) => { setTilesetUrl(evt.detail.value) }} inputMode="url" type="url" />
                    </FormField>
                  </SpaceBetween>
                </Container>
              </div>
            ),
            isOptional: true,
          },
          {
            title: "Filters",
            content: (
              <div style={boxStyle}>
                <Container>
                  <SpaceBetween direction="vertical" size="l">
                    <FormField>
                      <Checkbox
                        onChange={({ detail }) =>
                          setEnableXrayMode(detail.checked)
                        }
                        checked={enableXrayMode}
                      >
                        Enable X-Ray Mode
                      </Checkbox>
                    </FormField>
                  </SpaceBetween>
                </Container>
              </div>
            ),
            isOptional: true,
            errorText: `${errorMessage ? errorMessage : ''}`
          }
        ]}
      />
  );
}