import { CollectionPreferencesProps } from "@amzn/awsui-components-react";

export const PAGE_SIZE_OPTIONS: CollectionPreferencesProps.PageSizeOption[] = [
    { value: 10, label: "10 Entries" },
    { value: 30, label: "30 Entries" },
    { value: 50, label: "50 Entries" },
];
export enum PreferencesType {
    AggregatedTilesMetrics,
    ViolatedTilesMetrics,
    ChangeTilesMetrics,
    TileSizeMetrics
}
export enum TILESETTYPE {
    "amd" = "amd",
    "here" = "here",
    "zenrin" = "zenrin",
    "mmi" = "mmi",
}
export interface AggregatedTileMetricsData {
    POI: string | null;
    area_new: number;
    area_old: number;
    count_new: number;
    count_old: number;
    count_complex_new: number;
    count_complex_old: number;
    length_new: number;
    length_old: number;
}
export interface ViolatedTileMetricsData {
    tile_id: string;
    tileset_name: string;
    tileset_mode: string;
    validation_metrics: any;
    change_rate_metrics: any;
    change_difference_metrics: any;
    threshold_violation_metrics: any;
    missing_layer_metrics: any;
}
export interface ChangeTileMetricsData {
    label: string;
    type: string;
    tileset_id: string;
    old_value: number;
    new_value: number;
    change_rate: number;
    change_difference: number;
}
export interface TileSizeMetricsData {
    metric_count: number;
    metric_size_avg: number;
    metric_size_max: number;
    metric_size_min: number;
    metric_size_p50: number;
    metric_size_p90: number;
    metric_size_p95: number;
    metric_size_p99: number;
    metric_size_p99_5: number;
    metric_size_sum: number;
    z: number;
    layer_name: string;
}