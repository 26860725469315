import {
    Button,
    Box,
    Header,
    Modal,
    SpaceBetween,
    Table
} from "@amzn/awsui-components-react";
import { useState } from "react";
import {ViolatedTileMetricsData} from "../../utils/tileValidationUtils/tileValidationConfig";

/**
 * Helper function that returns the necessary column definitions and table data
 * @param metric - the metric to render
 * @param violatedTileDetails - the violated tile details to render
 * @returns the column definitions and table data
 */
const renderValidationMetrics = (metric: string, violatedTileDetails: ViolatedTileMetricsData)  => {
    console.log("Filling DetailModal tables");
    let jsonData: string = "";
    let columnDefinitions: any[]= [];
    let tableData: any[] = [];

    if (metric === "Validation Metrics") {
        const jsonDataNew = violatedTileDetails.validation_metrics.validation_metrics_new;
        const jsonDataOld = violatedTileDetails.validation_metrics.validation_metrics_old;
        columnDefinitions=[
            {
                id: "metric",
                header: "Metrics",
                cell: (item: { metric: string; oldValue: number; newValue: number}) =>
                    item.metric,
            },
            {
                id: "oldValue",
                header: "Old Value",
                cell: (item: { metric: string; oldValue: number; newValue: number}) =>
                    item.oldValue,
            },
            {
                id: "newValue",
                header: "New Value",
                cell: (item: { metric: string; oldValue: number; newValue: number}) =>
                    item.newValue,
            },
        ]
        tableData = Object.entries(JSON.parse(jsonDataNew))
            .map(([key, newValue]) => {
                const oldValue = JSON.parse(jsonDataOld)[key];
                return {
                    metric: key,
                    oldValue : oldValue,
                    newValue: newValue};
            });
    } else if (metric === "Change Rate Metrics") {
        jsonData = violatedTileDetails.change_rate_metrics;
        columnDefinitions=[
            {
                id: "metric",
                header: "Metrics",
                cell: (item: { metric: string; value: number;}) =>
                    item.metric,
            },
            {
                id: "value",
                header: "Value",
                cell: (item: { metric: string; value: number;}) =>
                    item.value,
            }
        ]
        tableData = Object.entries(JSON.parse(jsonData)).map(([key, value]) => {
            return {
                metric: key,
                value: value
            };
        });
    } else if (metric === "Change Difference Metrics") {
        jsonData = violatedTileDetails.change_difference_metrics;
        columnDefinitions=[
            {
                id: "metric",
                header: "Metrics",
                cell: (item: { metric: string; value: number;}) =>
                    item.metric,
            },
            {
                id: "value",
                header: "Value",
                cell: (item: { metric: string; value: number;}) =>
                    item.value,
            }
        ]
        tableData = Object.entries(JSON.parse(jsonData)).map(([key, value]) => {
            return {
                metric: key,
                value: value
            };
        });
    } else if (metric === "Threshold Violation Metrics") {
        jsonData = violatedTileDetails.threshold_violation_metrics;
        columnDefinitions = [
            {
                id: "metric",
                header: "Metrics",
                cell: (item: { metric: string; value: number; }) =>
                    item.metric,
            },
            {
                id: "value",
                header: "Value",
                cell: (item: { metric: string; value: number; }) =>
                    item.value,
            }
        ]
        tableData = Object.entries(JSON.parse(jsonData)).map(([key, value]) => {
            return {
                metric: key,
                value: value
            };
        });
    } else if (metric === "Missing Layer Metrics") {
        jsonData = violatedTileDetails.missing_layer_metrics;
        columnDefinitions = [
            {
                id: "metric",
                header: "Metrics",
                cell: (item: { metric: string; value: number; }) =>
                    item.metric,
            },
            {
                id: "value",
                header: "Value",
                cell: (item: { metric: string; value: number; }) =>
                    item.value,
            }
        ]
        if (typeof jsonData === 'undefined'){
            let parsedData;
            parsedData = JSON.parse('{"": null}');
            tableData = Object.entries(parsedData).map(([key, value]) => {
                return {
                    metric: key,
                    value: value
                };
            });
        }
        else if (typeof jsonData === 'string') {
            try {
                let parsedData;
                if (jsonData === '{"": undefined}'){
                    parsedData = JSON.parse('{"": null}');
                } else {
                    parsedData = JSON.parse(jsonData);
                }
                tableData = Object.entries(parsedData).map(([key, value]) => {
                    return {
                        metric: key,
                        value: value
                    };
                });
            } catch (error) {
                console.error('Error parsing JSON data:', error);
            }
        } else {
            console.error('Invalid JSON data:', jsonData);
        }
    }
    return [columnDefinitions, tableData]
};

/**
 * Modal which displays more detailed information about each metric.
 *
 * @param metric - the metric to be displayed in the modal
 * @param violatedTileDetails - the violated tile details to be displayed
 * @return <DetailModal metric={metric}/>
 */
const DetailModal = ({metric, violatedTileDetails}: {metric: string, violatedTileDetails: ViolatedTileMetricsData}) => {
    const [visible, setVisible] = useState(false);
    const [columnDefinitions, tableData] = renderValidationMetrics(metric, violatedTileDetails);

    return (
        <div>
            <Button
                iconName="status-info"
                variant="icon"
                onClick={() => setVisible(true)}
            />
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                header={
                    <Header>
                        {metric}
                        <br/> <br/>
                        <Table
                            renderAriaLive={() => 'Showing data'}
                            columnDefinitions={columnDefinitions}
                            items={tableData}
                            loadingText="Loading resources"
                            sortingDisabled
                            empty={
                                <Box
                                    margin={{vertical: "xs"}}
                                    textAlign="center"
                                    color="inherit">
                                    <SpaceBetween size="m">
                                        <b>No resources</b>
                                        <Button>Create resource</Button>
                                    </SpaceBetween>
                                </Box>
                            }
                            header={''}
                        />
                    </Header>
                }
            >
            </Modal>
        </div>
    );
};

export default DetailModal;
