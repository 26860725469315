import CopyTextField from "./CopyTextField";

/**
 * Converts time given in UTC epoch to a locale formatted date in a CopyTextField.
 *
 * @param epochTime - utc epoch to be converted to locale date
 * @param description? - label for the text field prefixed with a colon
 * @param altCopyText? - alternate text to copied in the case of the visual display being different from what is desired to copy into the clipboard
 * @return <EpochDateField epochTime={epochTime} description={description} altCopyText={altCopyText}/>
 */
const EpochDateField = ({
  epochTime,
  description,
  altCopyText,
}: {
  epochTime: number;
  description?: string;
  altCopyText?: string;
}) => {
  const dateConvert = new Date(epochTime);
  const dateString = dateConvert.toLocaleString();
  return (
    <CopyTextField
      description={description}
      content={dateString}
      altCopyText={altCopyText}
    />
  );
};

export default EpochDateField;
