import { FilteringProperty } from "@amzn/awsui-components-react/polaris/property-filter/interfaces";
import {
    columnDefinitionToOption,
    configPropsToColumnDefinitions,
    configPropsToFilterProps,
    TableContentConfigProps,
    TEXT_FIELD_OPERATORS,
    NUMBER_FIELD_OPERATORS, floatComparison,
} from "../tableConfig";
import {ChangeTileMetricsData} from "./tileValidationConfig";
import CopyTextField from "../../components/offline-maps/CopyTextField";
import MapViewerURLGenerator from "./mapViewerURLGenerator";

let LEFTSTYLESHEETTYPE = "amazon-delivery-us";
let RIGHTSTYLESHEETTYPE = "amazon-delivery-us";
let LEFTSTYLESHEETVERSION = "3.1";
let RIGHTSTYLESHEETVERSION = "3.1";
let LEFTURL = "";
let RIGHTURL = "";

const setChangeTileMetricsURLConfig = (styleSheetTypeLeft: string, styleSheetVersionLeft: string, URLLeft: string,
                                         styleSheetTypeRight: string, styleSheetVersionRight: string, URLRight: string) => {
    if (styleSheetTypeLeft !== "") {
        LEFTSTYLESHEETTYPE = styleSheetTypeLeft;
    }
    if (styleSheetVersionLeft !== "") {
        LEFTSTYLESHEETVERSION = styleSheetVersionLeft;
    }
    if (URLLeft !== "") {
        LEFTURL = URLLeft;
    }
    if (styleSheetTypeRight !== "") {
        RIGHTSTYLESHEETTYPE = styleSheetTypeRight;
    }
    if (styleSheetVersionRight !== "") {
        RIGHTSTYLESHEETVERSION = styleSheetVersionRight;
    }
    if (URLRight !== "") {
        RIGHTURL = URLRight;
    }
}

const CHANGETILEMETRICS_TABLE_CONTENT_CONFIG: TableContentConfigProps<ChangeTileMetricsData>[] =
    [
        {
            id: "label",
            description: "Label",
            tableCell: (ChangeTileMetrics: ChangeTileMetricsData) => (
                <CopyTextField content={ChangeTileMetrics.label}/>
            ),
            sortingField: "label",
            filterGroup: "changeTileTableProp",
            filterOperators: TEXT_FIELD_OPERATORS,
        },
        {
            id: "type",
            description: "Type",
            tableCell: (ChangeTileMetrics: ChangeTileMetricsData) => (
                <CopyTextField content={ChangeTileMetrics.type}/>
            ),
            sortingField: "type",
            filterGroup: "changeTileTableProp",
            filterOperators: TEXT_FIELD_OPERATORS,
        },
        {
            id: "tileset_id",
            description: "Tile ID (x,y,z)",
            tableCell: (ChangeTileMetrics: ChangeTileMetricsData) => (
                <a href={MapViewerURLGenerator((ChangeTileMetrics.tileset_id).split("_")[0],
                    (ChangeTileMetrics.tileset_id).split("_")[1], (ChangeTileMetrics.tileset_id).split("_")[2],
                    LEFTSTYLESHEETTYPE, RIGHTSTYLESHEETTYPE, LEFTSTYLESHEETVERSION, RIGHTSTYLESHEETVERSION,
                    LEFTURL, RIGHTURL)} target="_blank" rel="noopener noreferrer">
                    <CopyTextField content={ChangeTileMetrics.tileset_id}/>
                </a>
            ),
            sortingField: "tileset_id",
            filterGroup: "changeTileTableProp",
            filterOperators: TEXT_FIELD_OPERATORS,
        },
        {
            id: "old_value",
            description: "Old Value",
            tableCell: (ChangeTileMetrics: ChangeTileMetricsData) => (
                <CopyTextField content={ChangeTileMetrics.old_value}/>
            ),
            sortingField: "old_value",
            filterGroup: "changeTileTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "new_value",
            description: "New Value",
            tableCell: (ChangeTileMetrics: ChangeTileMetricsData) => (
                <CopyTextField content={ChangeTileMetrics.new_value} />
            ),
            sortingField: "new_value",
            filterGroup: "changeTileTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "change_rate",
            description: "Change Rate",
            tableCell: (ChangeTileMetrics: ChangeTileMetricsData) => (
                <CopyTextField content={ChangeTileMetrics.change_rate} />
            ),
            sortingField: "change_rate",
            filterGroup: "changeTileTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS.map((operator) => ({
                operator,
                match: (itemValue: any, tokenValue: any) =>
                    floatComparison(itemValue, tokenValue, operator),
            })),
        },
        {
            id: "change_difference",
            description: "Change Difference",
            tableCell: (ChangeTileMetrics: ChangeTileMetricsData) => {
                return <CopyTextField content={ChangeTileMetrics.change_difference} />;
            },
            sortingField: "change_difference",
            filterGroup: "changeTileTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS.map((operator) => ({
                operator,
                match: (itemValue: any, tokenValue: any) =>
                    floatComparison(itemValue, tokenValue, operator),
            })),
        },

    ];

export const CHANGETILEMETRICS_TABLE_COLUMN_DEFINITIONS =
    configPropsToColumnDefinitions(CHANGETILEMETRICS_TABLE_CONTENT_CONFIG);

export const CHANGETILEMETRICS_TABLE_VISIBLE_CONTENT_OPTIONS: any = [
    {
        label: "Change Tile Metrics Info",
        options: columnDefinitionToOption(CHANGETILEMETRICS_TABLE_COLUMN_DEFINITIONS),
    },
];

export const CHANGETILEMETRICS_TABLE_FILTER_PROPERTIES: FilteringProperty[] =
    configPropsToFilterProps(CHANGETILEMETRICS_TABLE_CONTENT_CONFIG).sort((a, b) =>
        a.propertyLabel.localeCompare(b.propertyLabel)
    );

export default setChangeTileMetricsURLConfig;