import { create } from 'zustand';
import { APP_SECTIONS, NavigationStoreState } from './models/NavigationStore';



export const useRouterStore = create<NavigationStoreState>((set, get) => ({
    navigationSettings: 
        { 
            activeAppSection: null, 
            activePath: undefined,
            isNavigationOpen: false 
        },
    setCurrentUrl: (url: string) => {
        let currentSection = null;
        switch (url) {
            case '/':
            case '/map-viewer':
                currentSection = APP_SECTIONS.MAP_VIEWER;
                break;
            case '/map-viewer-compare':
                currentSection = APP_SECTIONS.MAP_COMPARE_MODE;
                break;
            default:
                break;
        }
        set({ navigationSettings: { ...get().navigationSettings, activeAppSection: currentSection, activePath: url } });
    },
    setIsNavigationOpen: (isOpen: boolean) => {
        set({ navigationSettings: { ...get().navigationSettings, isNavigationOpen: isOpen } });
    }
}));