import React, { useEffect } from 'react';
import { useNotificationsStore } from '../../../../store/NotificationsStore';


const Notifications = () => {
    const notificationSettings = useNotificationsStore((state) => state.notificationSettings);
    const readAllNotifications = useNotificationsStore((state) => state.readAllNotifications);

    useEffect(() => {
        readAllNotifications();
    }, [notificationSettings.messages, readAllNotifications]);

    const ulStyle: any = {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row-reverse',
        flexGap: '3px'

      };

    const itemStyle: any = {
        // borderBottom: '2px solid #d2d6d6',
        padding: '5px'
    }

    return (
        <>
            <div style={ulStyle}>
                {notificationSettings.messages.map((item, i) => (
                    <div key={i} style={itemStyle}>{item.title} - {item.message}</div>
                ))}
            </div>
        </>
    );
};

export default Notifications;