import { Box, Button, CollectionPreferences, ContentLayout, Header, Pagination, Table, TextFilter } from "@amzn/awsui-components-react";
import { FC, useState } from "react";
import Layout from "../../layout/Layout";

const StylesheetValidator: FC<any> = () => {
    const [selectedItems, setSelectedItems] = useState([{ name: "Item 2" }]);
    const title = 'Validation Rules';
    return (
        <Layout>
            <ContentLayout header={<Header variant="h1">Validator</Header>}>
                <Table
                    onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
                    selectedItems={selectedItems}
                    ariaLabels={{ selectionGroupLabel: "Items selection", allItemsSelectionLabel: ({ selectedItems }) => `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"} selected`, itemSelectionLabel: ({ selectedItems }, item) => { const isItemSelected = selectedItems.filter(i => i.name === item.name).length; return `${item.name} is ${isItemSelected ? "" : "not"} selected`; } }}
                    columnDefinitions={
                        [
                            { id: "variable", header: "Variable name", cell: e => e.name, sortingField: "name" },
                            { id: "value", header: "Text value", cell: (e: any) => e.alt, sortingField: "alt" },
                            { id: "type", header: "Type", cell: (e: any) => e.type },
                            { id: "description", header: "Description", cell: (e: any) => e.description }
                        ]}
                    items={
                        [
                            { name: "Item 1", alt: "First", description: "This is the first item", type: "1A", size: "Small" },
                            { name: "Item 2", alt: "Second", description: "This is the second item", type: "1B", size: "Large" },
                            { name: "Item 3", alt: "Third", description: "-", type: "1A", size: "Large" },
                            { name: "Item 4", alt: "Fourth", description: "This is the fourth item", type: "2A", size: "Small" },
                            { name: "Item 5", alt: "-", description: "This is the fifth item with a longer description", type: "2A", size: "Large" },
                            { name: "Item 6", alt: "Sixth", description: "This is the sixth item", type: "1A", size: "Small" }
                        ]}
                    loadingText="Loading resources"
                    selectionType="multi"
                    trackBy="name"
                    visibleColumns={["variable", "value", "type", "description"]}
                    empty=
                    {
                        <Box textAlign="center" color="inherit">
                            <b>No resources</b>
                            <Box padding={{ bottom: "s" }} variant="p" color="inherit">No resources to display.</Box>
                            <Button>Create resource</Button>
                        </Box>
                    }
                    filter={<TextFilter filteringPlaceholder="Find resources" filteringText="" />}
                    header={<Header counter={selectedItems.length ? "(" + selectedItems.length + "/10)" : "(10)"}>{title}</Header>}
                    pagination={<Pagination currentPageIndex={1} pagesCount={2} ariaLabels={{ nextPageLabel: "Next page", previousPageLabel: "Previous page", pageLabel: pageNumber => `Page ${pageNumber} of all pages` }} />}
                    preferences={
                        <CollectionPreferences 
                            title="Preferences" 
                            confirmLabel="Confirm" 
                            cancelLabel="Cancel" 
                            preferences={{ pageSize: 10, visibleContent: ["variable", "value", "type", "description"] }} 
                            pageSizePreference={{ title: "Page size", options: [{ value: 10, label: "10 resources" }, { value: 20, label: "20 resources" }] }} 
                            wrapLinesPreference={{ label: "Wrap lines", description: "Select to see all the text and wrap the lines" }} 
                            stripedRowsPreference={{ label: "Striped rows", description: "Select to add alternating shaded rows" }} 
                            contentDensityPreference={{ label: "Compact mode", description: "Select to display content in a denser, more compact mode" }} 
                            visibleContentPreference={{ title: "Select visible content", options: [{ label: "Main distribution properties", options: [{ id: "variable", label: "Variable name", editable: false }, { id: "value", label: "Text value" }, { id: "type", label: "Type" }, { id: "description", label: "Description" }] }] }} />} />
            </ContentLayout>
        </Layout>
    )
}
export default StylesheetValidator;