import { FilteringProperty } from "@amzn/awsui-components-react/polaris/property-filter/interfaces";
import {
    columnDefinitionToOption,
    configPropsToColumnDefinitions,
    configPropsToFilterProps,
    TableContentConfigProps,
    NUMBER_FIELD_OPERATORS,
    TEXT_FIELD_OPERATORS
} from "../tableConfig";
import {TileSizeMetricsData} from "./tileValidationConfig";
import CopyTextField from "../../components/offline-maps/CopyTextField";

const TILESIZEMETRICS_TABLE_1_CONTENT_CONFIG: TableContentConfigProps<TileSizeMetricsData>[] =
    [
        {
            id: "metric_count",
            description: "Metric Count",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_count}/>
            ),
            sortingField: "metric_count",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_avg",
            description: "Metric Size Average",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_avg}/>
            ),
            sortingField: "metric_size_avg",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_max",
            description: "Metric Size Max",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_max}/>
            ),
            sortingField: "metric_size_max",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_min",
            description: "Metric Size Min",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_min}/>
            ),
            sortingField: "metric_size_min",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_p50",
            description: "P50",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_p50}/>
            ),
            sortingField: "metric_size_p50",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_p90",
            description: "P90",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_p90}/>
            ),
            sortingField: "metric_size_p90",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_p95",
            description: "P95",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_p95}/>
            ),
            sortingField: "metric_size_p95",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_p99",
            description: "P99",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_p99}/>
            ),
            sortingField: "metric_size_p99",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_p99_5",
            description: "P99.5",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_p99_5}/>
            ),
            sortingField: "metric_size_p99_5",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_sum",
            description: "Metric Size Sum",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_sum}/>
            ),
            sortingField: "metric_size_sum",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },

    ];
const TILESIZEMETRICS_TABLE_2_CONTENT_CONFIG: TableContentConfigProps<TileSizeMetricsData>[] =
    [
        {
            id: "z",
            description: "Zoom",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.z}/>
            ),
            sortingField: "z",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_count",
            description: "Metric Count",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_count}/>
            ),
            sortingField: "metric_count",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_avg",
            description: "Metric Size Average",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_avg}/>
            ),
            sortingField: "metric_size_avg",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_max",
            description: "Metric Size Max",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_max}/>
            ),
            sortingField: "metric_size_max",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_min",
            description: "Metric Size Min",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_min}/>
            ),
            sortingField: "metric_size_min",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_p50",
            description: "P50",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_p50}/>
            ),
            sortingField: "metric_size_p50",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_p90",
            description: "P90",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_p90}/>
            ),
            sortingField: "metric_size_p90",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_p95",
            description: "P95",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_p95}/>
            ),
            sortingField: "metric_size_p95",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_p99",
            description: "P99",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_p99}/>
            ),
            sortingField: "metric_size_p99",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_p99_5",
            description: "P99.5",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_p99_5}/>
            ),
            sortingField: "metric_size_p99_5",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_sum",
            description: "Metric Size Sum",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_sum}/>
            ),
            sortingField: "metric_size_sum",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },

    ];
const TILESIZEMETRICS_TABLE_3_CONTENT_CONFIG: TableContentConfigProps<TileSizeMetricsData>[] =
    [
        {
            id: "z",
            description: "Zoom",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.z}/>
            ),
            sortingField: "z",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "layer_name",
            description: "Layer Name",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.layer_name}/>
            ),
            sortingField: "layer_name",
            filterGroup: "tileSizeTableProp",
            filterOperators: TEXT_FIELD_OPERATORS,
        },
        {
            id: "metric_count",
            description: "Metric Count",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_count}/>
            ),
            sortingField: "metric_count",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_avg",
            description: "Metric Size Average",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_avg}/>
            ),
            sortingField: "metric_size_avg",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_max",
            description: "Metric Size Max",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_max}/>
            ),
            sortingField: "metric_size_max",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_min",
            description: "Metric Size Min",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_min}/>
            ),
            sortingField: "metric_size_min",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_p50",
            description: "P50",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_p50}/>
            ),
            sortingField: "metric_size_p50",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_p90",
            description: "P90",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_p90}/>
            ),
            sortingField: "metric_size_p90",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_p95",
            description: "P95",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_p95}/>
            ),
            sortingField: "metric_size_p95",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_p99",
            description: "P99",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_p99}/>
            ),
            sortingField: "metric_size_p99",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_p99_5",
            description: "P99.5",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_p99_5}/>
            ),
            sortingField: "metric_size_p99_5",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        },
        {
            id: "metric_size_sum",
            description: "Metric Size Sum",
            tableCell: (TileSizeMetrics: TileSizeMetricsData) => (
                <CopyTextField content={TileSizeMetrics.metric_size_sum}/>
            ),
            sortingField: "metric_size_sum",
            filterGroup: "tileSizeTableProp",
            filterOperators: NUMBER_FIELD_OPERATORS,
        }

    ];
export const TILESIZEMETRICS_TABLE_1_COLUMN_DEFINITIONS =
    configPropsToColumnDefinitions(TILESIZEMETRICS_TABLE_1_CONTENT_CONFIG);

export const TILESIZEMETRICS_TABLE_1_VISIBLE_CONTENT_OPTIONS: any = [
    {
        label: "Tile Size Metrics Info",
        options: columnDefinitionToOption(TILESIZEMETRICS_TABLE_1_COLUMN_DEFINITIONS),
    },
];

export const TILESIZEMETRICS_TABLE_1_FILTER_PROPERTIES: FilteringProperty[] =
    configPropsToFilterProps(TILESIZEMETRICS_TABLE_1_CONTENT_CONFIG).sort((a, b) =>
        a.propertyLabel.localeCompare(b.propertyLabel)
    );

export const TILESIZEMETRICS_TABLE_2_COLUMN_DEFINITIONS =
    configPropsToColumnDefinitions(TILESIZEMETRICS_TABLE_2_CONTENT_CONFIG);

export const TILESIZEMETRICS_TABLE_2_VISIBLE_CONTENT_OPTIONS: any = [
    {
        label: "Tile Size 2 Metrics Info",
        options: columnDefinitionToOption(TILESIZEMETRICS_TABLE_2_COLUMN_DEFINITIONS),
    },
];

export const TILESIZEMETRICS_TABLE_2_FILTER_PROPERTIES: FilteringProperty[] =
    configPropsToFilterProps(TILESIZEMETRICS_TABLE_2_CONTENT_CONFIG).sort((a, b) =>
        a.propertyLabel.localeCompare(b.propertyLabel)
    );
export const TILESIZEMETRICS_TABLE_3_COLUMN_DEFINITIONS =
    configPropsToColumnDefinitions(TILESIZEMETRICS_TABLE_3_CONTENT_CONFIG);

export const TILESIZEMETRICS_TABLE_3_VISIBLE_CONTENT_OPTIONS: any = [
    {
        label: "Tile Size 3 Metrics Info",
        options: columnDefinitionToOption(TILESIZEMETRICS_TABLE_3_COLUMN_DEFINITIONS),
    },
];

export const TILESIZEMETRICS_TABLE_3_FILTER_PROPERTIES: FilteringProperty[] =
    configPropsToFilterProps(TILESIZEMETRICS_TABLE_3_CONTENT_CONFIG).sort((a, b) =>
        a.propertyLabel.localeCompare(b.propertyLabel)
    );