import {render} from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'react-oidc-context';


const config = (window as any).Configs;
const oidcConfig = {
    authority: config.COGNITO_AUTHORITY,
    client_id: config.COGNITO_CLIENT_ID,
    redirect_uri: config.COGNITO_REDIRECT_URI,
    // ...
  };

  
const container = document.getElementById('root');
render(<AuthProvider {...oidcConfig}><App /></AuthProvider>, container);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);