import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import NotFound from './components/NotFound';
import StylesheetDevicePreview from './components/stylesheets/device-preview/StylesheetDevicePreview';
import OfflineMapsDashboard from './components/offline-maps/OfflineMapsDashboard';
import { useAuth, hasAuthParams } from 'react-oidc-context';
import {useEffect} from 'react';
import OfflineMapViewer from './components/offline-maps/OfflineMapViewer';
import GeneralMapViewerSlider from './components/general/MapViewerSlider';
import GeneralMapViewer from './components/general/MapViewer';
import GeneralMapViewerCompare from './components/general/MapViewerCompare';
import TileValidation from './components/tileValidation/TileValidation';
import RouteSimulator from './components/stylesheets/route-simulator/RouteSimulator';
import StylesheetDoc from './components/stylesheets/stylesheet-doc/StylesheetDoc';
import StylesheetValidator from './components/stylesheets/stylesheet-validator/StylesheetValidator';
import StylesheetViz from './components/stylesheets/stylesheet-viz/StylesheetViz';
import StylesheetDiff from './components/stylesheets/stylesheet-diff/StylesheetDiff';
import ManageStylesheets from './components/stylesheets/manage/stylesheets/ManageStylesheets';
import ManageFonts from './components/stylesheets/manage/fonts/ManageFonts';
import ManageSprites from './components/stylesheets/manage/sprites/ManageSprites';
import VersionExplorer from './components/stylesheets/manage/version-explorer/VersionExplorer';
import StylesheetColors from './components/stylesheets/colors/StylesheetColors';

function App() {
  const auth = useAuth();


  function removeAuthQueryParameters() {
    if (hasAuthParams()) {
      const originalUri = localStorage.getItem('uri');
      if (originalUri) {
        let url = new URL(originalUri);
        url.searchParams.delete('state');
        url.searchParams.delete('code');

        localStorage.removeItem('uri');
        window.location.replace(url);
      }
      else {
        window.location.replace('/');
      }
    }
  }

  // automatically sign-in
    useEffect(() => {
      if (!hasAuthParams() &&
          !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {

            // store the current uri in local storage
            localStorage.setItem('uri', window.location.href);
          auth.signinRedirect();
      }
  }, [auth, auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);


  // silent signin when token expires
  useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(() => {
        auth.signinSilent();
    })
  }, [auth, auth.events, auth.signinSilent]);

  if (auth.activeNavigator) {
    switch (auth.activeNavigator) {
      case 'signinSilent':
        return <div>Signing you in...</div>;
      case 'signoutRedirect':
        return <div>Signing you out...</div>;
      default:
        return <div>Waiting...</div>
    }
  }


  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    removeAuthQueryParameters();

    return <div>Refresh the webapp and try again..Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    removeAuthQueryParameters();

    return (
      <Router>
        <Routes>
          <Route path='/' element={<Navigate to="/general-map-viewer" />} />
          <Route path='/general-map-viewer' element={<GeneralMapViewer />} />
          <Route path='/general-map-viewer-compare' element={<GeneralMapViewerCompare />} />
          <Route path='/general-map-viewer-slider' element={<GeneralMapViewerSlider />} />
          <Route path='/tile-validation-metrics' element={<TileValidation />} />

          <Route path='/offline-maps-dashboard' element={<OfflineMapsDashboard />}/>
          <Route path='/offline-maps-viewer' element={<OfflineMapViewer />}/>

          <Route path='/manage-stylesheets' element={<ManageStylesheets />} />
          <Route path='/manage-fonts' element={<ManageFonts />} />
          <Route path='/manage-sprites' element={<ManageSprites />} />
          <Route path='/manage-version-explorer' element={<VersionExplorer />} />
          <Route path='/stylesheet-device-preview' element={<StylesheetDevicePreview />} />
          <Route path='/stylesheet-diff' element={<StylesheetDiff />} />
          <Route path='/stylesheet-route-simulator' element={<RouteSimulator />} />
          <Route path='/stylesheet-doc' element={<StylesheetDoc />} />
          <Route path='/stylesheet-validator' element={<StylesheetValidator />} />
          <Route path='/stylesheet-viz' element={<StylesheetViz />} />
          <Route path='/stylesheet-colors' element={<StylesheetColors />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Router>
    );
  }

  return <div>Refresh the webapp and try again..</div>;
}

export default App;
