import { TableProps } from "@amzn/awsui-components-react";
import { FilteringProperty } from "@amzn/awsui-components-react/polaris/property-filter/interfaces";
import React from "react";
import EpochDateField from "../../components/offline-maps/EpochDateField";

import {
  TableDateFilterForm,
  formatDate,
} from "../../components/offline-maps/TableDateFilterForm";
import CopyTextField from "../../components/offline-maps/CopyTextField";
import {
  TEXT_FIELD_OPERATORS,
  NUMBER_FIELD_OPERATORS,
  dateComparison,
  columnDefinitionToOption,
  configPropsToColumnDefinitions,
  TableContentConfigProps,
  stringComparator,
  stringIntComparator,
  configPropsToDisplay,
  configPropsToFilterProps,
} from "../tableConfig";
import { RegionData } from "./offlineDashboardConfig";
import { getTotalPackSize } from "./offlineDashboardUtil";
import { DisplayContentTypeDefinition } from "../../components/offline-maps/models/DisplayContentTypeDefinition";
import MapPreviewFieldIcon from "../../components/offline-maps/utils/MapPreviewFieldIcon";
import DetailModal from "../../components/offline-maps/DetailModal";
import { DISPLAY_TYPE } from "../../components/offline-maps/models/DisplayType";

const REGION_CONTENT_CONFIG: TableContentConfigProps<RegionData>[] = [
  {
    id: "detailsModal",
    description: "Details",
    tableCell: (region: RegionData) => <DetailModal region={region} />,
  },
  {
    id: "regionId",
    description: "Region Id",
    tableCell: (region: RegionData) => (
      <CopyTextField content={region.regionId} />
    ),
    sortingComparator: (lhs: RegionData, rhs: RegionData) =>
      stringIntComparator(lhs.regionId, rhs.regionId),
    filterGroup: "regionProp",
    filterOperators: TEXT_FIELD_OPERATORS,
    displayType: DISPLAY_TYPE.Text
  },

  {
    id: "releaseId",
    description: "Release Id",
    tableCell: (region: RegionData) => (
      <CopyTextField content={region.releaseId} />
    ),
    sortingComparator: (lhs: RegionData, rhs: RegionData) =>
      stringIntComparator(lhs.releaseId, rhs.releaseId),
    filterGroup: "regionProp",
    filterOperators: TEXT_FIELD_OPERATORS,
    displayType: DISPLAY_TYPE.Text,
  },

  {
    id: "regionName",
    description: "Region Name",
    tableCell: (region: RegionData) => (
      <CopyTextField content={region.regionName} />
    ),
    sortingComparator: (lhs: RegionData, rhs: RegionData) =>
      stringComparator(lhs.regionName, rhs.regionName),
    filterGroup: "regionProp",
    filterOperators: TEXT_FIELD_OPERATORS,
    displayType: DISPLAY_TYPE.Text,
  },

  {
    id: "countryCode",
    description: "Country Code",
    tableCell: (region: RegionData) => <CopyTextField content={region.countryCode} />,
    sortingComparator: (lhs: RegionData, rhs: RegionData) =>
      stringComparator(lhs.countryCode, rhs.countryCode),
    filterGroup: "regionProp",
    filterOperators: TEXT_FIELD_OPERATORS,
    displayType: DISPLAY_TYPE.Text,
  },

  {
    id: "serviceAreaId",
    description: "Service Area ID",
    tableCell: (region: RegionData) => (
      <CopyTextField content={region.serviceAreaId} />
    ),
    sortingComparator: (lhs: RegionData, rhs: RegionData) =>
      stringComparator(lhs.serviceAreaId, rhs.serviceAreaId),
    filterGroup: "regionProp",
    filterOperators: TEXT_FIELD_OPERATORS,
    displayType: DISPLAY_TYPE.Text,
  },

  {
    id: "boundingBox",
    description: "Bounding Box (minLong, minLat, maxLong, maxLat)",
    tableCell: (region: RegionData) => (
      <div className="tbl-icon-cell">
        <MapPreviewFieldIcon region={region}/>
        <CopyTextField
          content={
            region.boundingBox.minLong +
            ", " +
            region.boundingBox.minLat +
            ", " +
            region.boundingBox.maxLong +
            ", " +
            region.boundingBox.maxLat
          }
        />
      </div>
    ),
    displayType: DISPLAY_TYPE.CustomText,
    displayCustomFormat: (region: RegionData) => {
      return (
        `${region.boundingBox.minLong}, ${region.boundingBox.minLat}, ` +
        `${region.boundingBox.maxLong}, ${region.boundingBox.maxLat}`
      );
    },
  },

  {
    id: "packSize",
    description: "Pack Size",
    tableCell: (region: RegionData) => (
      <CopyTextField content={humanFileSize(getTotalPackSize(region))} />
    ),
    sortingComparator: (lhs: RegionData, rhs: RegionData): number => {
      const lhsTotal = getTotalPackSize(lhs);
      const rhsTotal = getTotalPackSize(rhs);
      return lhsTotal - rhsTotal;
    },
  },

  {
    id: "regionDefinition",
    description: "Region Definition",
    displayType: DISPLAY_TYPE.Text,
  },
];

const NAV_CONTENT_CONFIG: TableContentConfigProps<RegionData>[] = [
  {
    id: "navArtifactModel.buildVersion",
    description: "Nav Pack Build Version",
    tableCell: (region: RegionData) => (
      <CopyTextField content={region["navArtifactModel.buildVersion"]} />
    ),
    sortingComparator: (lhs: RegionData, rhs: RegionData) =>
      stringIntComparator(
        lhs["navArtifactModel.buildVersion"],
        rhs["navArtifactModel.buildVersion"]
      ),
    filterGroup: "navProp",
    filterOperators: NUMBER_FIELD_OPERATORS,
    displayType: DISPLAY_TYPE.Text,
  },

  {
    id: "navArtifactModel.createTimestampEpoch",
    description: "Nav Pack Creation Date",
    tableCell: (region: RegionData) => (
      <EpochDateField
        epochTime={region["navArtifactModel.createTimestampEpoch"]}
      />
    ),
    sortingComparator: (lhs: RegionData, rhs: RegionData) =>
      lhs["navArtifactModel.createTimestampEpoch"] -
      rhs["navArtifactModel.createTimestampEpoch"],
    filterGroup: "navProp",
    filterOperators: NUMBER_FIELD_OPERATORS.map((operator) => ({
      operator,
      form: TableDateFilterForm,
      format: formatDate,
      match: (itemValue: any, tokenValue: any) =>
        dateComparison(itemValue, tokenValue, operator),
    })),
    displayType: DISPLAY_TYPE.Date,
  },

  {
    id: "navArtifactModel.expirationTimestampEpoch",
    description: "Nav Pack Expiration Date",
    tableCell: (region: RegionData) => (
      <EpochDateField
        epochTime={region["navArtifactModel.expirationTimestampEpoch"]}
      />
    ),
    sortingComparator: (lhs: RegionData, rhs: RegionData) =>
      lhs["navArtifactModel.expirationTimestampEpoch"] -
      rhs["navArtifactModel.expirationTimestampEpoch"],
    filterGroup: "navProp",
    filterOperators: NUMBER_FIELD_OPERATORS.map((operator) => ({
      operator,
      form: TableDateFilterForm,
      format: formatDate,
      match: (itemValue: any, tokenValue: any) =>
        dateComparison(itemValue, tokenValue, operator),
    })),
    displayType: DISPLAY_TYPE.Date,
  },

  {
    id: "navArtifactModel.rsmVersion",
    description: "RSM Version",
    tableCell: (region: RegionData) => (
      <CopyTextField content={region["navArtifactModel.rsmVersion"]} />
    ),
    sortingComparator: (lhs: RegionData, rhs: RegionData) =>
      stringComparator(
        lhs["navArtifactModel.rsmVersion"],
        rhs["navArtifactModel.rsmVersion"]
      ),
    filterGroup: "navProp",
    filterOperators: TEXT_FIELD_OPERATORS,
    displayType: DISPLAY_TYPE.Text,
  },

  {
    id: "navArtifactModel.workflowId",
    description: "Nav Pack Workflow ID",
    tableCell: (region: RegionData) => (
      <CopyTextField content={region["navArtifactModel.workflowId"]} />
    ),
    sortingComparator: (lhs: RegionData, rhs: RegionData) =>
      stringComparator(
        lhs["navArtifactModel.workflowId"],
        rhs["navArtifactModel.workflowId"]
      ),
    filterGroup: "navProp",
    filterOperators: TEXT_FIELD_OPERATORS,
    displayType: DISPLAY_TYPE.Text,
  },
];

const TILE_CONTENT_CONFIG: TableContentConfigProps<RegionData>[] = [
  {
    id: "tileArtifactModel.buildVersion",
    description: "Tile Pack Build Version",
    tableCell: (region: RegionData) => (
      <CopyTextField content={region["tileArtifactModel.buildVersion"]} />
    ),
    sortingComparator: (lhs: RegionData, rhs: RegionData) =>
      stringIntComparator(
        lhs["tileArtifactModel.buildVersion"],
        rhs["tileArtifactModel.buildVersion"]
      ),
    filterGroup: "tileProp",
    filterOperators: TEXT_FIELD_OPERATORS,
    displayType: DISPLAY_TYPE.Text,
  },

  {
    id: "tileArtifactModel.createTimestampEpoch",
    description: "Tile Pack Creation Date",
    tableCell: (region: RegionData) => (
      <EpochDateField
        epochTime={region["tileArtifactModel.createTimestampEpoch"]}
      />
    ),
    sortingComparator: (lhs: RegionData, rhs: RegionData) =>
      lhs["tileArtifactModel.createTimestampEpoch"] -
      rhs["tileArtifactModel.createTimestampEpoch"],
    filterGroup: "tileProp",
    filterOperators: NUMBER_FIELD_OPERATORS.map((operator) => ({
      operator,
      form: TableDateFilterForm,
      format: formatDate,
      match: (itemValue: any, tokenValue: any) =>
        dateComparison(itemValue, tokenValue, operator),
    })),
    displayType: DISPLAY_TYPE.Date,
  },

  {
    id: "tileArtifactModel.expirationTimestampEpoch",
    description: "Tile Pack Expiration Date",
    tableCell: (region: RegionData) => (
      <EpochDateField
        epochTime={region["tileArtifactModel.expirationTimestampEpoch"]}
      />
    ),
    sortingComparator: (lhs: RegionData, rhs: RegionData) =>
      lhs["tileArtifactModel.expirationTimestampEpoch"] -
      rhs["tileArtifactModel.expirationTimestampEpoch"],
    filterGroup: "tileProp",
    filterOperators: NUMBER_FIELD_OPERATORS.map((operator) => ({
      operator,
      form: TableDateFilterForm,
      format: formatDate,
      match: (itemValue: any, tokenValue: any) =>
        dateComparison(itemValue, tokenValue, operator),
    })),
    displayType: DISPLAY_TYPE.Date,
  },

  {
    id: "tileArtifactModel.workflowId",
    description: "Tile Pack Workflow ID",
    tableCell: (region: RegionData) => (
      <CopyTextField content={region["tileArtifactModel.workflowId"]} />
    ),
    sortingComparator: (lhs: RegionData, rhs: RegionData) =>
      stringComparator(
        lhs["tileArtifactModel.workflowId"],
        rhs["tileArtifactModel.workflowId"]
      ),
    filterGroup: "tileProp",
    filterOperators: TEXT_FIELD_OPERATORS,
    displayType: DISPLAY_TYPE.Text,
  },
];

const STYLESHEET_CONTENT_CONFIG: TableContentConfigProps<RegionData>[] = [
  {
    id: "tileArtifactModel.styleSheetModels",
    description: "Stylesheet IDs",
    tableCell: (region: RegionData) => {
      let stylesheetIds = "";
      if (region["tileArtifactModel.styleSheetModels"])
        stylesheetIds = JSON.stringify(
          region["tileArtifactModel.styleSheetModels"].map(
            (stylesheetModel) => stylesheetModel.id
          )
        );
      return <CopyTextField content={stylesheetIds} />;
    },
  },

  {
    id: "tileArtifactModel.styleSheetModels",
    description: "Id",
    displayType: DISPLAY_TYPE.CustomText,
    displayCustomFormat: (region: RegionData, index?: number) => {
      return region["tileArtifactModel.styleSheetModels"][index!].id;
    },
  },

  {
    id: "tileArtifactModel.styleSheetModels",
    description: "Url",
    displayType: DISPLAY_TYPE.CustomText,
    displayCustomFormat: (region: RegionData, index?: number) => {
      return region["tileArtifactModel.styleSheetModels"][index!].url;
    },
  },

  {
    id: "tileArtifactModel.styleSheetModels",
    description: "Stylesheet Version",
    displayType: DISPLAY_TYPE.CustomText,
    displayCustomFormat: (region: RegionData, index?: number) => {
      return region["tileArtifactModel.styleSheetModels"][index!].variant;
    },
  },

  {
    id: "tileArtifactModel.styleSheetModels",
    description: "Min Zoom",
    displayType: DISPLAY_TYPE.CustomText,
    displayCustomFormat: (region: RegionData, index?: number) => {
      return region["tileArtifactModel.styleSheetModels"][index!].minZoom;
    },
  },

  {
    id: "tileArtifactModel.styleSheetModels",
    description: "Max Zoom",
    displayType: DISPLAY_TYPE.CustomText,
    displayCustomFormat: (region: RegionData, index?: number) => {
      return region["tileArtifactModel.styleSheetModels"][index!].maxZoom;
    },
  },

  {
    id: "tileArtifactModel.styleSheetModels",
    description: "Country",
    displayType: DISPLAY_TYPE.CustomText,
    displayCustomFormat: (region: RegionData, index?: number) => {
      return region["tileArtifactModel.styleSheetModels"][index!].country;
    },
  },

  {
    id: "tileArtifactModel.styleSheetModels",
    description: "Locale",
    displayType: DISPLAY_TYPE.CustomText,
    displayCustomFormat: (region: RegionData, index?: number) => {
      return region["tileArtifactModel.styleSheetModels"][index!].locale;
    },
  },

  {
    id: "tileArtifactModel.styleSheetModels",
    description: "Version",
    displayType: DISPLAY_TYPE.CustomText,
    displayCustomFormat: (region: RegionData, index?: number) => {
      return region["tileArtifactModel.styleSheetModels"][index!].version;
    },
  },

  {
    id: "tileArtifactModel.styleSheetModels",
    description: "Status",
    displayType: DISPLAY_TYPE.CustomText,
    displayCustomFormat: (region: RegionData, index?: number) => {
      return region["tileArtifactModel.styleSheetModels"][index!].status;
    },
  },

  {
    id: "tileArtifactModel.styleSheetModels",
    description: "Provider",
    displayType: DISPLAY_TYPE.CustomText,
    displayCustomFormat: (region: RegionData, index?: number) => {
      return region["tileArtifactModel.styleSheetModels"][index!].provider;
    },
  },
];

const REGION_COLUMN_DEFINITIONS = configPropsToColumnDefinitions(
  REGION_CONTENT_CONFIG
);
const NAV_COLUMN_DEFINIONS = configPropsToColumnDefinitions(NAV_CONTENT_CONFIG);
const TILE_COLUMN_DEFINIONS =
  configPropsToColumnDefinitions(TILE_CONTENT_CONFIG);
const STYLESHEET_COLUMN_DEFINITIONS = configPropsToColumnDefinitions(
  STYLESHEET_CONTENT_CONFIG
);

export const REGION_TABLE_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<any>[] =
  [
    ...REGION_COLUMN_DEFINITIONS,
    ...NAV_COLUMN_DEFINIONS,
    ...TILE_COLUMN_DEFINIONS,
    ...STYLESHEET_COLUMN_DEFINITIONS,
  ];

export const REGION_TABLE_VISIBLE_CONTENT_OPTIONS: any = [
  {
    label: "Region Info",
    options: columnDefinitionToOption(REGION_COLUMN_DEFINITIONS),
  },
  {
    label: "Nav Pack Artifacts",
    options: columnDefinitionToOption(NAV_COLUMN_DEFINIONS),
  },
  {
    label: "Tile Pack Artifacts",
    options: columnDefinitionToOption(TILE_COLUMN_DEFINIONS),
  },
  {
    label: "Stylesheet Artifacts",
    options: columnDefinitionToOption(STYLESHEET_COLUMN_DEFINITIONS),
  },
];

const humanFileSize = (size: number) => {
  var i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return `${(Number.parseFloat((size / Math.pow(1024, i)).toFixed(2)) * 1)}  ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
}

export const REGION_FILTER_PROPERTIES: FilteringProperty[] =
  configPropsToFilterProps(REGION_CONTENT_CONFIG);
export const NAV_FILTER_PROPERTIES: FilteringProperty[] =
  configPropsToFilterProps(NAV_CONTENT_CONFIG);
export const TILE_FILTER_PROPERTIES: FilteringProperty[] =
  configPropsToFilterProps(TILE_CONTENT_CONFIG);

export const REGION_TABLE_FILTER_PROPERTIES: any[] = [
  ...REGION_FILTER_PROPERTIES,
  ...NAV_FILTER_PROPERTIES,
  ...TILE_FILTER_PROPERTIES,
].sort((a, b) => a.propertyLabel.localeCompare(b.propertyLabel));

export const REGION_DETAIL_MODAL_CONTENT: DisplayContentTypeDefinition[] =
  configPropsToDisplay(REGION_CONTENT_CONFIG);
export const NAV_DETAIL_MODAL_CONTENT: DisplayContentTypeDefinition[] =
  configPropsToDisplay(NAV_CONTENT_CONFIG);
export const TILE_DETAIL_MODAL_CONTENT: DisplayContentTypeDefinition[] =
  configPropsToDisplay(TILE_CONTENT_CONFIG);
export const STYLESHEET_DETAIL_MODAL_CONTENT: DisplayContentTypeDefinition[] =
  configPropsToDisplay(STYLESHEET_CONTENT_CONFIG);
