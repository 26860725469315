import { create } from 'zustand';
import { getXrayJson } from '../components/common/MapUtils';
import { fetchResourceFromUrl, fetchStylesheet, fetchStylesheets, updateTileUrl } from '../utils/apiCall';
import { MapConfigResult, MapLocation, MapStoreState, ModalControllerProps, NavigateByLocationProps } from './models/MapStore';


const getStylesheetJson = async (selectionType: string, environment: string, stylesheetId: string, stylesheetVersion: string, manualSylesheetUrl: string = '', tilesetUrl: string = '') => {
    try {
        if (selectionType === 'select') {
            const response = await fetchStylesheets(environment);
            const stylesheetsByCountryCode = response.styleSheets;
            for (const countryCode of Object.keys(stylesheetsByCountryCode)) {
                const stylesheets = stylesheetsByCountryCode[countryCode];
                const stylesheet = stylesheets.find((item: any) => item.id === stylesheetId);
                if (stylesheet) {
                    const stylesheetUrl = stylesheet.url;
                    let stylesheetJson = await fetchStylesheet(stylesheetUrl, stylesheetVersion, environment);
                    if (tilesetUrl.trim()) {
                        stylesheetJson = await updateTileUrl(tilesetUrl.trim(), stylesheetJson);
                    }
                    const result: MapConfigResult = {
                        selectionType: selectionType,
                        environment: environment,
                        stylesheetName: stylesheetId,
                        stylesheetVersion: stylesheetVersion,
                        tilesetUrl: tilesetUrl,
                        manualSylesheetUrl: manualSylesheetUrl,
                        stylesheetJson: stylesheetJson,
                        stylesheetXrayJson: getXrayJson(stylesheetJson),
                        enableXrayMode: false,
                        hiddenLayers: new Set([])
                    }
                    return result
                }
            }
        } else {
            let stylesheetJson = await fetchResourceFromUrl(manualSylesheetUrl);;
            if (tilesetUrl.trim()) {
                stylesheetJson = await updateTileUrl(tilesetUrl.trim(), stylesheetJson);
            }
            const result: MapConfigResult = {
                selectionType: selectionType,
                environment: environment,
                stylesheetName: stylesheetId,
                stylesheetVersion: stylesheetVersion,
                tilesetUrl: tilesetUrl,
                manualSylesheetUrl: manualSylesheetUrl,
                stylesheetJson: stylesheetJson,
                stylesheetXrayJson: getXrayJson(stylesheetJson),
                enableXrayMode: false,
                hiddenLayers: new Set([])
                
            }
            return result
        }
    }
    catch (error) {
        console.error(error);
        return null;
    }
    
}


const DEFAULT_MODAL_PROPS: ModalControllerProps = {
    size: 'large',
    type: 'map-configuration',
    visibility: false,
    title: 'Map Configuration'
};

let INITIAL_MAP_LOCATION = {
    lat: 47.6077,
    lon: -122.3394,
    zoom: 8.57,
    pitch: 0,
    bearing: 0
};
try {
    let hasLeftMap = false;
    let hasRightmap = false;
    let currentHash = decodeURIComponent(window.location.search);
    if (currentHash.startsWith('?')) {
        currentHash = currentHash.substring(1, currentHash.length)
    }
    let parts = currentHash.split('&');
    let state: { [index: string]: string } = {};
    for (const item of parts) {
        const [key, value] = item.split('=');
        state[key] = value;
    }
    for (const actionName of Object.keys(state)) {
        if (actionName === 'loc') {
            const rawParam = state[actionName];
            const [lon, lat, zoom, pitch, bearing] = rawParam.split('|');
            INITIAL_MAP_LOCATION = {
                lat: parseFloat(lat),
                lon: parseFloat(lon),
                zoom: parseFloat(zoom),
                pitch: parseFloat(pitch),
                bearing: parseFloat(bearing)
            }
        }

        if (actionName === 'map-config') {
            hasLeftMap = true;
            const rawParam = state[actionName];
            const [selectionType, environment, stylesheetId, stylesheetVersion, manualSylesheetUrl, tilesetUrl] = rawParam.split('|');
            getStylesheetJson(selectionType, environment, stylesheetId, stylesheetVersion, manualSylesheetUrl, tilesetUrl).then((result) => {
                if (result) {
                    useMapStore.setState({ mapConfig: result });
                }
            });

        }

        if (actionName === 'map-config-right') {
            hasRightmap = true;
            const rawParam = state[actionName];
            const [selectionType, environment, stylesheetId, stylesheetVersion, manualSylesheetUrl, tilesetUrl] = rawParam.split('|');
            getStylesheetJson(selectionType, environment, stylesheetId, stylesheetVersion, manualSylesheetUrl, tilesetUrl).then((result) => {
                if (result) {
                    useMapStore.setState({ mapConfigRight: result });
                }
            });
        }
    }

    // Loading the inital map
    if (hasLeftMap === false) {
        getStylesheetJson('select', 'medas_prod', 'amazon-delivery-us', '3.0').then((result) => {
            if (result) {
                useMapStore.setState({ mapConfig: result });
            }
        });
    }

    if (hasRightmap === false) {
        getStylesheetJson('select', 'medas_prod', 'amazon-dark-us', '3.0').then((result) => {
            if (result) {
                useMapStore.setState({ mapConfigRight: result });
            }
        });
    }
} catch (error) {
    console.error('error parsing url', error);
}

const throttle = (fn: Function, wait: number = 300) => {
    let inThrottle: boolean,
      lastFn: ReturnType<typeof setTimeout>,
      lastTime: number;
    return function (this: any) {
      const context = this,
        args = arguments;
      if (!inThrottle) {
        fn.apply(context, args);
        lastTime = Date.now();
        inThrottle = true;
      } else {
        clearTimeout(lastFn);
        lastFn = setTimeout(() => {
          if (Date.now() - lastTime >= wait) {
            fn.apply(context, args);
            lastTime = Date.now();
          }
        }, Math.max(wait - (Date.now() - lastTime), 0));
      }
    };
  };
  

export const useMapStore = create<MapStoreState>((set, get) => ({
    mapLocation: INITIAL_MAP_LOCATION,
    modalControllerProps: DEFAULT_MODAL_PROPS,
    updateUrlThrottled: throttle((evt: any) => {
        get().updateUrl()
      }, 250),
    setMapLocation: (mapLocation: MapLocation) => {
        set({ mapLocation: { ...get().mapLocation, ...mapLocation } });
        get().updateUrlThrottled();
    },
    setModelControllerProps: (props: Partial<ModalControllerProps>) => {
        set({ modalControllerProps: { ...get().modalControllerProps, ...props } });
    },
    setModelVisibility: (visibility: boolean) => {
        set({ modalControllerProps: { ...get().modalControllerProps, visibility: visibility } });
    },
    lastNavigateByLocationProps: null,
    setLastNavigateByLocationProps: (prop: NavigateByLocationProps) => {
        set({ lastNavigateByLocationProps: { ...get().lastNavigateByLocationProps, ...prop } });
    },
    mapConfig: null,
    setMapConfig: (mapConfigResult: MapConfigResult, isRight: boolean) => {
        if (isRight === false) {
            if (get().mapConfig === null) {
                set({ mapConfig: { ...mapConfigResult } });
            } else {
                set({ mapConfig: { ...get().mapConfig, ...mapConfigResult } });
            }
        } else {
            if (get().mapConfigRight === null) {
                set({ mapConfigRight: { ...mapConfigResult } });
            } else {
                set({ mapConfigRight: { ...get().mapConfigRight, ...mapConfigResult } });
            }
        }
        get().updateUrl();
    },
    mapConfigRight: null,
    setXray: (xray: boolean, isRight: boolean) => {
        if (isRight === true) {
            set({ mapConfigRight: { ...get().mapConfigRight!, ...{enableXray: xray }} });
        } else {
            set({ mapConfig: { ...get().mapConfig!, ...{enableXray: xray }} });
        }
        
    },
    setHiddenLayers: (hiddenLayers: Set<string>, isRight: boolean) => {
        if (isRight === true) {
            set({ mapConfigRight: { ...get().mapConfigRight!, ...{hiddenLayers: hiddenLayers }} });
        } else {
            set({ mapConfig: { ...get().mapConfig!, ...{hiddenLayers: hiddenLayers }} });
        }
    },
    updateUrl: () => {
        const path = window.location.pathname;
        let mapConfig = '';
        let mapConfigRight = '';
        if (get().mapConfig) {
            if (path === '/general-map-viewer') {
                mapConfig = `map-config=${get().mapConfig?.selectionType}|${get().mapConfig?.environment}|${get().mapConfig?.stylesheetName}|${get().mapConfig?.stylesheetVersion}|${get().mapConfig?.manualSylesheetUrl}|${get().mapConfig?.tilesetUrl}`
            }
        }

        if (get().mapConfig && get().mapConfigRight) {
            if (path === '/general-map-viewer-compare' || path === '/general-map-viewer-slider') {
                mapConfig = `map-config=${get().mapConfig?.selectionType}|${get().mapConfig?.environment}|${get().mapConfig?.stylesheetName}|${get().mapConfig?.stylesheetVersion}|${get().mapConfig?.manualSylesheetUrl}|${get().mapConfig?.tilesetUrl}`
                mapConfigRight = `map-config-right=${get().mapConfigRight?.selectionType}|${get().mapConfigRight?.environment}|${get().mapConfigRight?.stylesheetName}|${get().mapConfigRight?.stylesheetVersion}|${get().mapConfigRight?.manualSylesheetUrl}|${get().mapConfigRight?.tilesetUrl}`
            }
        }

        const segment = `loc=${get().mapLocation.lon}|${get().mapLocation.lat}|${get().mapLocation.zoom}|${get().mapLocation.pitch}|${get().mapLocation.bearing}&${mapConfig}&${mapConfigRight}`;
        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${segment}`;
        window.history.replaceState({path:newurl},'',newurl);  
    },


    modes: {
        layers: null
    },
    setLayers: (layerTab: "map-layers" | "map-layers-right" | null) => {
        set({ modes: { ...get().modes, ...{layers: layerTab }} });
    },
}));





