import { useMapEditSessionStore } from "../../../store/MapEditSessionStore";
import { useMapStore } from "../../../store/MapStore";
import { MapEditSessionStoreState } from "../../../store/models/MapEditSessionStore";
import { MapStoreState } from "../../../store/models/MapStore";
import { NotificationsStoreState } from "../../../store/models/NotificationsStore";
import { SettingsStoreState } from "../../../store/models/SettingsStore";
import { SplitPanelStoreState } from "../../../store/models/SplitPanelStore";
import { useNotificationsStore } from "../../../store/NotificationsStore";
import { useSettingsStore } from "../../../store/SettingsStore";
import { useSplitPanelStore } from "../../../store/SplitPanelStore";

export interface CanvasWithStoreProps {
    mapStore: MapStoreState;
    settingsStore: SettingsStoreState;
    notificationStore: NotificationsStoreState;
    splitPanelStore: SplitPanelStoreState;
    mapEditSessionStore: MapEditSessionStoreState;
}
export const canvasWithStore = (BaseComponent: any) => (props: any) => {
    const mapStore = useMapStore();
    const settingsStore = useSettingsStore();
    const notificationStore = useNotificationsStore();
    const splitPanelStore = useSplitPanelStore();
    const mapEditSessionStore = useMapEditSessionStore();
    return <BaseComponent {...props} 
        mapStore={mapStore} 
        settingsStore={settingsStore} 
        notificationStore={notificationStore}
        splitPanelStore={splitPanelStore}
        mapEditSessionStore={mapEditSessionStore} />;
};
