import { ContentLayout } from "@amzn/awsui-components-react";
import { useState } from "react";
import { useMapStore } from "../../store/MapStore";
import Layout from "../layout/Layout";
import MapActions from "./common/MapActions";
import MapErrorMessage from "./common/MapErrorMessage";
import MapLayers from "./common/MapLayers";
import MapLocationBar from "./common/MapLocationBar";
import MapViewerSliderCanvas, { MapViewerSliderCanvasProps } from "./common/MapViewerSliderCanvas";

const MapViewerSlider = () => {
    const modes = useMapStore((state) => state.modes);
    const [canvasProps, setCanvasProps] = useState<MapViewerSliderCanvasProps>({
        style: { gridRowStart: 1, gridColumnStart: 1 }
    });
    return (
        <Layout>
            <ContentLayout>
                <div style={{height: 'calc(100vh - 92px)'}}>
                    <div style={{ display: 'grid', width: '100%', height: '100%' }}>
                        <MapViewerSliderCanvas {...canvasProps} />
                        <MapLocationBar style={{ gridRowStart: 1, gridColumnStart: 1 }}/>
                        <MapErrorMessage style={{ gridRowStart: 1, gridColumnStart: 1 }}/>
                        {modes.layers === 'map-layers' && <MapLayers isRightMap={false} style={{ gridRowStart: 1, gridColumnStart: 1, zIndex:3 }}/>}
                        {modes.layers === 'map-layers-right' && <MapLayers isRightMap={true} style={{ gridRowStart: 1, gridColumnStart: 1, zIndex:3 }}/>}
                        <div style={{ gridRowStart: 1, gridColumnStart: 1, zIndex:3, width: 'fit-content', height: 'fit-content' }}>
                            <MapActions hasRightMap={true} />
                        </div>
                    </div>
                </div>
            </ContentLayout>
        </Layout>
    )
}

export default MapViewerSlider;