import { create } from "zustand";
import {
  REALM,
  RealmData,
} from "../utils/offlineMapsDashboard/offlineDashboardConfig";

import {
  FLATTEN_IGNORE_COLUMNS,
  RegionData,
  PreferencesType,
} from "../utils/offlineMapsDashboard/offlineDashboardConfig";
import {
  saveDefaultPreferences,
  saveDefaultRealm,
  getDefaultPreferences,
  getDefaultRealm,
} from "../utils/offlineMapsDashboard/offlineDashboardUtil";
import { getRealm } from "../utils/offlineMapsDashboard/offlineMapsApiCall";
import { flattenObject } from "../utils/tableConfig";
import { OfflineMapsDashboardStoreState } from "./models/OfflineMapsDashboardStoreState";

export const useOfflineMapsDashboardStore =
  create<OfflineMapsDashboardStoreState>((set: any, get: any) => ({
    offlineRegionTablePreferences: getDefaultPreferences(
      PreferencesType.Region
    ),
    /**
     * Sets parameters in the global offlineTable preferences.
     *
     * @param detail the collection of parameters to modify in the existing preferences
     */
    setOfflineRegionTablePreferences: (detail: any) => {
      set({
        offlineRegionTablePreferences: {
          ...get().offlineRegionTablePreferences,
          ...detail,
        },
      });
      //update preferences
      saveDefaultPreferences(
        get().offlineRegionTablePreferences,
        PreferencesType.Region
      );
    },

    offlineStylesheetTablePreferences: getDefaultPreferences(
      PreferencesType.Stylesheet
    ),

      offlineBuildVersionTablePreferences: getDefaultPreferences(
          PreferencesType.BuildVersion
      ),
    /**
     * Sets parameters in the global offlineTable preferences.
     *
     * @param detail the collection of parameters to modify in the existing preferences
     */
    setOfflineStylesheetTablePreferences: (detail: any) => {
      set({
        offlineStylesheetTablePreferences: {
          ...get().offlineStylesheetTablePreferences,
          ...detail,
        },
      });
      //update preferences
      saveDefaultPreferences(
        get().offlineStylesheetTablePreferences,
        PreferencesType.Stylesheet
      );
    },

    /**
     * Sets parameters in the global offlineTable preferences.
     *
     * @param detail the collection of parameters to modify in the existing preferences
     */
    setOfflineBuildVersionTablePreferences: (detail: any) => {
      set({
        offlineBuildVersionTablePreferences: {
          ...get().offlineBuildVersionTablePreferences,
          ...detail,
        },
      });
      //update preferences
      saveDefaultPreferences(
          get().offlineBuildVersionTablePreferences,
          PreferencesType.BuildVersion
      );
    },

    offlineRealmDataNA: { regions: [], stylesheets: [], workflows: [], buildversions: [] },
    offlineRealmDataEU: { regions: [], stylesheets: [], workflows: [], buildversions: [] },
    offlineRealmDataFE: { regions: [], stylesheets: [], workflows: [], buildversions: [] },
    offlineRealmDataIN: { regions: [], stylesheets: [], workflows: [], buildversions: [] },

    offlineMapsActiveRealm: getDefaultRealm(),
    /**
     * Sets the active realm to the provided target realm.
     *
     * @param target the Dropdown target object containing the realm to set as active
     */
    setOfflineMapsActiveRealm: (target: any) => {
      set({ offlineMapsActiveRealm: target.detail.id });
      //update preferences
      const activeRealm = get().offlineMapsActiveRealm;
      saveDefaultRealm(activeRealm);

      if (get().fetchedOfflineRealmDataTimestamp[activeRealm] === 0) {
        get().fetchOfflineRealmData(activeRealm);
      }
    },
    /**
     * Retrieves data for the active realm.
     *
     * @returns offlineRealmData for the active realm
     */
    getActiveRealmData: () => {
      if (
        get().fetchedOfflineRealmDataTimestamp[get().offlineMapsActiveRealm] ===
        0
      ) {
        get().fetchOfflineRealmData(get().offlineMapsActiveRealm);
      }

      switch (get().offlineMapsActiveRealm) {
        case REALM.NA:
          return get().offlineRealmDataNA;
        case REALM.EU:
          return get().offlineRealmDataEU;
        case REALM.FE:
          return get().offlineRealmDataFE;
        case REALM.IN:
          return get().offlineRealmDataIN;
        default:
          return { regions: [] };
      }
    },
    /**
     * Retrieves data for the active realm.
     *
     * @returns offlineRealmData for the active realm
     */
    getRealmWorkflowData: (realm: REALM) => {
      switch (realm) {
        case REALM.NA:
          return get().offlineRealmDataNA.workflows;
        case REALM.EU:
          return get().offlineRealmDataEU.workflows;
        case REALM.FE:
          return get().offlineRealmDataFE.workflows;
        case REALM.IN:
          return get().offlineRealmDataIN.workflows;
        default:
          return [];
      }
    },

    fetchedOfflineRealmDataTimestamp: {
      NA: 0,
      EU: 0,
      FE: 0,
      IN: 0,
    },

    isFetchingOfflineRealmData: {
      NA: false,
      EU: false,
      FE: false,
      IN: false,
    },
    /**
     * Checks if any region is awaiting results for a data request
     *
     * @returns true if there is any pending request, else false
     */
    getAnyFetchingOfflineRealmData: () => {
      return (
        get().isFetchingOfflineRealmData.NA ||
        get().isFetchingOfflineRealmData.EU ||
        get().isFetchingOfflineRealmData.FE ||
        get().isFetchingOfflineRealmData.IN
      );
    },

    /**
     * Initiates a request for data for the given realm.
     *
     * @param realm the target realm to request data for
     */
    fetchOfflineRealmData: async (realm: REALM) => {
      //inital fetch props
      const timestamp = Date.now();

      if (get().isFetchingOfflineRealmData[realm]) {
        console.log("Error: Already fetching data for realm: " + realm);
        return;
      }

      set({
        isFetchingOfflineRealmData: {
          ...get().isFetchingOfflineRealmData,
          [realm]: true,
        },
        fetchedOfflineRealmDataTimestamp: {
          ...get().fetchedOfflineRealmDataTimestamp,
          [realm]: timestamp,
        },
      });

      getRealm(realm).then((realmData) => {
        get().setOfflineRealmData(realm, realmData);
      });
    },
    /**
     * Handles storing the data for each realm.
     *
     * @param realm the realm to modify
     * @param realmData data to set for the given realm
     */
    setOfflineRealmData: (realm: REALM, realmData: any) => {
      const fetchingDataStatus = {
        ...get().isFetchingOfflineRealmData,
        [realm]: false,
      };
      //invalid realm data
      if (
        !realmData.regions ||
        !realmData.stylesheets ||
        !realmData.workflows ||
          !realmData.buildversions
      ) {
        realmData.regions = [];
        realmData.stylesheets = [];
        realmData.workflows = [];
        realmData.buildversions =[];
      }

      //complex filter requires single level object keys
      const flatRegionList: RegionData[] = [];
      for (const region of realmData.regions) {
        const flatObj = flattenObject(region, FLATTEN_IGNORE_COLUMNS);
        flatRegionList.push(flatObj);
      }
      realmData.regions = flatRegionList;

      for (const workflow of realmData.workflows) {
        const parsedValue = JSON.parse(workflow.value);
        workflow.value = parsedValue;
      }

      const convertedRealmData: RealmData = {
        regions: flatRegionList,
        stylesheets: realmData.stylesheets,
        workflows: realmData.workflows,
        buildversions: realmData.buildversions,
      };

      switch (realm) {
        case REALM.NA:
          set({
            isFetchingOfflineRealmData: fetchingDataStatus,
            offlineRealmDataNA: convertedRealmData,
          });
          break;

        case REALM.EU:
          set({
            isFetchingOfflineRealmData: fetchingDataStatus,
            offlineRealmDataEU: convertedRealmData,
          });
          break;

        case REALM.FE:
          set({
            isFetchingOfflineRealmData: fetchingDataStatus,
            offlineRealmDataFE: convertedRealmData,
          });
          break;

        case REALM.IN:
          set({
            isFetchingOfflineRealmData: fetchingDataStatus,
            offlineRealmDataIN: convertedRealmData,
          });
          break;
      }
    },

    /**
     * Fetches realm data for all available realms.
     */
    fetchAllOfflineRealmData: async () => {
      //initial fetch props
      const timestamp = Date.now();
      set({
        isFetchingOfflineRealmData: {
          NA: true,
          EU: true,
          FE: true,
          IN: true,
        },
        fetchedOfflineRealmDataTimestamp: {
          NA: timestamp,
          EU: timestamp,
          FE: timestamp,
          IN: timestamp,
        },
      });

      for (const realm in REALM) {
        getRealm(realm).then((realmData) => {
          get().setOfflineRealmData(realm, realmData);
        });
      }
    },
  }));
