import { Box, FormField, Select, SpaceBetween } from "@amzn/awsui-components-react";
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import { FC, useEffect, useState } from "react";
import { applyMode, applyDensity, Density, Mode, disableMotion } from '@amzn/awsui-global-styles';
import { useSettingsStore } from "../../../store/SettingsStore";


const GeneralSettings: FC = () => {
    const ALL_MODES = [
        { label: "Light", value: Mode.Light },
        { label: "Dark", value: Mode.Dark },
    ];
    const ALL_DENSITIY = [
        { label: "Compact", value: Density.Compact },
        { label: "Comfortable", value: Density.Comfortable },
    ];
    const ALL_MOTION = [
        { label: "True", value: "True" },
        { label: "False", value: "False" },
    ];

    const [mode, setMode] = useState<string>(Mode.Light);
    const [density, setDensity] = useState<string>(Density.Comfortable);
    const [motion, setMotion] = useState<string>("False");

    const generalSettings = useSettingsStore((state) => state.generalSettings);
    const setGeneralSettings = useSettingsStore((state) => state.setGeneralSettings);


    useEffect(() => {
        setMode(generalSettings.mode);
        setDensity(generalSettings.density);
        setMotion(generalSettings.motion);
    }, []);

    useEffect(() => {
        applyMode(generalSettings.mode);
        applyDensity(generalSettings.density);
        disableMotion((generalSettings.motion) === "True");
    }, [generalSettings]);


    return (<Box padding="s">
        <SpaceBetween size='xs'>
            <FormField
                label="Mode"
            >
                <Select
                    selectedOption={ALL_MODES.find(item => item.value === mode)!}
                    onChange={({ detail }) => {
                        setGeneralSettings({ ...generalSettings, mode: detail.selectedOption.value as any });
                        setMode(detail.selectedOption.value!);
                    }}
                    options={ALL_MODES}
                    selectedAriaLabel="Selected"
                />
            </FormField>
            <FormField
                label="Density"
            >
                <Select
                    selectedOption={ALL_DENSITIY.find(item => item.value === density)!}
                    onChange={({ detail }) => {
                        setGeneralSettings({ ...generalSettings, density: detail.selectedOption.value as any });
                        setDensity(detail.selectedOption.value!);
                    }}
                    options={ALL_DENSITIY}
                    selectedAriaLabel="Selected"
                />
            </FormField>
            <FormField
                label="Disable Motion"
            >
                <Select
                    selectedOption={ALL_MOTION.find(item => item.value === motion)!}
                    onChange={({ detail }) => {
                        setGeneralSettings({ ...generalSettings, motion: detail.selectedOption.value as any });
                        setMotion(detail.selectedOption.value!);
                    }}
                    options={ALL_MOTION}
                    selectedAriaLabel="Selected"
                />
            </FormField>
        </SpaceBetween>
    </Box>)
}

export default GeneralSettings;